import styled from 'styled-components';

export const DropdownFilterStyles = styled.div<{
  width?: number;
  filterWidth?: number;
}>`
  font-weight: 400;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  background: #fff;
  position: absolute;
  top: 100%;
  left: -${({ width, filterWidth }) => (width && filterWidth ? width / 2 - filterWidth / 2 : 0)}px;
  z-index: 200;
  width: 100%;
  padding: 16px;
  border-radius: 4px;

  .selectContainer {
  }
`;
