import { FC, useEffect, useState } from 'react';
import { JustPlusIcon } from '../../../../assets';
import { ApiCategory, categoryApi } from '../../../../api';
import { TChip } from '../types';
export const Chip: FC<TChip> = ({
  category,
  handleRemove
}) => {
  const [targetCategories, setTargetCategories] = useState<ApiCategory[]>([]);
  useEffect(() => {
    categoryApi.getChainCategories(category.id).then(res => {
      setTargetCategories(res.data.reverse());
    });
  }, []);
  return <div className="chip">
      <div className="chipText">
        {targetCategories.map(el => el.name).join(' / ')}
      </div>
      <button className="chipDel" onClick={() => handleRemove(category)} aria-label="Видалити категорію"
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    type="button">
        <JustPlusIcon color="#999" width={20} height={20} />
      </button>
    </div>;
};