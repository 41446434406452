import React, { FC } from 'react';
import { TIcon } from './TIcon';
export const SaveDashboard: FC<TIcon> = React.memo(({
  width = 20,
  height = 20,
  color = '#768E9D',
  bgColor = '#D7DFE4'
}) => <svg width={width} height={height} viewBox="0 0 20 20" fill="none">
      <rect width={width} height={height} fill={bgColor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.00008 2H11.0001V9.46138L13.5356 6.92589L14.9498 8.3401L10 13.2899L5.05029 8.3401L6.46451 6.92589L9.00008 9.46146V2ZM2 16V18H4H16H18V16V11H16V16H4V11H2L2 16Z" fill={color} />
    </svg>);