/* eslint-disable class-methods-use-this */
import { TAnalyticsFilter } from '../../state';
import baseApi from '../baseApi';
import {
  ApiResponse,
} from '../entities';
import IExportAnalyticsApi from './IExportAnalyticsApi';

class ExportAnalyticsApi implements IExportAnalyticsApi {
  public async getDashboardsFile(companyID: number, data: TAnalyticsFilter, slag: string): Promise<ApiResponse<any>> {
    return baseApi.postData(`/companies/${companyID}/${slag}`, data, true);
  }
}

export const exportAnalyticsApi = new ExportAnalyticsApi();
