import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { ReviewsByStatusesDashboardStyles } from './styles';
import { analyticsApi, TCountsByStatusInRegion, TCountsByStatusTotal, TPostReviewsByStatuses } from '../../../../api';
import { Table } from '../../../organisms';
import { setSaveFilters, setSaveLink, useAppDispatch, useAppSelector } from '../../../../state';
import { reviewsStatusIconsAndText } from '../../../../constants';
import { PieChart } from '../../analyticsCharts';
const colors: string[] = ['#F4EF67', '#F2C3DB', '#6EAE93', '#BB6685', '#BCB9D0', '#CFC4FF', '#A7C4E9', '#BA806E', '#FF5C53', '#FA7B4C', '#C3DBDB'];
type TRegionReviewsByStatusesDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[] | boolean;
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
};
type TReviewStatuses = {
  color: string;
  label: string;
  statuses: number[];
};
const reviewStatuses: TReviewStatuses[] = [{
  color: '#6EAE93',
  label: 'Опрацьовані',
  statuses: [2, 4, 5, 10]
}, {
  color: '#FF5C53',
  label: 'Просрочені',
  statuses: [8]
}, {
  color: '#F4EF67',
  label: 'Не опрацьовані',
  statuses: [0, 1, 3, 6, 7]
}];
export const RegionReviewsByStatusesDashboard: FC<TRegionReviewsByStatusesDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions
}) => {
  const dispatch = useAppDispatch();
  const [counts, setCounts] = useState<TCountsByStatusInRegion[]>([]);
  const [totalCounts, setTotalCounts] = useState<TCountsByStatusTotal[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const reviewCount = useRef<number>(0);
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      regions,
      relatedByOrgans,
      sourceIDs,
      idfs,
      organTypes,
      cnapTypes,
      isDiia,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostReviewsByStatuses = {
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      sourceIDs: sourceIDs as number[],
      isDiia: isDiia as boolean,
      regionLevel: 3
    };
    setLoading(true);
    analyticsApi.getRegionReviewsByStatuses(+companyID, filteredData).then(res => {
      if (res.statusCode === 200) {
        reviewCount.current = res.data.reviewsCount;
        setCounts(res.data.countsByStatusInRegion);
        setTotalCounts(res.data.countsByStatusTotal);
      }
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    });
    dispatch(setSaveLink('analytics/regions-reviews-by-statuses/file'));
    dispatch(setSaveFilters({
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      sourceIDs: sourceIDs as number[],
      isDiia: isDiia as boolean,
      regionLevel: 3
    }));
  }, [reQuery]);
  return <ReviewsByStatusesDashboardStyles>
      <div className="legend">
        {Object.entries(reviewsStatusIconsAndText(interfaceLanguage)).map(([key, value], index) => <div key={value.text} className="legend-item">
            <span style={{
          background: `${colors[index]}`
        }} />
            <p>{value.text}</p>
          </div>)}
      </div>
      <div className="dashboard">
        <div className="dashboard-item dashboard-one">
          <h4>Статус відгуків</h4>
          <div className="statuses">
            <div className="statuses-item statuses-all">
              <h5>Всього</h5>
              <div className="statuses-chart">
                {totalCounts.filter(count => count.part).map((e, i) => <div className="statuses-segment" key={`${e.count}-${e.part}-${e.status}`} style={{
                width: `${e.part}%`,
                background: `${colors[i]}`
              }} title={`Всього ${e.count}`}>
                    {Math.ceil(e.part)}%
                  </div>)}
              </div>
            </div>
            {counts.map(el => <div key={`${el.regionName}-${el.regionID}`} className="statuses-item">
                <h5>{el.regionName}</h5>
                <div className="statuses-chart">
                  {el.counts.map((e, i) => <div className="statuses-segment" key={`${e.count}-${e.part}-${e.status}`} style={{
                width: `${e.part}%`,
                background: `${colors[i]}`
              }} title={`Всього ${e.count}`}>
                      {Math.ceil(e.part)}%
                    </div>)}
                </div>
              </div>)}
          </div>
        </div>
        <div className="dashboard-item dashboard-two">
          <h4>Всього відгуків</h4>
          <div className="reviews">
            <h2>{reviewCount.current}</h2>
          </div>
        </div>
        <div className="dashboard-item dashboard-three">
          <h4>Статус відгуків</h4>
          <div className="chart-legend">
            {reviewStatuses.map(e => <div className="chart-legend-item">
                <div style={{
              background: e.color
            }} />
                <p>{e.label}</p>
              </div>)}
          </div>
          <div className="pies">
            <PieChart width={dimensions.height / 7} height={dimensions.height / 7} data={reviewStatuses.map(el => {
            const total: {
              count: number;
              part: number;
            } = totalCounts.filter(count => el.statuses.includes(count.status)).reduce((acc, item) => {
              acc.count += item.count;
              acc.part += item.part;
              return acc;
            }, {
              count: 0,
              part: 0
            });
            return {
              label: el.label,
              value: parseFloat(total.part.toFixed(2)),
              fill: el.color,
              count: total.count
            };
          })} chartLegend />
          </div>
        </div>
      </div>
    </ReviewsByStatusesDashboardStyles>;
};