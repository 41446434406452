import * as Yup from 'yup';
import { getTranslationByLangOrEng, translations } from '../../i18n';
import { IUserPermission } from '../../entities';
import { useClickOutside } from '../../hooks';
import { useRef, useState } from 'react';
import { CheckMark, DeleteIcon, ThreeDots } from '../../assets';
import { proxyFile } from '../../utils';
export interface FormValues {
  name: string;
  permissions: IUserPermission[];
}
export const formInitialValues: FormValues = {
  name: '',
  permissions: []
};

// @ts-ignore
export const validationSchema = (lang: string) => Yup.object({
  name: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  permissions: Yup.array()
});
export const extraFieldsSettings = {
  editButtons: {
    justifyContent: 'flex-end'
  },
  create: {
    justifyContent: 'center'
  },
  read: {
    justifyContent: 'center'
  },
  update: {
    justifyContent: 'center'
  },
  delete: {
    justifyContent: 'center'
  }
};
export const NameCustomField = ({
  name,
  logo,
  textColor
}: {
  name: string;
  logo?: string;
  textColor?: string;
}) => <div className="customNameWrapper">
    {logo && <div className={logo ? 'imageContainer' : 'imageContainer default'}>
        <div className="image">{logo && <img src={proxyFile(logo, true)} alt="logo" />}</div>
      </div>}
    <span style={{
    color: textColor || '#000'
  }}>{name}</span>
  </div>;
export const EditButtons = ({
  lang,
  id,
  filterItemsAfterDelete
}: {
  lang: string;
  id: number;
  filterItemsAfterDelete: (id: number) => void;
}) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const menuRef = useRef<any>();
  useClickOutside(menuRef, () => setMenuOpen(false));
  return <div className="editButtonsContainer">
      <div className="menuOpenerContainer" ref={menuRef}>
        <button className="menuOpenerButton" type="button" onClick={event => {
        event.stopPropagation();
        setMenuOpen(!menuOpen);
      }}>
          <ThreeDots />
        </button>
        {menuOpen && <ul className="menuList">
            <li>
              <button type="button" onClick={event => {
            event.stopPropagation();
            filterItemsAfterDelete(id);
          }}>
                <DeleteIcon /> <span>{translations[lang].delete_button}</span>
              </button>
            </li>
          </ul>}
      </div>
    </div>;
};
export const StaticCheckBox = ({
  value
}: {
  value: boolean;
}) => <div className="checkboxContainer">
    <div className="checkboxWrapper">
      <div className={value ? 'checkboxSelected checkbox' : 'checkbox'}>
        {value && <CheckMark width={10} height={8} />}
      </div>
    </div>
  </div>;