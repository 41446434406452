import React, { FC } from 'react';
import { TIcon } from './TIcon';
export const ClearFilters: FC<TIcon> = React.memo(({
  width = 20,
  height = 20,
  color = '#000'
}) => <svg width={width} height={height} viewBox="0 0 16 18" fill="none">
      <rect x="5.00195" y="0.666626" width="5.99609" height="1.66667" fill={color} />
      <rect x="6.49609" y="0.666626" width="6.17442" height="1.66667" transform="rotate(90 6.49609 0.666626)" fill={color} />
      <rect x="2.16699" y="5.17432" width="5.49225" height="1.66667" transform="rotate(90 2.16699 5.17432)" fill={color} />
      <rect x="15.5" y="5.17432" width="5.49225" height="1.66667" transform="rotate(90 15.5 5.17432)" fill={color} />
      <rect x="14.75" y="9" width="8.33333" height="1.66667" transform="rotate(90 14.75 9)" fill={color} />
      <rect x="2.90137" y="9" width="8.33333" height="1.66667" transform="rotate(90 2.90137 9)" fill={color} />
      <rect x="6.66895" y="12.0498" width="5.28348" height="1.66667" transform="rotate(90 6.66895 12.0498)" fill={color} />
      <rect x="10.998" y="0.666626" width="6.17442" height="1.66667" transform="rotate(90 10.998 0.666626)" fill={color} />
      <rect x="9.50391" y="5.17432" width="5.99609" height="1.66667" fill={color} />
      <rect x="0.5" y="5.17432" width="5.99609" height="1.66667" fill={color} />
      <rect x="0.5" y="9" width="15" height="1.66667" fill={color} />
      <rect x="1.23535" y="15.6665" width="13.5156" height="1.66667" fill={color} />
    </svg>);