import React, { FC, useEffect } from 'react';
import { Pie } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleOrdinal } from '@visx/scale';
import { LegendOrdinal } from '@visx/legend';
import { PieArch } from './pieArc';
import { DataItem } from '../../../../pages';
import { PieChartStyles } from './styles';
type TPieChart = {
  width: number;
  height: number;
  data: DataItem[];
  label?: string;
  legend?: boolean;
  chartLegend?: boolean;
  onClick?: (el: DataItem) => void;
};
export const PieChart: FC<TPieChart> = ({
  width,
  height,
  data,
  label,
  legend,
  chartLegend,
  onClick
}) => {
  const margin = {
    top: 20,
    right: 20,
    bottom: 20,
    left: 20
  };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(width, height) / 2;
  const innerRadius = radius / 2;
  const colorScale = scaleOrdinal({
    domain: data.map(d => d.label),
    range: data.map(el => el.fill ?? '#FFDB4D')
  });
  const leftAlignedLabels: DataItem[] = [];
  const rightAlignedLabels: DataItem[] = [];
  data.forEach((item, index) => {
    if (index < data.length / 2) {
      rightAlignedLabels.push(item);
    } else {
      leftAlignedLabels.push(item);
    }
  });
  return <PieChartStyles>
      <svg width={width} height={height}>
        <Group top={height / 2} left={width / 2}>
          <Pie data={data.filter(el => el.value)} pieValue={d => d.value} outerRadius={radius - 10} innerRadius={innerRadius} cornerRadius={0} padAngle={0.005} startAngle={0} endAngle={2 * Math.PI} pieSort={null} pieSortValues={null}>
            {pie => pie.arcs.map((arc, index) => <PieArch key={`arc-${arc.data.label}-${index}`} arc={arc} path={pie.path} index={index} color={colorScale(arc.data.label)} onClick={onClick || undefined} chartLegend={chartLegend} />)}
          </Pie>
        </Group>
      </svg>
      {legend && <div className="pie-legend-wrapper">
          <div className="legend-column">
            {leftAlignedLabels.map((item, idx) => <div key={idx} className="legend-item" style={{
          textAlign: 'left',
          color: colorScale(item.label)
        }}>
                {`${item.value} ${item.count ? `(${item.count})` : ''}`}
              </div>)}
          </div>
          <div className="legend-column">
            {rightAlignedLabels.map((item, idx) => <div key={idx} className="legend-item" style={{
          textAlign: 'right',
          color: colorScale(item.label)
        }}>
                {`${item.value} ${item.count ? `(${item.count})` : ''}`}
              </div>)}
          </div>
        </div>}
    </PieChartStyles>;
};