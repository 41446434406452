import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { NpsDetailedDashboardStyles } from './styles';
import { setSaveFilters, setSaveLink, useAppDispatch } from '../../../../state';
import { analyticsApi, ApiGroupedCsi, ApiGroupedNps, TPostGroupedCsi, TPostGroupedNps } from '../../../../api';
import { DataItem } from '../../../../pages';
import { Loader } from '../../../atoms';
const colors: string[] = ['#CFC4FF', '#A7C4E9'];
type TNpsCsiSatisfactionDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[] | boolean;
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
};
export const NpsCsiSatisfactionDashboard: FC<TNpsCsiSatisfactionDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions
}) => {
  const dispatch = useAppDispatch();
  const [npsData, setNpsData] = useState<ApiGroupedNps | null>(null);
  const [csiData, setCsiData] = useState<ApiGroupedCsi | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const loadData = async () => {
    setLoading(true);
    const {
      companyID,
      from,
      to,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      sourceIDs,
      cnapTypes,
      isDiia,
      ...restFilterParams
    } = filterParams;
    const filteredNpsData: TPostGroupedNps = {
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean,
      sourceIDs: sourceIDs as number[],
      fieldIDs: [858818],
      positiveAnswers: ['9', '10', '9/10', '10/10'],
      negativeAnswers: ['0', '1', '2', '3', '4', '5', '6', '0/10', '1/10', '2/10', '3/10', '4/10', '5/10', '6/10'],
      groupsBy: [{
        metaKey: 'asc_category'
      }]
    };
    const filteredCsiData: TPostGroupedCsi = {
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean,
      sourceIDs: sourceIDs as number[],
      fieldIDs: [858866],
      positiveAnswers: ['Чудово', 'Добре'],
      groupsBy: [{
        metaKey: 'asc_category'
      }]
    };
    const [nps, csi] = await Promise.all([analyticsApi.getGroupedNps(+companyID, filteredNpsData), analyticsApi.getGroupedCsi(+companyID, filteredCsiData)]);
    if (nps.statusCode === 200 && csi.statusCode === 200) {
      setNpsData(nps.data);
      setCsiData(csi.data);
    }
    setLoading(false);
    dispatch(setSaveLink('analytics/grouped/csi-nps/file'));
    dispatch(setSaveFilters({
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean,
      npsFieldIDs: [858818],
      csiFieldIDs: [858866],
      sourceIDs: sourceIDs as number[],
      npsPositiveAnswers: ['9', '10', '9/10', '10/10'],
      npsNegativeAnswers: ['0', '1', '2', '3', '4', '5', '6', '0/10', '1/10', '2/10', '3/10', '4/10', '5/10', '6/10'],
      groupsBy: [{
        metaKey: 'asc_category'
      }],
      csiPositiveAnswers: ['Чудово', 'Добре']
    }));
  };
  useEffect(() => {
    loadData();
  }, [reQuery]);
  if (loading) {
    return <NpsDetailedDashboardStyles>
        <Loader />
      </NpsDetailedDashboardStyles>;
  }
  return <NpsDetailedDashboardStyles>
      {csiData && npsData && <div className="charts">
          <div className="charts-item">
            <h4>CSI</h4>
            <div className="bars">
              {csiData.groups[0].filter(e => e.groupName.length).map((el, index) => <div key={`${el.groupName}-csi`} className="bars-item">
                    <div className="bars-fill">
                      <div style={{
                background: colors[0],
                height: `${el.csi}%`
              }} />
                      <span>{el.csi.toFixed(1)}%</span>
                    </div>
                    <h6 title={el.groupName}>{el.groupName}</h6>
                  </div>)}
            </div>
          </div>
          <div className="charts-item">
            <h4>NPS</h4>
            <div className="bars">
              {npsData.groups[0].filter(e => e.groupName.length).map((el, index) => <div key={`${el.groupName}-nps`} className="bars-item">
                    <div className="bars-fill">
                      <div style={{
                background: colors[1],
                height: `${Math.abs(el.nps)}%`
              }} />
                      <span>{el.nps.toFixed(1)}%</span>
                    </div>
                    <h6 title={el.groupName}>{el.groupName}</h6>
                  </div>)}
            </div>
          </div>
        </div>}
    </NpsDetailedDashboardStyles>;
};