import React, { KeyboardEventHandler, useEffect, useRef } from 'react';
import { TableStyles } from './styles';
import { useExpanded, usePagination, useSortBy, useTable } from 'react-table';
import { TablePagination } from './tablePagination';
import { ArrowDownIcon } from '../../../assets';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '../../atoms';
import { TableProps } from './TableTypes';
export const Table = React.memo(({
  data,
  columns,
  hideFieldsSplitters,
  pagination,
  extraFieldsSettings,
  headerColumnCounts,
  sortableColumns,
  hiddenHeaders,
  fullWidthColumns,
  onClickToRow,
  rowTitle,
  pageSize,
  setPageSize,
  currentPage,
  rowsQuantity,
  pageNumberHandler,
  totalCount,
  offset,
  loading,
  minHeight,
  fixedHeader
}: TableProps) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const infiniteScrollRef = useRef<HTMLDivElement>(null);
  const checkIfMoreFields = () => {
    if (infiniteScrollRef.current && tableRef.current && data && pageNumberHandler && currentPage && totalCount) {
      const infiniteScrollHeight = infiniteScrollRef.current.clientHeight;
      const tableListHeight = tableRef.current.clientHeight;
      if (infiniteScrollHeight >= tableListHeight && data.length < totalCount) {
        pageNumberHandler(currentPage + 1);
      }
    }
  };
  useEffect(() => {
    if (data?.length && !loading) {
      checkIfMoreFields();
    }
  }, [data, totalCount, loading]);
  const {
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page
  } = useTable({
    columns,
    data: data || [],
    initialState: {
      pageIndex: 0,
      pageSize: pageSize || 10
    }
  }, useSortBy, useExpanded, usePagination);
  const renderTableHeader = () => <thead>
        {headerGroups.map((headerGroup, index) => <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => {
        const columnItem = (column.getHeaderProps().key as string).split('_')[1];
        return <th {...column.getHeaderProps(sortableColumns?.includes(columnItem) || sortableColumns === 'all' ? column.getSortByToggleProps() : undefined)} style={hideFieldsSplitters ? {
          borderRight: 'none',
          width: extraFieldsSettings?.[columnItem]?.width ?? 'auto'
        } : {
          width: extraFieldsSettings?.[columnItem]?.width ?? 'auto'
        }} key={columnItem}>
                  <div className="fieldContent" style={{
            justifyContent: extraFieldsSettings?.[columnItem]?.justifyContent
          }}>
                    <span>
                      {hiddenHeaders?.includes(columnItem) ? '' : column.render('Header')}
                      {headerColumnCounts?.[columnItem] ? ` (${headerColumnCounts?.[columnItem]})` : ''}
                    </span>
                    {!hiddenHeaders?.includes(columnItem) && (sortableColumns?.includes(columnItem) || sortableColumns === 'all') && <div className="arrows">
                          <div className={column.isSortedDesc ? 'arrowUp active' : 'arrowUp'}>
                            <ArrowDownIcon />
                          </div>
                          <div className={column.isSorted && !column.isSortedDesc ? 'arrowDown active' : 'arrowDown'}>
                            <ArrowDownIcon />
                          </div>
                        </div>}
                  </div>
                </th>;
      })}
          </tr>)}
      </thead>;
  const renderTableBody = () => <tbody {...getTableBodyProps()}>
        {(pagination ? page : rows).length === 0 && <tr className="emptyRow">
            <td colSpan={columns.length}>
              <p style={{
          textAlign: 'center',
          color: '#000',
          padding: '10px 0'
        }}>
                Список порожній
              </p>
            </td>
          </tr>}

        {(pagination ? page : rows).map((row, index) => {
      prepareRow(row);
      return (
        // @ts-ignore
        <React.Fragment key={row.original.id}>
              <tr title={rowTitle || ''} onClick={() => onClickToRow ? onClickToRow(row.original) : ''} {...row.getRowProps()} className={onClickToRow ? 'clickableRow' : ''} tabIndex={0} onKeyDown={e => {
            if (e.key === 'Enter' && onClickToRow) {
              onClickToRow(row.original);
            }
          }}>
                {row.cells.map(cell => {
              const column = (cell.getCellProps().key as string).split('_')[2];
              return <td {...cell.getCellProps()} style={hideFieldsSplitters ? {
                borderRight: 'none',
                width: extraFieldsSettings?.[column]?.width ?? 'auto'
              } : {
                width: extraFieldsSettings?.[column]?.width ?? 'auto'
              }}>
                      <div className="fieldContent" style={{
                  color: extraFieldsSettings?.[column]?.textColor,
                  flexDirection: extraFieldsSettings?.[column]?.flexDirections as 'row' | 'column' | undefined,
                  justifyContent: extraFieldsSettings?.[column]?.justifyContent,
                  alignItems: extraFieldsSettings?.[column]?.alignItems,
                  margin: extraFieldsSettings?.[column]?.margin
                }}>
                        <div className={extraFieldsSettings?.[column]?.backgroundColor ? 'withBackground' : ''} style={{
                    width: fullWidthColumns?.includes(column) ? '100%' : 'auto'
                  }}>
                          {cell.render('Cell')}
                        </div>
                      </div>
                    </td>;
            })}
              </tr>
            </React.Fragment>
      );
    })}
      </tbody>;
  return <TableStyles style={{
    minHeight: minHeight || 'none'
  }}>
        <div className="tableContainer" id="tableContainer" ref={infiniteScrollRef}
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    tabIndex={0} aria-label="Таблиця">
          <InfiniteScroll dataLength={data ? data.length : 0} style={{
        height: 'auto',
        overflow: 'visible'
      }} next={() => {
        if (pageNumberHandler && currentPage) {
          pageNumberHandler(currentPage + 1);
        }
      }} hasMore={currentPage && offset && totalCount ? currentPage * offset < totalCount : false} scrollableTarget="tableContainer" loader={loading ? <Loader margin={10} /> : null}>
            <table ref={tableRef} style={{
          minWidth: columns.length > 5 ? '1400px' : 'none'
        }} className={fixedHeader ? 'fixed' : ''}>
              {renderTableHeader()}
              {renderTableBody()}
            </table>
          </InfiniteScroll>
        </div>

        {pagination && pageSize !== undefined && currentPage !== undefined && rowsQuantity !== undefined && pageNumberHandler !== undefined && <TablePagination pageSize={pageSize} setPageSize={setPageSize} currentPage={currentPage} pageNumberHandler={pageNumberHandler} rowsQuantity={rowsQuantity} />}
      </TableStyles>;
});