/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { ReviewTicketsStyles } from './styles';
import { IUser } from '../../../entities';
import * as Yup from 'yup';
import { ApiCreateTicket, ApiIssue, ApiIssueStatus, ApiIssueType, ApiNode, issuesApi, usersApi } from '../../../api';
import { SubmitButton, TransparentButton } from '../../atoms';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { getErrorMessage, handleKeyUp } from '../../../utils';
import { CustomSelect, InputField } from '../../molecules';
import { JustPlusIcon } from '../../../assets';
interface FormValues {
  name: string;
  status: ApiIssueStatus | null;
  responsible: IUser | null;
}
const initialValues: FormValues = {
  name: '',
  status: null,
  responsible: null
};
type TReviewTickets = {
  reviewID: number;
  companyID: number;
  node: ApiNode;
  setTickets: Dispatch<SetStateAction<ApiIssue[]>>;
  tickets: ApiIssue[];
};
const validationSchema = Yup.object({
  name: Yup.string().required('Опис обовʼязковий'),
  status: Yup.mixed().nullable().required('Оберіть статус'),
  responsible: Yup.mixed().nullable().required('Оберіть відповідального')
});
export const ReviewTickets: FC<TReviewTickets> = ({
  reviewID,
  companyID,
  node,
  setTickets,
  tickets
}) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [statuses, setStatuses] = useState<ApiIssueStatus[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const loadInitialData = async () => {
    setLoading(true);
    const [statuses, tickets] = await Promise.all([issuesApi.getTicketStatusesByCompany(companyID), issuesApi.getTicketsByReviewId(reviewID)]);
    if (node.responsibleIDs) {
      const usersPromis: Promise<IUser>[] = node.responsibleIDs.map(async el => {
        const res = await usersApi.getUser(el);
        return res.data;
      });
      const usersResp: IUser[] = await Promise.all(usersPromis);
      setUsers(usersResp);
    }
    setStatuses(statuses.data);
    setTickets(tickets.data);
    setLoading(false);
  };
  const deleteTicket = (id: number) => {
    issuesApi.deleteTicket(id).then(res => {
      if (res.statusCode === 200) {
        setTickets(prev => prev.filter(el => el.id !== id));
      }
    });
  };
  const onSubmit = async (values: FormValues, {
    setSubmitting,
    resetForm
  }: FormikHelpers<FormValues>) => {
    const ticket: ApiCreateTicket = {
      name: values.name,
      nodeID: node.id,
      priority: 1,
      responsibleID: values.responsible?.id!,
      reviewID,
      statusID: values.status?.id!
    };
    issuesApi.createTicket(companyID, ticket).then(res => {
      setTickets(prev => [...prev, res.data]);
      resetForm();
    });
    setSubmitting(false);
  };
  const renderForm = ({
    values,
    setFieldValue
  }: FormikProps<FormValues>) => <Form>
      <InputField name="name" onChange={setFieldValue} onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)} placeholder="Опис завдання" value={values.name} error={typeof errorMessage === 'object' ? getErrorMessage('name', errorMessage) : undefined} />

      <CustomSelect options={users} selected={values.responsible} valueField="id" labelField="name" name="responsible" required onChange={(value: any) => {
      setFieldValue('responsible', value);
    }} placeholder="Оберіть відповідального" label="Відповідальна особа" />

      <CustomSelect options={statuses} selected={values.status} required valueField="id" labelField="name" name="status" onChange={(value: any) => {
      setFieldValue('status', value);
    }} placeholder="Оберіть статус" label="Статус завдання" />

      <SubmitButton isLoading={loading} extraButtonStyles={{
      padding: '12px 25px'
    }}>
        Додати задачу
      </SubmitButton>
    </Form>;
  useEffect(() => {
    loadInitialData();
  }, [reviewID]);
  return <ReviewTicketsStyles>
      <div className="ticket-list">
        {tickets.length ? tickets.map(el => <div className="ticket-item">
              <div className="ticket-del" onClick={() => deleteTicket(el.id)} aria-label="Видалити делегування" tabIndex={0} onKeyDown={e => {
          if (e.key === 'Enter') {
            deleteTicket(el.id);
          }
        }}>
                <JustPlusIcon color="#999" width={20} height={20} />
              </div>
              <div title={`${el.name}`} className="ticket-description">
                <p>Опис: <b>{el.name}</b></p>
                <p>Статус: <b>{el.status.name}</b></p>
              </div>
              <TransparentButton isLink linkAddress={`/tickets/${el.id}`} targetIsBlank text="Відкрити делегування" />
            </div>) : <div className="ticket-empty">Немає пов`язаних делегувань</div>}
      </div>
      <div className="ticket-form">
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
          {renderForm}
        </Formik>
      </div>
    </ReviewTicketsStyles>;
};