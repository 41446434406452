import styled from 'styled-components';

export const CsiDetailedDashboardStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;

  .charts {
    height: 100%;
    width: 100%;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    gap: 24px;
    grid-template-areas:
      'one two two three three three three three three four four four'
      'one two two three three three three three three four four four'
      'five five five five five five six six six six six six'
      'five five five five five five six six six six six six'
      'five five five five five five six six six six six six';

    @media screen and (max-width: 1440px) {
      gap: 8px;
    }

    &-item {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 24px;
      gap: 8px;
      border: 1px solid #dee6eb;
      border-radius: 10px;

      @media screen and (max-width: 1440px) {
        padding: 12px;
      }

      h4 {
        font-weight: 400;
        font-size: 18px;
        line-height: 115%;
        text-align: center;
        color: #000000;
        padding-bottom: 10px;
        border-bottom: 1px solid #dee6eb;

        @media screen and (max-width: 1440px) {
          font-size: 10px;
          padding-bottom: 5px;
        }
      }
    }

    &-one {
      grid-area: one;
    }

    &-two {
      grid-area: two;
    }

    &-three {
      grid-area: three;
    }

    &-four {
      grid-area: four;
    }

    &-five {
      grid-area: five;
    }

    &-six {
      grid-area: six;
    }
  }

  .bars {
    display: flex;
    align-items: flex-end;
    gap: 15px;
    flex: 1;

    @media screen and (max-width: 1440px) {
      gap: 8px;
    }

    &-item {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 15px;

      @media screen and (max-width: 1440px) {
        gap: 8px;
      }

      h6 {
        font-weight: 400;
        font-size: 10px;
        line-height: 115%;
        text-align: center;
        color: #000000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 64px;
      }
    }

    &-fill {
      flex: 1;
      position: relative;
      width: 100%;
      height: 100%;

      &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        bottom: 0;
        transform: translate(-50%);
        left: 50%;
        background: #dee6eb;
      }

      div {
        background: #cfc4ff;
        border-radius: 100px 100px 0 0px;
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        width: 100%;
        max-width: 50%;
      }

      span {
        font-weight: 700;
        font-size: 16px;
        line-height: 115%;
        color: #000000;
        opacity: 0.8;
        position: absolute;
        z-index: 3;
        transform: translate(-50%, -50%) rotate(-90deg);
        bottom: 30px;
        left: 50%;

        @media screen and (max-width: 1440px) {
          font-size: 10px;
          bottom: 10px;
        }
      }
    }
  }

  .horizontal {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    gap: 8px;

    &-item {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 15px;
      flex: 1;

      h6 {
        width: calc(50% - 8px);
        font-weight: 400;
        font-size: 12px;
        line-height: 115%;
        text-align: end;

        @media screen and (max-width: 1440px) {
          font-size: 10px;
        }
      }
    }

    &-fill {
      width: calc(50% - 8px);
      position: relative;
      display: flex;
      align-items: center;
      height: 60%;

      &::before {
        position: absolute;
        content: '';
        width: 1px;
        height: calc(100% + 8px);
        top: 50%;
        transform: translate(0, -50%);
        background: #dee6eb;
      }

      div {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 0px 100px 100px 0px;
      }

      span {
        font-weight: 700;
        font-size: 10px;
        line-height: 115%;
        text-align: center;
        color: #000000;
        opacity: 0.8;
        position: relative;
        z-index: 3;
        left: 10px;
      }
    }
  }
`;
