import { createAsyncThunk } from '@reduxjs/toolkit';
import { ChangeUserData, LoginData, RegisterData } from '../entities';
import {
  ApiAuthResponse, ApiResponse, ApiUser, authApi,
} from '../../api';
import { omit } from 'lodash';
import { AuthUser } from '../../entities/AuthUser';
import { accessesApi } from '../../api/accessesApi/Accesses';

export const loginUser = createAsyncThunk(
  'users/login',
  async (data: LoginData, thunkAPI) => {
    console.log('loginUser');
    try {
      const response: ApiResponse<ApiAuthResponse> = await authApi.login(data);

      if (response.statusCode === 200) {
        localStorage.setItem('access_token', response.data.accessToken!);
        localStorage.setItem(
          'access_token_expires_at',
          response.data.accessTokenExpiresAt,
        );
        localStorage.setItem(
          'access_token_end_date',
          response.data.accessTokenExpiresAt!.toString(),
        );
        localStorage.setItem('refresh_token', response.data.refreshToken!);
        localStorage.setItem(
          'refresh_token_expires_at',
          response.data.refreshTokenExpiresAt,
        );
        // @ts-ignore
        const user: ApiResponse<ApiUser> = await authApi.getUserMe();
        // @ts-ignore
        return user.data as AuthUser;
      }

      return thunkAPI.rejectWithValue('');
    } catch (e) {
      console.log('Error', e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const registerUser = createAsyncThunk(
  'users/register',
  async (data: RegisterData, thunkAPI) => {
    try {
      const response = await authApi.register(data);
      if (response.statusCode === 200) {
        localStorage.setItem('access_token', response.data.accessToken!);
        localStorage.setItem(
          'access_token_expires_at',
          response.data.accessTokenExpiresAt!.toString(),
        );
        localStorage.setItem('refresh_token', response.data.refreshToken!);
        localStorage.setItem(
          'refresh_token_expires_at',
          response.data.refreshTokenExpiresAt!.toString(),
        );
      }
    } catch (e) {
      console.log('Error', e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const changeUserData = createAsyncThunk(
  'users/changeData',
  async (data: ChangeUserData, thunkAPI) => {
    try {
      const response = await authApi.editUser(data.id, omit(data, 'id'));
      return response.statusCode;
    } catch (e) {
      console.log('Error', e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export type UserCredentials = {
  accessToken: string;
  accessTokenExpiresAt: string;
  refreshToken: string;
  refreshTokenExpiresAt: string;
  userID: number;
};

export const saveUserCredentials = createAsyncThunk(
  'users/saveTokens',
  async (
    {
      accessToken,
      accessTokenExpiresAt,
      refreshToken,
      refreshTokenExpiresAt,
      userID,
    }: UserCredentials,
    thunkAPI,
  ) => {
    console.log('saveUserCredentials');
    try {
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('access_token_expires_at', accessTokenExpiresAt);
      localStorage.setItem(
        'access_token_end_date',
        accessTokenExpiresAt!.toString(),
      );
      localStorage.setItem('refresh_token', refreshToken!);
      localStorage.setItem('refresh_token_expires_at', refreshTokenExpiresAt);

      const user: ApiResponse<AuthUser> = await authApi.getUserMe();

      return user.data as AuthUser;
    } catch (e) {
      console.log('Error', e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);
