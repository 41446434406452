import {
  INewAnswerStructure,
  IReviewType,
  IUser,
  TSentiment,
} from '../../../entities';
import * as Yup from 'yup';
import { getTranslationByLangOrEng } from '../../../i18n';
import { ApiReviewFormAnswer } from '../../../api';

export interface FormValues {
  status: any;
  comment: string;
}

export interface FormValues2 {
  reviewType: IReviewType | undefined;
}

export interface FormMessageValues {
  text: string;
  close: boolean;
  attachmentUrl?: string;
}

export interface FormValues4 {
  name: string;
  color: {
    rgb: { r: number; g: number; b: number; a?: number };
    hex: string;
  };
}

export const formInitialValues4: FormValues4 = {
  name: '',
  color: {
    rgb: {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    },
    hex: '#FFFFFF',
  },
};

export interface ReviewData {
  reviewId: number;
  reviewStatus: number;
  reviewCreatedAt: string;
  reviewUpdatedAt: string;
  wasOverdue: boolean;
}

export interface AdditionalReviewData {
  companyName?: string;
  nodeName?: string;
  nodeId?: number;
  companyId?: number;
  formName?: string;
  nodeCode?: string;
  userName?: string;
  phone?: string;
  email?: string;
  connectionChannels?: {
    [key: string]: string;
  };
  googlePlaceID?: {
    id?: number;
    value: string;
  };
  averageGoogleScoring?: string;
  files?: INewAnswerStructure[];
  formSource?: {
    name: string | number;
    id: number;
  } | null;
  responsible: string[];
  responsibleReview?: IUser;
  responsiblesReview?: IUser[];
  sentiment: TSentiment | null;
}

export type ExtendedApiReviewFormAnswer = ApiReviewFormAnswer & {
  type: string;
  position: number;
  keyQuestion: boolean;
};

export const validationSchema4 = (lang: string) => Yup.object({
  name: Yup.string().required(
    getTranslationByLangOrEng(lang, 'validation_required'),
  ),
  color: Yup.object().required(
    getTranslationByLangOrEng(lang, 'validation_required'),
  ),
});

const types: { [key: string]: string[] } = {
  audio: ['.mp3', '.wav', '.aac', '.m4a'],
  document: ['.pdf', '.doc', '.xlsx', '.csv', '.txt'],
  image: ['.png', '.jpg', '.heic', '.jpeg'],
  video: ['.mp4', '.mov'],
};

export const getFileType = (extension: string) => {
  for (const [type, extensions] of Object.entries(types)) {
    if (extensions.includes(extension)) {
      return type;
    }
  }
  return 'unknown';
};

export const closeDialogData: FormMessageValues = {
  close: true,
  text: '',
};

export const reopenDialogData: FormMessageValues = {
  close: false,
  text: '',
};

export const chatTabsEdit: (lang: string) => { [key: string]: string }[] = (
  lang,
) => [
  {
    key: 'target',
    text: getTranslationByLangOrEng(lang, 'target'),
  },
  {
    key: 'all',
    text: getTranslationByLangOrEng(lang, 'all'),
  },
];

export const possibleOptions = [
  'radio',
  'checkbox',
  'detailed_response',
  'dropdown',
  'quantity',
  'hidden',
  'nps',
  'scale',
  'csat',
  'ces',
  'file_upload',
];
export const arrNetworkValues = ['telegram', 'viber', 'whatsapp', 'google'];

export const messageTextareaStyles = {
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  borderTop: 'none',
  fontFamily: 'e-Ukraine',
  outline: 0,
};

export const issuePartNames = () => ['Тип', 'Категорія', 'Підкатегорія'];
