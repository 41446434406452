import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useAppSelector } from '../../../state';
import { TReviewCategories } from './types';
import { ReviewCategoriesStyles } from './styles';
import { ApiCategory, categoryApi } from '../../../api';
import { useClickOutside } from '../../../hooks';
import { ArrowDownIcon } from '../../../assets';
import { CategoryChips } from '../../molecules';
import { SelectOption } from './categoryOption';
export const ReviewCategories: FC<TReviewCategories> = ({
  reviewID,
  companyID,
  categories,
  setCategories
}) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [companyCategories, setCompanyCategories] = useState<ApiCategory[]>([]);
  const [open, setOpen] = useState(false);
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const handleSelect = (item: ApiCategory) => {
    const isAdded = categories.some(el => el.id === item.id);
    if (isAdded) {
      categoryApi.delCategoryFromReview(reviewID, item.id).then(res => {
        setCategories(prev => prev.filter(el => el.id !== item.id));
      });
    } else {
      categoryApi.addCategoryToReview(reviewID, item.id).then(res => {
        setCategories(prev => [...prev, item]);
      });
    }
  };
  const handleRemove = (item: ApiCategory) => {
    categoryApi.delCategoryFromReview(reviewID, item.id).then(res => {
      setCategories(prev => prev.filter(el => el.id !== item.id));
    });
  };
  useClickOutside(optionsListRef, () => {
    setOpen(false);
  }, selectedValueFieldRef.current);
  useEffect(() => {
    categoryApi.getCategoriesByCompany(companyID).then(res => {
      setCompanyCategories(res.data || []);
    });
  }, []);
  return <ReviewCategoriesStyles>
      <CategoryChips categories={categories} handleRemove={handleRemove} />
      <div className="selectWrapper">
        <div className="selectField">
          <div className="selectValue" ref={selectedValueFieldRef} onClick={() => setOpen(!open)} aria-label="Обрати категорію відгука"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0} onKeyDown={e => {
          if (e.key === 'Enter') {
            setOpen(!open);
          }
        }}>
            <div className="placeholder">Обрати категорію відгука</div>
            <div className={open ? 'active' : ''}>
              <ArrowDownIcon />
            </div>
          </div>

          {open && <div className="selectOptions" ref={optionsListRef}>
              {companyCategories.length === 0 && <div className="selectOption">
                  <div className="selectRow" style={{
              paddingLeft: '16px'
            }}>
                    <span>Empty</span>
                  </div>
                </div>}
              {companyCategories.map(el => <SelectOption key={el.id} offset={1} handleSelect={handleSelect} category={el} companyID={companyID} categories={categories} />)}
            </div>}
        </div>
      </div>
    </ReviewCategoriesStyles>;
};