export const companyTypes = {
  'diia-tsnap': 'ЦНАП',
  'diia-snap': 'СНАП',
};

export const initialExecPositionValue = [
  { id: 'Executive', value: 'Керівник' },
];

export const initialWorkersPositionValue = [
  { id: 'Employee_tsnap', value: 'Працівник' },
];

export const availableCompanyAliases = ['hsc', 'regdracs', 'diia-tsnap'];

export const excludeCompanyAliases = ['diia-tsnap'];

export const snapOrg = [
  { id: 'TSOVV', value: 'ЦОВВ' },
  {
    id: 'SNAP_REG_UST',
    value: 'СНАП регіональні установи',
  },
  { id: 'SNAP', value: 'СНАП' },
];

export const initialOrganisationValue = {
  'diia-tsnap': [
    { id: 'DTministery', value: 'Мінцифри' },
    { id: 'DP_Diia', value: 'ДП Дія' },
    { id: 'ODA', value: 'ОДА' },
    { id: 'OMS', value: 'ОМС' },
    { id: 'TSNAP', value: 'ЦНАП' },
  ],
  'diia-snap': snapOrg,
  hsc: snapOrg,
  regdracs: snapOrg,
};

export const initialPositionValue = {
  DTministery: initialExecPositionValue,
  DP_Diia: initialExecPositionValue,
  ODA: initialExecPositionValue,
  OMS: initialExecPositionValue,
  TSOVV: initialExecPositionValue,
  SNAP_REG_UST: initialExecPositionValue,
  default: [...initialExecPositionValue, ...initialWorkersPositionValue],
};

export const excludePositionField = (organisationId: string) => !['DTministery', 'DP_Diia', 'ODA', 'OMS', 'TSOVV', 'SNAP_REG_UST'].includes(
  organisationId,
);

export const nodesLevelToExclude: { [key: string]: number[] } = {
  DTministery: [1, 2, 3, 4],
  DP_Diia: [1, 2, 3, 4],
  ODA: [2, 3, 4],
  OMS: [],
  TSNAP: [],
  TSOVV: [1, 2, 3, 4],
  SNAP_REG_UST: [2, 3, 4],
  SNAP: [],
};
