import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useAppSelector } from '../../../../state';
import { getTranslationByLangOrEng } from '../../../../i18n';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import SubmitButton from '../../../form/submitButton/SubmitButton';
import { getErrorMessage, handleKeyUp } from '../../../../utils';
import { Checkbox, InputField } from '../../../molecules';
import { companySettingsApi } from '../../../../api';
type TDepthNotificationsResponsible = {
  apiNotifyOverdueEnable?: boolean;
  apiNotifyRemindEnable?: boolean;
  apiNotifyOverdueLevels?: number[];
  apiNotifyRemindLevels?: number[];
};
interface FormValues {
  notifyOverdueEnable?: boolean;
  notifyRemindEnable?: boolean;
  notifyOverdueLevels?: string;
  notifyRemindLevels?: string;
}
export const DepthNotificationsResponsible: FC<TDepthNotificationsResponsible> = React.memo(({
  apiNotifyOverdueEnable,
  apiNotifyRemindEnable,
  apiNotifyOverdueLevels,
  apiNotifyRemindLevels
}) => {
  const {
    id
  } = useParams();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [initialValues, setInitialValues] = useState<FormValues>();
  const [errorMessage, setErrorMessage] = useState<any>({
    notifyOverdueEnable: false,
    notifyRemindEnable: false,
    notifyOverdueLevels: '',
    notifyRemindLevels: ''
  });
  const validationSchema = Yup.object().shape({
    notifyOverdueLevels: Yup.string().matches(/^\d+(,\d+)*$/, 'Invalid forman').test('no-trailing-comma', 'Last char do not be ,', value => {
      if (value) {
        return !value.endsWith(',');
      }
      return true;
    })
  });
  async function onSubmit(values: FormValues, {
    setSubmitting
  }: FormikHelpers<FormValues>) {
    setSubmitting(false);
    const promises = [];
    if (id) {
      promises.push(companySettingsApi.updateCompanySettingsKey(+id, 'notify-overdue-enabled', {
        value: values.notifyOverdueEnable
      }));
      promises.push(companySettingsApi.updateCompanySettingsKey(+id, 'notify-remind-enabled', {
        value: values.notifyRemindEnable
      }));
      promises.push(companySettingsApi.updateCompanySettingsKey(+id, 'notify-overdue-levels', {
        value: values.notifyOverdueLevels?.split(',').map(e => +e)
      }));
      promises.push(companySettingsApi.updateCompanySettingsKey(+id, 'notify-remind-levels', {
        value: values.notifyRemindLevels?.split(',').map(e => +e)
      }));
      Promise.all(promises).then(res => {
        setInitialValues({
          notifyOverdueEnable: values.notifyOverdueEnable,
          notifyRemindEnable: values.notifyRemindEnable,
          notifyOverdueLevels: values.notifyOverdueLevels,
          notifyRemindLevels: values.notifyRemindLevels
        });
      });
    }
  }
  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue
  }: FormikProps<FormValues>) => <Form>
        <div className="col">
          <p>Прострочені відгуки</p>
          <div className="col-child">
            <Checkbox hideError name="notifyOverdueEnable" value={!!values.notifyOverdueEnable} onChange={() => setFieldValue('notifyOverdueEnable', !values.notifyOverdueEnable)} />
            <InputField name="notifyOverdueLevels" showError={false} onChange={setFieldValue} onKeyUp={() => handleKeyUp('notifyOverdueLevels', setErrorMessage, errorMessage)} placeholder="Відправка сповіщень для рівнів ієрархії:" value={values.notifyOverdueLevels} error={typeof errorMessage === 'object' ? getErrorMessage('notifyOverdueLevels', errorMessage) : undefined} disabled={!values.notifyOverdueEnable} />
          </div>
        </div>
        <div className="col">
          <p>Неопрацьовані відгуки</p>
          <div className="col-child">
            <Checkbox hideError name="notifyRemindLevels" value={!!values.notifyRemindLevels} onChange={() => setFieldValue('notifyRemindLevels', !values.notifyRemindLevels)} />
            <InputField name="notifyRemindLevels" showError={false} onChange={setFieldValue} onKeyUp={() => handleKeyUp('notifyRemindLevels', setErrorMessage, errorMessage)} placeholder="Відправка сповіщень для рівнів ієрархії:" value={values.notifyRemindLevels} error={typeof errorMessage === 'object' ? getErrorMessage('notifyRemindLevels', errorMessage) : undefined} disabled={!values.notifyRemindLevels} />
          </div>
        </div>
        <SubmitButton isError={touched && Object.keys(errors).length > 0}>
          {getTranslationByLangOrEng(interfaceLanguage, 'save_button')}
        </SubmitButton>

        {typeof errorMessage === 'string' && <p className="extraErrorMessage">{errorMessage}</p>}
      </Form>;
  useEffect(() => {
    setInitialValues({
      notifyOverdueEnable: !!apiNotifyOverdueEnable,
      notifyRemindEnable: !!apiNotifyRemindEnable,
      notifyOverdueLevels: apiNotifyOverdueLevels?.join(',') || '',
      notifyRemindLevels: apiNotifyRemindLevels?.join(',') || ''
    });
  }, [apiNotifyOverdueEnable, apiNotifyOverdueLevels, apiNotifyRemindEnable, apiNotifyRemindLevels]);
  return <div className="depthNotificationsResponsible">
        <h3>Сповіщення про прострочені та неопрацьовані відгуки</h3>
        {!!initialValues && <div className="formContainer">
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
            {renderForm}
          </Formik>
        </div>}
        <div className="horizontalLine" />
      </div>;
});