import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { GeneralFormInfoStyles } from './styles';
import DefaultCompanyImage from '../../../assets/images/defaultCompanyImage.png';
import { ApiConstructorForm, TAdditionalParams, companyApi, constructorApi, sourcesApi } from '../../../api';
import { useNavigate, useParams } from 'react-router-dom';
import { FieldArray, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { SubmitButton } from '../../atoms';
import { Checkbox, CustomSelect, InputField, RadioButton } from '../../molecules';
import { getErrorMessage, handleKeyUp, proxyFile } from '../../../utils';
import { IFieldColor, IFieldFile, addAdditionalParam, mapApiColorToFieldColor, mapApiFileToFieldFile, mapFieldColorToApiColor } from '../fieldConstructor';
import { ISource } from '../../../entities';
import CustomColorsSelect from '../../form/customSelect/CustomColorsSelect';
import { CheckMark } from '../../../assets';
import SelectWithAnyItems from '../../form/customSelect/SelectWithAnyItems';
const defaultDivisionLine: IFieldColor = {
  name: 'Division color',
  color: '#000'
};
const defaultStep: IFieldColor = {
  name: 'Step color',
  color: '#000'
};
const defaultElements: IFieldColor = {
  name: 'Diia color',
  color: '#000'
};
const defaultBg: IFieldColor = {
  name: 'Diia bg',
  color: '#ccc'
};
export interface IGeneralFormInfo {
  name: string;
  logo: string;
  status: string;
  logoLocation: string;
  logo_selected: IFieldFile | null;
  default_mascot: IFieldFile | null;
  dividing_decorative_line_width_in_percent: number;
  start_page_button_percent_width: number;
  dividing_decorative_line_height: number;
  radio_button_border_width: number;
  radio_button_checked_border_radius: number;
  radio_button_checked_sizes: number;
  dividing_decorative_line_color: IFieldColor;
  steps_text_underline_color: IFieldColor;
  elements_color: IFieldColor;
  not_checked_elements_color: IFieldColor;
  steps_text_color: IFieldColor;
  background_color: IFieldColor;
  anonymous: boolean;
  withSteps: boolean;
  transparent_bg: boolean;
  is_printable: boolean;
  show_node_code: boolean;
  show_dividing_decorative_line: boolean;
  sourceID: number;
}
const initialValue: IGeneralFormInfo = {
  name: '',
  logo: '',
  status: 'false',
  logoLocation: 'left',
  logo_selected: null,
  default_mascot: null,
  dividing_decorative_line_width_in_percent: 90,
  start_page_button_percent_width: 100,
  dividing_decorative_line_height: 2,
  radio_button_border_width: 1,
  radio_button_checked_border_radius: 22,
  radio_button_checked_sizes: 18,
  dividing_decorative_line_color: defaultDivisionLine,
  steps_text_underline_color: defaultDivisionLine,
  steps_text_color: defaultStep,
  elements_color: defaultElements,
  background_color: defaultBg,
  not_checked_elements_color: defaultElements,
  anonymous: false,
  is_printable: false,
  // font_weight_bold: false,
  withSteps: false,
  transparent_bg: false,
  // font_family: null,
  show_dividing_decorative_line: false,
  show_node_code: false,
  sourceID: 0
};
const validationSchema = Yup.object({
  name: Yup.string().required('* Required')
});
export const GeneralFormInfo = () => {
  const {
    companyId,
    formId
  } = useParams();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<IGeneralFormInfo>();
  const [apiForm, setApiForm] = useState<ApiConstructorForm | null>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [companyImages, setCompanyImages] = useState<IFieldFile[]>([]);
  const [companyFonts, setCompanyFonts] = useState<IFieldFile[]>();
  const [companyColors, setCompanyColors] = useState<IFieldColor[]>([]);
  const [companySources, setCompanySources] = useState<ISource[]>([]);
  async function getCompanyMetadata() {
    if (companyId) {
      const res = await companyApi.getCompanyMetadata(+companyId!);
      if (res.statusCode >= 200 && res.statusCode < 300) {
        setCompanyImages(res.data.filter(item => item.key.startsWith('image_')).map(item => {
          const imageParts = item.value.split('|||');
          return {
            name: imageParts[0],
            url: imageParts[1]
          };
        }));
        setCompanyColors(res.data.filter(item => item.key.startsWith('color_')).map(item => {
          const colorParts = item.value.split('|||');
          return {
            name: colorParts[0],
            color: colorParts[1]
          };
        }));
        setCompanyFonts(res.data.filter(item => item.key.startsWith('font_')).map(item => {
          const fontParts = item.value.split('|||');
          return {
            name: fontParts[0],
            url: fontParts[1]
          };
        }));
        sourcesApi.getSourcesByCompanyId(+companyId).then(res => {
          setCompanySources(res.data);
        });
      }
    }
  }
  const onSubmit = async (values: IGeneralFormInfo, {
    setSubmitting
  }: FormikHelpers<IGeneralFormInfo>) => {
    setSubmitting(false);
    let options: TAdditionalParams[] = [];
    let styles: TAdditionalParams[] = [];
    if (companyId) {
      if (formId && apiForm) {
        options = [...apiForm.options];
        styles = [...apiForm.styles];
        addAdditionalParam(values.show_node_code, 'show_node_code', options);
        addAdditionalParam(values.withSteps, 'withSteps', options);
        addAdditionalParam(values.is_printable, 'is_printable', options);
        addAdditionalParam(mapFieldColorToApiColor(values.dividing_decorative_line_color), 'dividing_decorative_line_color', styles);
        addAdditionalParam(mapFieldColorToApiColor(values.steps_text_color), 'steps_text_color', styles);
        addAdditionalParam(mapFieldColorToApiColor(values.steps_text_underline_color), 'steps_text_underline_color', styles);
        addAdditionalParam(mapFieldColorToApiColor(values.elements_color), 'elements_color', styles);
        addAdditionalParam(mapFieldColorToApiColor(values.not_checked_elements_color), 'not_checked_elements_color', styles);
        addAdditionalParam(mapFieldColorToApiColor(values.background_color), 'background_color', styles);
        addAdditionalParam(values.show_dividing_decorative_line, 'show_dividing_decorative_line', styles);
        addAdditionalParam(values.logoLocation, 'logoLocation', styles);
        addAdditionalParam(values.dividing_decorative_line_width_in_percent, 'dividing_decorative_line_width_in_percent', styles);
        addAdditionalParam(values.start_page_button_percent_width, 'start_page_button_percent_width', styles);
        addAdditionalParam(values.transparent_bg, 'transparent_bg', styles);
        addAdditionalParam(values.dividing_decorative_line_height, 'dividing_decorative_line_height', styles);
        addAdditionalParam(values.radio_button_border_width, 'radio_button_border_width', styles);
        addAdditionalParam(values.radio_button_checked_border_radius, 'radio_button_checked_border_radius', styles);
        addAdditionalParam(values.radio_button_checked_sizes, 'radio_button_checked_sizes', styles);
        if (values.logo_selected) {
          addAdditionalParam(`${values.logo_selected.name}|||${values.logo_selected.url}`, 'logo_selected', styles);
        }
        if (values.default_mascot) {
          addAdditionalParam(`${values.default_mascot.name}|||${values.default_mascot.url}`, 'default_mascot', styles);
        }
        constructorApi.updateForm(+formId, {
          name: values.name,
          status: values.status,
          anonymous: values.anonymous,
          logo: values.logo,
          companyID: +companyId,
          sourceID: values.sourceID,
          styles,
          options
        }).then(res => {
          console.log(res);
          toast.success('Форма успішно оновлена');
        });
      } else {
        addAdditionalParam(values.show_node_code, 'show_node_code', options);
        addAdditionalParam(values.withSteps, 'withSteps', options);
        addAdditionalParam(values.is_printable, 'is_printable', options);
        addAdditionalParam(mapFieldColorToApiColor(values.dividing_decorative_line_color), 'dividing_decorative_line_color', styles);
        addAdditionalParam(mapFieldColorToApiColor(values.steps_text_color), 'steps_text_color', styles);
        addAdditionalParam(mapFieldColorToApiColor(values.steps_text_underline_color), 'steps_text_underline_color', styles);
        addAdditionalParam(mapFieldColorToApiColor(values.elements_color), 'elements_color', styles);
        addAdditionalParam(mapFieldColorToApiColor(values.not_checked_elements_color), 'not_checked_elements_color', styles);
        addAdditionalParam(mapFieldColorToApiColor(values.background_color), 'background_color', styles);
        addAdditionalParam(values.show_dividing_decorative_line, 'show_dividing_decorative_line', styles);
        addAdditionalParam(values.logoLocation, 'logoLocation', styles);
        addAdditionalParam(values.dividing_decorative_line_width_in_percent, 'dividing_decorative_line_width_in_percent', styles);
        addAdditionalParam(values.start_page_button_percent_width, 'start_page_button_percent_width', styles);
        addAdditionalParam(values.transparent_bg, 'transparent_bg', styles);
        addAdditionalParam(values.dividing_decorative_line_height, 'dividing_decorative_line_height', styles);
        addAdditionalParam(values.radio_button_border_width, 'radio_button_border_width', styles);
        addAdditionalParam(values.radio_button_checked_border_radius, 'radio_button_checked_border_radius', styles);
        addAdditionalParam(values.radio_button_checked_sizes, 'radio_button_checked_sizes', styles);
        if (values.logo_selected) {
          addAdditionalParam(`${values.logo_selected.name}|||${values.logo_selected.url}`, 'logo_selected', styles);
        }
        if (values.default_mascot) {
          addAdditionalParam(`${values.default_mascot.name}|||${values.default_mascot.url}`, 'default_mascot', styles);
        }
        constructorApi.createForm({
          name: values.name,
          status: values.status,
          anonymous: values.anonymous,
          logo: values.logo,
          companyID: +companyId,
          sourceID: values.sourceID,
          styles,
          options
        }).then(res => {
          console.log(res);
          toast.success('Форма успішно створена');
          navigate(`/companies/${companyId}/forms/${res.data.id}`);
        });
      }
    }
  };
  const renderForm = ({
    values,
    setFieldValue,
    handleChange
  }: FormikProps<IGeneralFormInfo>) => <Form>
      <InputField name="name" onChange={setFieldValue} onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)} placeholder="Назва форми" value={values.name} error={typeof errorMessage === 'object' ? getErrorMessage('name', errorMessage) : undefined} />

      <Checkbox name="anonymous" value={values.anonymous!} onChange={handleChange}>
        <span className="checkboxValue">Анонімна форма</span>
      </Checkbox>

      <Checkbox name="is_printable" value={values.is_printable!} onChange={handleChange}>
        <span className="checkboxValue">Можливість друку</span>
      </Checkbox>

      <Checkbox name="show_node_code" value={values.show_node_code!} onChange={handleChange}>
        <span className="checkboxValue">Показати код вузла</span>
      </Checkbox>

      <CustomSelect label="Джерело відгуків" name="sourceID" options={companySources || []} valueField="id" labelField="name" placeholder="Оберіть джерело відгуків" selected={companySources?.find(source => source.id === values.sourceID)} onChange={(value: any) => {
      setFieldValue('sourceID', value.id);
    }} search />

      <div className="constructor-line" />

      <div className="img-container">
        <h4>Логотип</h4>
        <div className="img-wrapper">
          <div className={values.logo_selected ? 'img-target' : 'img-target img-default'}>
            {values.logo_selected ? <img className="img-selected" src={proxyFile(values.logo_selected.url, true)} alt={values.logo_selected.name} /> : <img src={DefaultCompanyImage} alt="" />}
          </div>
          <div className="img-upload">
            <p>Виберіть логотип із завантажених зображень суб’єкту моніторингу</p>
            <SelectWithAnyItems name="logo" placeholder="Виберіть логотип" options={companyImages} value={values.logo_selected} handleSelect={file => {
            setFieldValue('logo_selected', file);
            setFieldValue('logo', file.url);
          }} formGroupStyles={{
            width: '300'
          }} hideErrors renderItem={option => <div className="img-optionwrapper">
                  <div className="img-optionimg">
                    <img src={proxyFile(option.url, true)} alt={option.name} />
                  </div>

                  <p>{option.name}</p>
                </div>} renderSelect={value => <p>{value.name}</p>} />
          </div>
        </div>
      </div>

      <div className="constructor-radiowrapper">
        <h4>Місце розташування логотипу</h4>
        <FieldArray name="logoLocation" render={helpers => <div className="constructor-radio">
              {['left', 'center', 'right'].map((value, index) => <div className="radioFieldContainer" key={value}>
                  <RadioButton name={`logoLocation${value}`} value={value.toString()} onChange={event => {
            setFieldValue('logoLocation', event.target.value);
          }} checked={values.logoLocation === value}>
                    <div className="radioText">{value}</div>
                  </RadioButton>
                </div>)}
            </div>} />
      </div>

      <div className="color-wrapper">
        <h6>Колір фону</h6>

        <div className="color-selector">
          <CustomColorsSelect name="background_color" placeholder="Виберіть колір" options={companyColors} value={values.background_color} handleSelect={color => {
          setFieldValue('background_color', {
            color: color.color,
            name: color.name
          });
        }} extraComponentPosition="left" extraStyles={{
          flexGrow: 1
        }} handleAddColor={color => {
          setFieldValue('background_color', {
            color: color.color,
            name: color.name
          });
        }} />
        </div>
      </div>

      <Checkbox name="transparent_bg" value={values.transparent_bg!} onChange={handleChange}>
        <span className="checkboxValue">Градієнтна прозорість фону</span>
      </Checkbox>

      <div className="constructor-line" />

      <div className="radio-wrapper">
        <div className="radio-item" style={{
        width: +values.radio_button_checked_border_radius,
        height: +values.radio_button_checked_border_radius,
        border: `${values.radio_button_border_width}px solid ${values.elements_color.color}`,
        borderRadius: '50%'
      }}>
          <div className="radio-item-checked" style={{
          width: +values.radio_button_checked_sizes,
          height: +values.radio_button_checked_sizes,
          backgroundColor: values.elements_color.color as string,
          borderRadius: '50%'
        }} />
        </div>

        <div className="radio-item" style={{
        width: +values.radio_button_checked_sizes,
        height: +values.radio_button_checked_sizes,
        border: `${values.radio_button_border_width || 1}px solid ${values.elements_color.color}`
      }}>
          <CheckMark color={values.elements_color.color as string} />
        </div>

        <div className="radio-item" style={{
        width: +values.radio_button_checked_border_radius,
        height: +values.radio_button_checked_border_radius,
        border: `${values.radio_button_border_width}px solid ${values.not_checked_elements_color.color}`,
        borderRadius: '50%'
      }} />

        <div className="radio-item" style={{
        width: +values.radio_button_checked_border_radius,
        height: +values.radio_button_checked_border_radius,
        border: `${values.radio_button_border_width}px solid ${values.not_checked_elements_color.color}`
      }} />
      </div>

      <div className="color-wrapper">
        <h6>Колір елементів</h6>

        <div className="color-selector">
          <CustomColorsSelect name="elements_color" placeholder="Виберіть колір" options={companyColors} value={values.elements_color} handleSelect={color => {
          setFieldValue('elements_color', {
            color: color.color,
            name: color.name
          });
        }} extraComponentPosition="left" extraStyles={{
          flexGrow: 1
        }} handleAddColor={color => {
          setFieldValue('elements_color', {
            color: color.color,
            name: color.name
          });
        }} />
        </div>
      </div>
      <div className="color-wrapper">
        <h6>Колір не позначених елементів</h6>

        <div className="color-selector">
          <CustomColorsSelect name="not_checked_elements_color" placeholder="Виберіть колір" options={companyColors} value={values.not_checked_elements_color} handleSelect={color => {
          setFieldValue('not_checked_elements_color', {
            color: color.color,
            name: color.name
          });
        }} extraComponentPosition="left" extraStyles={{
          flexGrow: 1
        }} handleAddColor={color => {
          setFieldValue('not_checked_elements_color', {
            color: color.color,
            name: color.name
          });
        }} />
        </div>
      </div>
      <InputField name="radio_button_border_width" type="number" disabled={!values.radio_button_border_width} min={0} onChange={setFieldValue} onKeyUp={() => handleKeyUp('radio_button_border_width', setErrorMessage, errorMessage)} placeholder="Ширина межі елементів" value={values.radio_button_border_width} error={typeof errorMessage === 'object' ? getErrorMessage('radio_button_border_width', errorMessage) : undefined} />
      <InputField name="radio_button_checked_border_radius" type="number" disabled={!values.radio_button_checked_border_radius} min={0} onChange={setFieldValue} onKeyUp={() => handleKeyUp('radio_button_checked_border_radius', setErrorMessage, errorMessage)} placeholder="Відмічені перемикачі радіусу межі" value={values.radio_button_checked_border_radius} error={typeof errorMessage === 'object' ? getErrorMessage('radio_button_checked_border_radius', errorMessage) : undefined} />
      <InputField name="radio_button_checked_sizes" type="number" disabled={!values.radio_button_checked_sizes} min={0} onChange={setFieldValue} onKeyUp={() => handleKeyUp('radio_button_checked_sizes', setErrorMessage, errorMessage)} placeholder="Перевірений розмір перемикача" value={values.radio_button_checked_sizes} error={typeof errorMessage === 'object' ? getErrorMessage('radio_button_checked_sizes', errorMessage) : undefined} />

      <div className="constructor-line" />

      <div className="img-container">
        <h4>Головний талісман</h4>
        <div className="img-wrapper">
          <div className={values.default_mascot ? 'img-target' : 'img-target img-default'}>
            {values.default_mascot ? <img className="img-selected" src={proxyFile(values.default_mascot.url, true)} alt={values.default_mascot.name} /> : <img src={DefaultCompanyImage} alt="" />}
          </div>
          <div className="img-upload">
            <p>Виберіть талісман із завантажених зображень суб’єкту моніторингу</p>
            <SelectWithAnyItems name="default_mascot" placeholder="Виберіть талісман" options={companyImages} value={values.default_mascot} handleSelect={file => {
            setFieldValue('default_mascot', file);
          }} formGroupStyles={{
            width: '300'
          }} hideErrors renderItem={option => <div className="img-optionwrapper">
                  <div className="img-optionimg">
                    <img src={proxyFile(option.url, true)} alt={option.name} />
                  </div>

                  <p>{option.name}</p>
                </div>} renderSelect={value => <p>{value.name}</p>} />
          </div>
        </div>
      </div>

      <div className="constructor-radiowrapper">
        <h4>Ширина кнопки стартової сторінки</h4>
        <FieldArray name="start_page_button_percent_width" render={helpers => <div className="constructor-radio">
              {[100, 50, 30].map((value, index) => <div className="radioFieldContainer" key={value}>
                  <RadioButton name={`start_page_button_percent_width_${value}`} value={value.toString()} onChange={event => {
            setFieldValue('start_page_button_percent_width', +event.target.value);
          }} checked={values.start_page_button_percent_width === value}>
                    <div className="radioText">{`${value}%`}</div>
                  </RadioButton>
                </div>)}
            </div>} />
      </div>

      <div className="constructor-line" />

      <Checkbox name="show_dividing_decorative_line" value={values.show_dividing_decorative_line!} onChange={handleChange}>
        <span className="checkboxValue">
          Показати розділову декоративну лінію форми
        </span>
      </Checkbox>

      <div className="constructor-radiowrapper">
        <h4>Довжина декоративної лінії у відсотках</h4>
        <FieldArray name="dividing_decorative_line_width" render={helpers => <div className="constructor-radio">
              {[90, 60, 30].map((value, index) => <div className="radioFieldContainer" key={value}>
                  <RadioButton disabled={!values.show_dividing_decorative_line} name={`dividing_decorative_line_width_in_percent_${value}`} value={value.toString()} onChange={event => {
            setFieldValue('dividing_decorative_line_width_in_percent', +event.target.value);
          }} checked={values.dividing_decorative_line_width_in_percent === value}>
                    <div className="radioText">{`${value}%`}</div>
                  </RadioButton>
                </div>)}
            </div>} />
      </div>

      <InputField name="dividing_decorative_line_height" type="number" disabled={!values.show_dividing_decorative_line} min={0} onChange={setFieldValue} onKeyUp={() => handleKeyUp('dividing_decorative_line_height', setErrorMessage, errorMessage)} placeholder="Значення за замовчуванням" value={values.dividing_decorative_line_height} error={typeof errorMessage === 'object' ? getErrorMessage('dividing_decorative_line_height', errorMessage) : undefined} />

      <div className="color-wrapper">
        <h6>Колір декоративної лінії</h6>

        <div className="color-selector">
          <CustomColorsSelect name="dividing_decorative_line_color" placeholder="Виберіть колір" disabled={!values.show_dividing_decorative_line} options={companyColors} value={values.dividing_decorative_line_color} handleSelect={color => {
          setFieldValue('dividing_decorative_line_color', {
            color: color.color,
            name: color.name
          });
        }} extraComponentPosition="left" extraStyles={{
          flexGrow: 1
        }} handleAddColor={color => {
          setFieldValue('dividing_decorative_line_color', {
            color: color.color,
            name: color.name
          });
        }} />
        </div>
      </div>

      {/* <div className="constructor-line" />
       <CustomSelect
        name="fonts"
        options={companyFonts || []}
        label="Шрифт форми"
        placeholder="Оберіть шрифт форми"
        valueField="url"
        labelField="name"
        selected={values.font_family}
        onChange={(value: any) => {
          setFieldValue('font_family', value);
        }}
        search
       />
       <Checkbox
        name="font_weight_bold"
        value={values.font_weight_bold!}
        onChange={handleChange}
       >
        <span className="checkboxValue">
          Жирний шрифт для запитань форми
        </span>
       </Checkbox>
       <div className="constructor-line" /> */}

      <Checkbox name="withSteps" value={values.withSteps!} onChange={handleChange}>
        <span className="checkboxValue">Форма з кроками</span>
      </Checkbox>

      <div className="color-wrapper">
        <h6>Колір тексту кроку</h6>

        <div className="color-selector">
          <CustomColorsSelect name="steps_text_color" placeholder="Виберіть колір" options={companyColors} value={values.steps_text_color} disabled={!values.withSteps} handleSelect={color => {
          setFieldValue('steps_text_color', {
            color: color.color,
            name: color.name
          });
        }} extraComponentPosition="left" extraStyles={{
          flexGrow: 1
        }} handleAddColor={color => {
          setFieldValue('steps_text_color', {
            color: color.color,
            name: color.name
          });
        }} />
        </div>
      </div>

      <div className="color-wrapper">
        <h6>Колір підкреслення тексту кроку</h6>

        <div className="color-selector">
          <CustomColorsSelect name="steps_text_underline_color" placeholder="Виберіть колір" options={companyColors} disabled={!values.withSteps} value={values.steps_text_underline_color} handleSelect={color => {
          setFieldValue('steps_text_underline_color', {
            color: color.color,
            name: color.name
          });
        }} extraComponentPosition="left" extraStyles={{
          flexGrow: 1
        }} handleAddColor={color => {
          setFieldValue('steps_text_underline_color', {
            color: color.color,
            name: color.name
          });
        }} />
        </div>
      </div>

      <div className="constructor-line" />

      <SubmitButton extraBlockStyles={{
      marginTop: '20px'
    }} extraButtonStyles={{
      padding: '15px 45px'
    }}>
        Зберегти
      </SubmitButton>
    </Form>;
  useEffect(() => {
    getCompanyMetadata();
    if (formId) {
      constructorApi.getForm(+formId).then(res => {
        const show_node_code = res.data.options.find(option => option.key === 'show_node_code')?.value === 'true';
        const withSteps = res.data.options.find(option => option.key === 'withSteps')?.value === 'true';
        const is_printable = res.data.options.find(option => option.key === 'is_printable')?.value === 'true';
        // const font_weight_bold = res.data.styles.find((option) => option.key === 'font_weight_bold')?.value === 'true'
        const show_dividing_decorative_line = res.data.styles.find(option => option.key === 'show_dividing_decorative_line')?.value === 'true';
        const transparent_bg = res.data.styles.find(option => option.key === 'transparent_bg')?.value === 'true';
        const dividing_decorative_line_width_in_percent = res.data.styles.find(option => option.key === 'dividing_decorative_line_width_in_percent')?.value || 90;
        const start_page_button_percent_width = res.data.styles.find(option => option.key === 'start_page_button_percent_width')?.value || 100;
        const dividing_decorative_line_height = res.data.styles.find(option => option.key === 'dividing_decorative_line_height')?.value || 2;
        const radio_button_border_width = res.data.styles.find(option => option.key === 'radio_button_border_width')?.value || 1;
        const radio_button_checked_border_radius = res.data.styles.find(option => option.key === 'radio_button_checked_border_radius')?.value || 22;
        const radio_button_checked_sizes = res.data.styles.find(option => option.key === 'radio_button_checked_sizes')?.value || 18;
        const dividing_decorative_line_color = res.data.styles.find(option => option.key === 'dividing_decorative_line_color')?.value ? mapApiColorToFieldColor(res.data.styles.find(option => option.key === 'dividing_decorative_line_color')?.value!) : defaultDivisionLine;
        const steps_text_underline_color = res.data.styles.find(option => option.key === 'steps_text_underline_color')?.value ? mapApiColorToFieldColor(res.data.styles.find(option => option.key === 'steps_text_underline_color')?.value!) : defaultDivisionLine;
        const steps_text_color = res.data.styles.find(option => option.key === 'steps_text_color')?.value ? mapApiColorToFieldColor(res.data.styles.find(option => option.key === 'steps_text_color')?.value!) : defaultStep;
        // let font_family = null;
        const background_color = res.data.styles.find(option => option.key === 'background_color')?.value ? mapApiColorToFieldColor(res.data.styles.find(option => option.key === 'background_color')?.value!) : defaultBg;
        const elements_color = res.data.styles.find(option => option.key === 'elements_color')?.value ? mapApiColorToFieldColor(res.data.styles.find(option => option.key === 'elements_color')?.value!) : defaultElements;
        const not_checked_elements_color = res.data.styles.find(option => option.key === 'not_checked_elements_color')?.value ? mapApiColorToFieldColor(res.data.styles.find(option => option.key === 'not_checked_elements_color')?.value!) : defaultElements;
        const logoLocation = res.data.styles.find(option => option.key === 'logoLocation')?.value || 'left';
        const logo_selected = res.data.styles.find(option => option.key === 'logo_selected')?.value ? mapApiFileToFieldFile(res.data.styles.find(option => option.key === 'logo_selected')?.value!) : null;
        const default_mascot = res.data.styles.find(option => option.key === 'default_mascot')?.value ? mapApiFileToFieldFile(res.data.styles.find(option => option.key === 'default_mascot')?.value!) : null;

        // if (res.data.styles.find((option) => option.key === 'font_family')?.value) {
        //   const fontArr = res.data.styles.find((option) => option.key === 'font_family')?.value.split('|||')
        //   if (fontArr) {
        //     font_family = {
        //       url: fontArr[1], name: fontArr[0],
        //     }
        //   }
        // }

        setApiForm(res.data);
        setInitialValues({
          name: res.data.name,
          logo: res.data.logo,
          status: res.data.status,
          anonymous: res.data.anonymous,
          not_checked_elements_color,
          is_printable,
          default_mascot,
          logoLocation,
          transparent_bg,
          elements_color,
          background_color,
          sourceID: res.data.sourceID,
          show_dividing_decorative_line,
          steps_text_underline_color,
          steps_text_color,
          logo_selected,
          // font_weight_bold,
          // font_family,
          withSteps,
          show_node_code,
          dividing_decorative_line_width_in_percent: +dividing_decorative_line_width_in_percent,
          start_page_button_percent_width: +start_page_button_percent_width,
          dividing_decorative_line_height: +dividing_decorative_line_height,
          radio_button_border_width: +radio_button_border_width,
          radio_button_checked_border_radius: +radio_button_checked_border_radius,
          radio_button_checked_sizes: +radio_button_checked_sizes,
          dividing_decorative_line_color
        });
      });
    } else {
      setInitialValues(initialValue);
    }
  }, []);
  return <GeneralFormInfoStyles>
      <div className="constructor">
        {initialValues && <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
            {renderForm}
          </Formik>}
      </div>
    </GeneralFormInfoStyles>;
};