import React, { FC } from 'react';
import { TIcon } from './TIcon';
export const ListRegime: FC<TIcon> = React.memo(({
  width = 20,
  height = 20,
  color = '#768E9D',
  bgColor = '#D7DFE4'
}) => <svg width={width} height={height} viewBox="0 0 20 20" fill="none">
    <rect width="20" height="20" fill={bgColor} />
    <rect x="3.08228" y="3" width="14" height="3.01385" rx="1" stroke={color} strokeWidth="2" />
    <rect x="3.08228" y="8.5" width="14" height="3.01385" rx="1" stroke={color} strokeWidth="2" />
    <rect x="3.08228" y="14" width="14" height="3.01385" rx="1" stroke={color} strokeWidth="2" />
  </svg>);