import React, { FC, useRef, useState } from 'react';
import { ArrowDownIcon, CheckMark, SearchIcon } from '../../../assets';
import { IUserRole } from '../../../entities';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppSelector } from '../../../state';
import { RoleSelectStyles } from './RoleSelectStyles';
import { InView } from 'react-intersection-observer';
import { Loader } from '../../atoms';
import { availablePageSizes } from '../../../constants';
import { rolesApi } from '../../../api';
type TRoleSelect = {
  label?: string;
  name: string;
  required?: boolean;
  value: any;
  placeholder: string;
  search?: boolean;
  userRoles: number[];
  handleSelect: (role: IUserRole) => void;
  error?: string | undefined;
  autoloading?: boolean;
  autoloadable?: boolean;
  setSearchQuery?: (query: string) => void;
};
export const RoleSelect: FC<TRoleSelect> = React.memo(({
  label,
  name,
  required,
  value,
  placeholder,
  search,
  userRoles,
  handleSelect,
  error,
  autoloading,
  autoloadable,
  setSearchQuery
}) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [roleList, setRoleList] = useState<IUserRole[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const pageSizeRef = useRef<number>(availablePageSizes[0]);
  useClickOutside(optionsListRef, () => {
    setOpen(false);
  }, selectedValueFieldRef.current);
  async function handleLoadMore() {
    setUploading(true);
    rolesApi.getRoles(currentPage * pageSizeRef.current, pageSizeRef.current, searchParams).then(response => {
      setRoleList([...(roleList || []), ...(Array.isArray(response.data) ? response.data : [])]);
      setUploading(false);
    });
    setCurrentPage(currentPage + 1);
  }
  async function handleLoadWithSearch(searchQuery: string) {
    setUploading(true);
    rolesApi.getRoles(0 * pageSizeRef.current, pageSizeRef.current, searchQuery).then(response => {
      setRoleList([...(Array.isArray(response.data) ? response.data : [])]);
      setUploading(false);
    });
  }
  return <RoleSelectStyles>
        {label && <div className="labelContainer">
            <label htmlFor={name}>
              <span>
                {label}
                {required && <span className="required"> *</span>}
              </span>
            </label>
          </div>}
        <div className="selectField">
          <div className="selectedValue" onClick={() => setOpen(!open)} ref={selectedValueFieldRef}>
            <span className="placeholder">{placeholder}</span>
            <div className={open ? 'arrowDownActive' : ''}>
              <ArrowDownIcon />
            </div>
          </div>

          {open && <div className="options" ref={optionsListRef}>
              {search && <div className="searchWrapper">
                  <SearchIcon />
                  <input type="text" value={searchParams} onChange={e => {
            if (setSearchQuery) {
              setSearchQuery(e.target.value);
            }
            setSearchParams(e.target.value);
            handleLoadWithSearch(e.target.value);
          }} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search')} />
                </div>}
              {roleList.length && roleList.filter(role => role.id !== 26).filter(role => role.name.toLowerCase()).map(role => <div className="option" key={`selectOption:${role.id}`}>
                      <div className="rowSel">
                        <button type="button" onClick={() => {
              handleSelect(role);
            }}>
                          <div className="labelContent">
                            <div className="default">
                              {userRoles.includes(role.id!) && <CheckMark />}
                            </div>
                            <span>{role.name}</span>
                          </div>
                        </button>
                      </div>
                    </div>)}
              {autoloadable && !!handleLoadMore && <InView as="div" onChange={inView => {
          if (inView && !autoloading) {
            handleLoadMore();
          }
        }}>
                  {(isLoading || autoloading) && <Loader margin={0} height={20} />}
                </InView>}
            </div>}
        </div>
        <div className="formErrorContainer">
          {error && <span>{error}</span>}
        </div>
      </RoleSelectStyles>;
});