import { compiledBlock as _compiledBlock } from "million/react";
const NameCustomField_1 = _compiledBlock(_props => <div className="customNameWrapper">
      <div className={_props.v0}>
        <div className="image">
          {_props.v1}
        </div>
      </div>
      <span>{_props.v2}</span>
    </div>, {
  name: "NameCustomField_1",
  portals: ["v1", "v2"]
});
const NameCustomField = function NameCustomField({
  logo,
  name
}: {
  logo: string;
  name: string;
}) {
  return /*@million jsx-skip*/<NameCustomField_1 v0={logo ? 'imageContainer' : 'imageContainer default'} v1={logo && <img src={proxyFile(logo, true)} alt="logo" />} v2={name} />;
};
import { block as _block } from "million/react";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CompaniesStyles } from './CompaniesStyles';
import { Link, useNavigate } from 'react-router-dom';
import { DeleteIcon, FeedbacksListIcon, PencilIcon, SearchIcon } from '../../assets';
import { ApiCompany, companyApi } from '../../api';
import { useDispatch } from 'react-redux';
import { setSelectedCompany, useAppDispatch, useAppSelector } from '../../state';
import { CompaniesTableCompany, ICompany } from '../../entities';
import { getTranslationByLangOrEng } from '../../i18n';
import { Helmet } from 'react-helmet-async';
import { Loader, Modal, SubHeader, Table, TransparentButton } from '../../components';
import { proxyFile } from '../../utils';
import { usePermissionView } from '../../hooks';

// {hasAnyAccess('/companies/*/settings').view && (
//   <>
//     <Link
//       onClick={() => dispatch(setSelectedCompany(company))}
//       to={`/companies/${id}/edit`}
//       type="button"
//       aria-label="Редагувати"
//     >
//       <PencilIcon />
//     </Link>
//     <button
//       type="button"
//       onClick={() => {
//         setModal(true);
//       }}
//       aria-label="Видалити субʼєкт моніторингу"
//     >
//       <DeleteIcon />
//     </button>
//   </>
// )}

function EditButtons({
  id,
  company,
  filterCompaniesAfterDelete
}: {
  id: string;
  company: ICompany;
  filterCompaniesAfterDelete: (id: number) => void;
}) {
  const dispatch = useDispatch();
  const hasAnyAccess = usePermissionView();
  const [modal, setModal] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<boolean>(false);
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  return <>
      {modal && <Modal title={getTranslationByLangOrEng(interfaceLanguage, 'companies_main_delete_title')} onClose={() => setModal(false)} extraStyles={{
      width: '544px'
    }}>
          <div className="modalButtonsWrap">
            <p className="modalButtonsCaption">
              {getTranslationByLangOrEng(interfaceLanguage, 'companies_main_delete_description')}
            </p>
            <div>
              <button type="button" onClick={() => setModal(false)}>
                {getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
              </button>
              <button type="button" onClick={() => {
            companyApi.deleteCompany(+id).then(res => {
              if (res.statusCode === 200) {
                filterCompaniesAfterDelete(+id);
              } else {
                setDeleteError(true);
              }
            });
          }}>
                {getTranslationByLangOrEng(interfaceLanguage, 'delete_button')}
              </button>
            </div>
            {deleteError && <p className="modalError">
                {getTranslationByLangOrEng(interfaceLanguage, 'delete_error')}
              </p>}
          </div>
        </Modal>}
      <div className="editButtonsContainer">
        <div className="editButtons">
          <div className="splitter">
            <div />
          </div>

          <Link className="openButton" title={getTranslationByLangOrEng(interfaceLanguage, 'go_to_reviews_page_button_title')} type="button" aria-label="Перейти до відгуків" to={`/reviews/page/1?companyID=${id}`}>
            <FeedbacksListIcon />
          </Link>
        </div>
      </div>
    </>;
}
async function addLogoToCompany(data: Partial<ApiCompany>[]) {
  const results: Partial<CompaniesTableCompany>[] = await Promise.all(data.map(async item => {
    const metaData = await companyApi.getCompanyMetadataByCompanyId(+item!.id!);
    const logo = metaData.data.logo;
    return {
      ...item,
      logo: !(logo?.value instanceof File) ? logo?.value : undefined
    };
  }));
  return results;
}
export const Companies = () => {
  const navigate = useNavigate();
  const hasAnyAccess = usePermissionView();
  const [searchParams, setSearchParams] = useState<string>('');
  const [companies, setCompanies] = useState<Partial<CompaniesTableCompany>[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const {
    data: userdata
  } = useAppSelector(state => state.user);
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  useEffect(() => {
    setUploading(true);
    companyApi.getCompanies().then(async res => {
      if (res.statusCode >= 200 && res.statusCode <= 200) {
        const data = addLogoToCompany(userdata?.id === 44 ? res.data.filter(el => el.id === 109) : res.data);
        setCompanies(await data);
      }
    }).catch(e => {
      console.log(e);
    }).finally(() => {
      setUploading(false);
    });
  }, []);
  const filterCompaniesAfterDelete = useCallback((id: number) => {
    setCompanies(companies.filter(company => company.id !== id));
  }, [companies]);
  const filteredCompanies = useMemo(() => companies.filter(company => company.name!.toLowerCase().includes(searchParams.toLowerCase())), [searchParams, companies, uploading]);
  const companiesTableHeaders = React.useMemo(() => [{
    Header: getTranslationByLangOrEng(interfaceLanguage, 'companies_main_table_company_name_header'),
    accessor: 'name',
    // eslint-disable-next-line react/no-unstable-nested-components
    Cell: (data: any) => <NameCustomField name={data.row.original.name} logo={data.row.original.logo} />
  }, {
    Header: 'Custom field',
    accessor: 'customField',
    // eslint-disable-next-line react/no-unstable-nested-components
    Cell: (data: any) => <div className="company-btns">
            <button className="company-btn" onClick={() => navigate(`/companies/${data.row.original.id}/nodes`)} type="button" aria-label="Перейти до структури">
              Структура
            </button>
            {hasAnyAccess('/companies/*/users').view && <button className="company-btn" onClick={() => navigate(`/companies/${data.row.original.id}/nodes`, {
        state: {
          tab: 'company-users'
        }
      })} type="button" aria-label="Перейти до користувачів">
                Користувачі
              </button>}
            {hasAnyAccess('/companies/*/forms').view && <button className="company-btn" onClick={() => navigate(`/companies/${data.row.original.id}/nodes`, {
        state: {
          tab: 'company-forms'
        }
      })} type="button" aria-label="Перейти до форм">
                Форми
              </button>}
          </div>
  }, {
    Header: 'Edit buttons',
    accessor: 'editButtons',
    // eslint-disable-next-line react/no-unstable-nested-components
    Cell: (data: any) => <EditButtons id={data.row.original.id} company={data.row.original} filterCompaniesAfterDelete={filterCompaniesAfterDelete} />
  }], [companies, interfaceLanguage]);
  const headerColumnCounts = {
    company: filteredCompanies.length
  };
  const extraFieldsSettings = {
    customField: {
      alignItems: 'center'
    }
  };
  return <CompaniesStyles>
      <Helmet>
        <title>Субʼєкти моніторингу</title>
      </Helmet>

      <div style={{
      padding: 15
    }}>
        <SubHeader title={getTranslationByLangOrEng(interfaceLanguage, 'companies_main_title')}>
          {hasAnyAccess('/companies/*/settings').view && <TransparentButton handleClick={() => navigate('/companies/create')} text={getTranslationByLangOrEng(interfaceLanguage, 'companies_main_add_company_button')} filled />}
        </SubHeader>

        <div className="searchWrapper">
          <div className="search">
            <SearchIcon />
            <input placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search_for_name')} type="text" value={searchParams} onChange={e => setSearchParams(e.target.value)} />
          </div>
        </div>
        <div style={{
        width: 15
      }} />
        {uploading ? <Loader /> : <Table pagination columns={companiesTableHeaders} data={filteredCompanies} hideFieldsSplitters extraFieldsSettings={extraFieldsSettings} headerColumnCounts={headerColumnCounts} pageSize={filteredCompanies.length} sortableColumns={['name']} hiddenHeaders={['customField', 'editButtons', 'customFormsField']} fullWidthColumns={['editButtons']} />}
      </div>
    </CompaniesStyles>;
};