import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { CsiDashboardStyles } from './styles';
import { BarChart, PieChart } from '../../analyticsCharts';
import { analyticsApi, ApiCsi, fieldsApi, TPostCsi, TPostReviewsByStatuses } from '../../../../api';
import { DataItem } from '../../../../pages';
import { Loader } from '../../../atoms';
import { setSaveFilters, setSaveLink, useAppDispatch } from '../../../../state';
type PieData = {
  label: string;
  data: DataItem[];
};
type TCsiDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[] | boolean;
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
};
const colors: string[] = ['#6EAE93', '#A7C5E9', '#F5EF67', '#BB6685'];
export const CsiDashboard: FC<TCsiDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions
}) => {
  const dispatch = useAppDispatch();
  const [csiData, setCsiData] = useState<ApiCsi[]>([]);
  const [chartData, setChartData] = useState<ApiCsi[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const reviewCount = useRef<number>(0);
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      cnapTypes,
      isDiia,
      sourceIDs,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostCsi = {
      from: from as string,
      to: to as string,
      isDiia: isDiia as boolean,
      sourceIDs: sourceIDs as number[],
      filters: {
        regions: regions as number[],
        relatedByOrgans: relatedByOrgans as number[],
        cnapTypes: cnapTypes as string[],
        organTypes: organTypes as string[],
        idfs: idfs as string[]
      }
    };
    setLoading(true);
    analyticsApi.getCsiDashboardData(+companyID, filteredData).then(res => {
      setLabels(res.data[0].pie.map(el => el.label));
      setCsiData(res.data);
      setLoading(false);
    });
    const regionFilteredData: TPostReviewsByStatuses = {
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      sourceIDs: sourceIDs as number[],
      isDiia: isDiia as boolean,
      regionLevel: 3
    };
    analyticsApi.getRegionReviewsByStatuses(+companyID, regionFilteredData).then(res => {
      if (res.statusCode === 200) {
        reviewCount.current = res.data.reviewsCount;
      }
    });
    dispatch(setSaveLink('analytics/csi/file'));
    dispatch(setSaveFilters({
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      sourceIDs: sourceIDs as number[],
      from: from as string,
      to: to as string,
      isDiia: isDiia as boolean
    }));
  }, [reQuery]);
  useEffect(() => {
    if (csiData) {
      const loadAdditionalData = async () => {
        const results = await Promise.all(csiData.filter(csi => csi.fieldId).map(async item => {
          const res = await fieldsApi.getTargetField(item.fieldId);
          const fieldLabel = res.data.options.find(el => el.key === 'analytics_title')?.value || res.data.question;
          return {
            ...item,
            label: fieldLabel
          };
        }));
        setChartData(results);
      };
      loadAdditionalData();
    }
  }, [csiData]);
  if (loading) {
    return <CsiDashboardStyles>
        <Loader />
      </CsiDashboardStyles>;
  }
  return <CsiDashboardStyles>
      <h3>Всього відгуків: {reviewCount.current}</h3>
      <div className="csi">
        <div className="csi-wrapper">
          {chartData.map(el => <div className="csi-item">
              <div className="csi-bar" style={{
            height: `${dimensions.height / 3}px`
          }}>
                <h3 style={{
              height: `${el.satisfied}%`
            }}>
                  <span>{el.satisfied.toFixed(1)}%</span>
                </h3>
              </div>
              <h5>{el.label}</h5>
              <div className="csi-pie">
                {el.pie.map((e, i) => ({
              label: e.label,
              value: e.percent,
              count: e.count,
              fill: colors[i]
            })).map((el, index) => <div className="csi-legend">
                    {!!(index % 2) && <span style={{
                background: colors[index]
              }} />}
                    <p>{`${el.value}% (${el.count})`}</p>
                    {!(index % 2) && <span style={{
                background: colors[index]
              }} />}
                  </div>)}
                <PieChart width={dimensions.width / 9} height={dimensions.width / 9} data={el.pie.map((e, i) => ({
              label: e.label,
              value: e.percent,
              count: e.count,
              fill: colors[i]
            }))} />
              </div>
            </div>)}
        </div>
        <div className="csi-labels">
          {labels.map((el, index) => <div className="csi-label">
              <span style={{
            background: colors[index]
          }} />
              <p>{el}</p>
            </div>)}
        </div>
      </div>
    </CsiDashboardStyles>;
};