import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TablePaginationStyles } from './TablePaginationStyles';
import { useWindowSize } from 'usehooks-ts';
import { validateInputNumber } from './TablePaginationUtils';
import { useAppSelector } from '../../../../state';
import { useDebounce } from '../../../../hooks';
import { ArrowLeft, ArrowRight } from '../../../../assets';
import { getTranslationByLangOrEng } from '../../../../i18n';
import { availablePageSizes } from '../../../../constants';
import { DropdownSelector } from '../../../atoms/dropdownSelector';
interface TablePaginationProps {
  pageSize: number;
  currentPage: number;
  setPageSize: Dispatch<SetStateAction<number>> | undefined;
  rowsQuantity: number;
  pageNumberHandler: (number: number) => void;
}
export const TablePagination = React.memo(({
  pageSize,
  currentPage,
  rowsQuantity,
  setPageSize,
  pageNumberHandler
}: TablePaginationProps) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [pageNumber, setPageNumber] = useState<string | number>();
  const [pageQuantity, setPageQuantity] = useState<number>(1);
  const [canPreviousPage, setCanPreviousPage] = useState<boolean>(false);
  const [canNextPage, setCanNextPage] = useState<boolean>(false);
  const {
    width
  } = useWindowSize();
  const debouncedPageNumber = useDebounce(pageNumber, 1000);
  useEffect(() => {
    if (debouncedPageNumber) {
      if (debouncedPageNumber > pageQuantity) {
        setPageNumber(pageQuantity);
        pageNumberHandler(pageQuantity);
      } else {
        pageNumberHandler(parseInt(debouncedPageNumber, 10));
      }
    }
  }, [debouncedPageNumber]);
  useEffect(() => {
    setPageQuantity(Math.ceil(rowsQuantity / pageSize));
  }, [rowsQuantity, pageSize]);
  useEffect(() => {
    setPageNumber(currentPage);
    setCanPreviousPage(currentPage > 1);
    setCanNextPage(currentPage < Math.ceil(rowsQuantity / pageSize));
  }, [currentPage]);
  function gotoPage(pageNumber: number) {
    pageNumberHandler(pageNumber);
  }
  return <TablePaginationStyles mobileVersion={width <= 500}>
        {setPageSize !== undefined && <DropdownSelector setValue={e => setPageSize(parseInt(e, 10))} value={pageSize} initialValues={availablePageSizes} />}
        <div className="arrowsContainer">
          <div className="arrows">
            <button onClick={() => gotoPage(currentPage - 1)} disabled={!canPreviousPage} type="button" aria-label="Стрілка назад">
              <ArrowLeft color={canPreviousPage ? '#000' : '#999'} />
            </button>
          </div>

          <div className="pageCounter">
            <input width={48} height={48} className="currentPage" id="defaultValue" type="text" value={pageNumber} onChange={event => validateInputNumber(event.target.value) ? setPageNumber(event.target.value) : null} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'pagination_page_number_placeholder')} />
          </div>

          <div className="arrows">
            <button onClick={() => gotoPage(currentPage + 1)} disabled={!canNextPage} type="button" aria-label="Стрілка вперед">
              <ArrowRight color={canNextPage ? '#000' : '#999'} />
            </button>
          </div>
          <span>із {pageQuantity}</span>
        </div>
      </TablePaginationStyles>;
});