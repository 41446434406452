import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { ApiSmsCampaigns, massMailingApi } from '../../../api';
import { CampaignStyles, StatusesCounterStyles } from './CampaignStyles';
import { format } from 'date-fns';
import { ArrowDownIcon, Trash } from '../../../assets';
import { TransparentButton } from '../../atoms';
import { options } from './utils';
import { useAppSelector } from '../../../state';
import { getTranslationByLangOrEng } from '../../../i18n';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { ChartCampaignsSms, TCampaign } from './TCampaign';
import { Modal } from '../modal';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const coloredStats: {
  [key: string]: string;
} = {
  itemsTotal: '#acacac',
  itemsDelivered: '#60a518',
  itemsFormOpened: '#be65e1',
  itemsFailed: '#fc6b5a',
  itemsFormSubmitted: '#3ea1ee'
};
const keysToInclude: Array<keyof ChartCampaignsSms> = ['itemsTotal', 'itemsFormOpened', 'itemsFormSubmitted', 'itemsDelivered', 'itemsFailed'];
const config: {
  [key: string]: {
    [key: string]: string;
  };
} = {
  itemsTotal: {
    color: '#acacac',
    label: 'Всього'
  },
  itemsDelivered: {
    color: '#60a518',
    label: 'Успішно'
  },
  itemsFormOpened: {
    color: '#be65e1',
    label: 'Відкрили форму'
  },
  itemsFailed: {
    color: '#fc6b5a',
    label: 'Не вдалося'
  },
  itemsFormSubmitted: {
    color: '#3ea1ee',
    label: 'Відправили форму'
  }
};
export const CampaignSms: FC<TCampaign> = React.memo(({
  campaign,
  selectedCampaign,
  setSelectedCampaign,
  type,
  companyID,
  handleDelete
}) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isInfoVisible, setInfoVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [stats, setStats] = useState<ApiSmsCampaigns | null>(null);
  const data = useMemo(() => ({
    labels: ['Кількість'],
    datasets: keysToInclude.map((key, index) => {
      if (key === 'itemsFormOpened') {
        return {
          label: getTranslationByLangOrEng(interfaceLanguage, key),
          data: stats ? [stats.itemsFormOpened] : [],
          borderColor: coloredStats[key],
          backgroundColor: coloredStats[key]
        };
      }
      if (key === 'itemsFormSubmitted') {
        return {
          label: getTranslationByLangOrEng(interfaceLanguage, key),
          data: stats ? [stats.itemsFormSubmitted] : [],
          borderColor: coloredStats[key],
          backgroundColor: coloredStats[key]
        };
      }
      return {
        label: getTranslationByLangOrEng(interfaceLanguage, key),
        data: [campaign[key]],
        borderColor: coloredStats[key],
        backgroundColor: coloredStats[key]
      };
    })
  }), [campaign, stats]);
  const description: string = useMemo(() => keysToInclude.filter(el => el !== 'itemsFormOpened' && el !== 'itemsFormSubmitted').map(el => `${getTranslationByLangOrEng(interfaceLanguage, el).toLocaleUpperCase()}: ${campaign[el as keyof typeof campaign]}`).join(' | '), [campaign, keysToInclude, interfaceLanguage]);
  const handleToggleInfo = () => {
    setInfoVisible(!isInfoVisible);
    setSelectedCampaign(campaign);
  };
  useEffect(() => {
    setInfoVisible(selectedCampaign ? selectedCampaign.id === campaign.id : false);
  }, [selectedCampaign]);
  useEffect(() => {
    setLoading(true);
    if (type === 'SMS' && isInfoVisible) {
      massMailingApi.massMailingSmsCampaignStats(campaign.id).then(res => {
        if (res.statusCode === 200) {
          setStats(res.data);
        }
      });
    }
    if (type === 'Viber' && isInfoVisible) {
      massMailingApi.massMailingViberCampaignStats(campaign.id).then(res => {
        if (res.statusCode === 200) {
          setStats(res.data);
        }
      });
    }
  }, [type, isInfoVisible]);
  return <CampaignStyles>
        <div onClick={handleToggleInfo} className="campaign-header">
          <div className="campaign-title">
            <h3>{campaign.name}</h3>
            <p>{description}</p>
          </div>
          <div className="campaign-date">
            Date of creation:{' '}
            {format(new Date(campaign.createdAt), 'dd.MM.yyyy')}
          </div>
          <div className={isInfoVisible ? 'arrow' : 'arrow active'}>
            <ArrowDownIcon />
          </div>
        </div>
        <div className={`campaign-info-content ${isInfoVisible ? 'show' : ''}`}>
          <div className="btn-container">
            <button type="button" onClick={() => setIsModalVisible(true)} className="del-btn">
              <Trash width={20} height={20} />
              <span>Видалити</span>
            </button>
          </div>
          <StatusesCounterStyles>
            <div className="wrapper">
              {!!stats && keysToInclude.map(item => <div key={item} className="status">
                  <div className="statusHead">
                    <div className="statusCount">
                      <p style={{
                  color: config[item].color
                }}>
                        {campaign[item] ?? stats[item]}
                      </p>
                    </div>
                  </div>
                  <div className="statusContent">
                    <div className="statusName">{config[item].label}</div>
                  </div>
                </div>)}
            </div>
          </StatusesCounterStyles>
          <Bar height={40} options={options} data={data} />
        </div>
        {isModalVisible && <Modal title="Are you sure?" onClose={() => setIsModalVisible(false)} extraStyles={{
      width: 400
    }}>
            <div className="delete_modal">
              <p className="subtitle">
                Are you sure you want to delete the mailling ({campaign.name})
                and that the changes will be irreversible?
              </p>
              <div className="row">
                <TransparentButton filled extraStyles={{
            backgroundColor: '#ccc',
            borderColor: '#ccc'
          }} text="Cancell" handleClick={() => setIsModalVisible(true)} />
                <TransparentButton filled text="Delete" extraStyles={{
            backgroundColor: 'red',
            borderColor: 'red'
          }} handleClick={() => handleDelete(campaign.id)} />
              </div>
            </div>
          </Modal>}
      </CampaignStyles>;
});