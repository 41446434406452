import React, { FC } from 'react';
import { Modal } from '../Modal';
import { ImgModalStyles } from './ImgModalStyles';
import { proxyFile } from '../../../../utils';
type Props = {
  onClose: () => void;
  url: string;
};
export const ImgModal: FC<Props> = React.memo(({
  onClose,
  url
}) => <ImgModalStyles>
    <Modal onClose={onClose}>
      <img src={proxyFile(url, true)} alt="img" />
    </Modal>
  </ImgModalStyles>);