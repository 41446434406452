import * as Yup from 'yup';
export const validationSchema = Yup.object({
  position: Yup.object().nullable().required('Оберіть посаду'),
  type: Yup.object().nullable().required('Оберіть субʼєкт моніторингу'),
  organisation: Yup.object().nullable().required('Оберіть установу'),
  position_string: Yup.string().required('Зазначте назву посади'),
  0: Yup.object().nullable().when('organisation', {
    is: (organisation: {
      id: string;
    }) => organisation && ['DTministery', 'DP_Diia'].includes(organisation.id),
    then: Yup.object().nullable().required(''),
    otherwise: Yup.object().nullable().notRequired()
  }),
  1: Yup.object().nullable().when('organisation', {
    is: (organisation: {
      id: string;
    }) => organisation && ['DTministery', 'DP_Diia', 'TSOVV'].includes(organisation.id),
    then: Yup.object().nullable().notRequired(),
    otherwise: Yup.object().nullable().required('Оберіть регіон')
  }),
  2: Yup.object().nullable().when('organisation', {
    is: (organisation: {
      id: string;
    }) => organisation && ['DTministery', 'DP_Diia', 'TSOVV'].includes(organisation.id),
    then: Yup.object().nullable().notRequired(),
    otherwise: Yup.object().nullable().when('organisation', {
      is: (organisation: {
        id: string;
      }) => organisation && ['ODA', 'SNAP_REG_UST'].includes(organisation.id),
      then: Yup.object().nullable().notRequired(),
      otherwise: Yup.object().nullable().required('Оберіть район')
    })
  }),
  3: Yup.object().nullable().when('organisation', {
    is: (organisation: {
      id: string;
    }) => organisation && ['DTministery', 'DP_Diia', 'TSOVV'].includes(organisation.id),
    then: Yup.object().nullable().notRequired(),
    otherwise: Yup.object().nullable().when('organisation', {
      is: (organisation: {
        id: string;
      }) => organisation && ['ODA', 'SNAP_REG_UST'].includes(organisation.id),
      then: Yup.object().nullable().notRequired(),
      otherwise: Yup.object().nullable().required('Оберіть орган, що утворив центр')
    })
  }),
  4: Yup.array().nullable().when('organisation', {
    is: (organisation: {
      id: string;
    }) => organisation && ['DTministery', 'DP_Diia', 'TSOVV'].includes(organisation.id),
    then: Yup.array().nullable().notRequired(),
    otherwise: Yup.array().nullable().when('organisation', {
      is: (organisation: {
        id: string;
      }) => organisation && ['ODA', 'SNAP_REG_UST'].includes(organisation.id),
      then: Yup.array().nullable().notRequired(),
      otherwise: Yup.array().nullable().when('organisation', {
        is: (organisation: {
          id: string;
        }) => organisation && ['TSNAP'].includes(organisation.id),
        then: Yup.array().nullable().when('position', {
          is: (position: {
            id: string;
          }) => position && ['Executive'].includes(position.id),
          then: Yup.array().nullable().notRequired(),
          otherwise: Yup.array().nullable().test('array-has-selected-item', 'Оберіть фронт-офіс', items => !!items?.filter(e => e.value)?.length)
        }).notRequired(),
        otherwise: Yup.array().nullable().test('array-has-selected-item', 'Оберіть фронт-офіс', items => !!items?.filter(e => e.value)?.length)
      })
    })
  })
});