import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { DropdownFilterStyles } from './DropdownFilterStyles';
import { TDropdownFilter, TDropdownFilterValues } from './TDropdownFilter';
import { Loader, SubmitButton, TransparentButton } from '../../atoms';
import { translations } from '../../../i18n';
import { extraOneOptionStyles, resetFilterButtonStyles } from '../reviewsTableFilters/reviewsTableFiltersUtils';
import CustomSelectWithMultipleCheckboxes from '../../form/customSelect/CustomSelectWithMultipleCheckboxes';
import { useAppSelector } from '../../../state';
export const DropdownFilter = React.memo(({
  search,
  resizable,
  name,
  initialValues,
  filterParams,
  setReQuery,
  setApply,
  setFilterParams,
  onClose,
  width,
  filterWidth
}: TDropdownFilter) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const ref = useRef<any>();
  const formRef = useRef<FormikProps<TDropdownFilterValues>>(null);
  // useMemo
  const persistedValues: TDropdownFilterValues = useMemo(() => ({
    [name]: initialValues?.map(initialValue => ({
      ...initialValue,
      // persist previos filter params
      value: typeof filterParams[name] === 'object' ? filterParams[name]?.some((param: string) => String(param) === initialValue.id) : initialValue.id === String(filterParams[name])
    }))
  }), []);
  async function onSubmit(values: TDropdownFilterValues, {
    setSubmitting
  }: FormikHelpers<TDropdownFilterValues>) {
    const params: {
      [key: string]: any;
    } = {};
    const checkedValues: Array<string> = values[name]?.filter(item => item.value).map(e => e.id) ?? [];
    params[name] = checkedValues;
    const resultParams: {
      [key: string]: any;
    } = {};
    Object.entries({
      ...filterParams,
      ...params
    }).forEach(item => {
      // console.log(item);
      if (!!item[0] && item[1] !== undefined && item[1] !== null && item[1] !== '') {
        resultParams[item[0]] = item[1];
      }
    });
    setFilterParams(resultParams);
    setApply(resultParams);
    setReQuery();
    setSubmitting(false);
  }
  const additionalResetHandlers = () => {
    if (filterParams[name]) {
      delete filterParams[name];
    }
    setFilterParams({
      ...filterParams
    });
    setApply({
      ...filterParams
    });
    setReQuery();
    onClose();
  };
  const renderForm = ({
    values,
    errors,
    setFieldValue,
    handleChange,
    resetForm
  }: FormikProps<TDropdownFilterValues>) => <Form>
        <div style={{
      height: '100%',
      maxHeight: 300
    }}>
          <SubmitButton extraButtonStyles={{
        width: '100%',
        maxWidth: '500px',
        height: 32,
        fontSize: 16,
        fontWeight: 500
      }}>
            {translations[interfaceLanguage].apply_filters_button}
          </SubmitButton>
          <TransparentButton handleClick={() => {
        resetForm();
        additionalResetHandlers();
      }} text={translations[interfaceLanguage].reset_reviews_filter} extraStyles={resetFilterButtonStyles} extraButtonTextStyles={{
        color: '#ffffff',
        fontSize: 16,
        fontWeight: 500
      }} />
          <CustomSelectWithMultipleCheckboxes search={search} name={name} handleChange={handleChange} options={values[name] || []} extraOneOptionStyles={extraOneOptionStyles} optionsContainerStyles={{
        width: '100%'
      }} placeholder={translations[interfaceLanguage].select_multiple_Scores} placeholderStyles={{
        color: '#6c6c6c'
      }} oneOptionValueStyles={{
        marginLeft: '4px'
      }} isLoading={false} alwaysOpened />
        </div>
      </Form>;
  return <DropdownFilterStyles filterWidth={filterWidth} width={width} style={resizable ? {
    overflow: 'auto',
    resize: 'both',
    width
  } : {
    width
  }} ref={ref}>
        {initialValues ? <Formik innerRef={formRef} initialValues={persistedValues} onSubmit={onSubmit}>
            {renderForm}
          </Formik> : <Loader margin={0} />}
      </DropdownFilterStyles>;
});