import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { DataForDownloadDashboardStyles } from './styles';
import { analyticsApi, ApiDataForDownload, TPostDataForDownload } from '../../../../api';
import { Table } from '../../../organisms';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
import { uk
// eslint-disable-next-line import/no-duplicates
} from 'date-fns/locale';
import { setSaveFilters, setSaveLink, useAppDispatch } from '../../../../state';
type TDataForDownloadDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[] | boolean;
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
};
const questions: string[] = ['Як ви загалом оцінюєте якість надання державної послуги?', 'Як ви оцінюєте перелік доступних послуг?', 'Чи порекомендуєте ви Центр, як основне місце для отримання державних послуг, родичам, друзям, знайомим?', 'Чи комфортно ви почувалися в приміщенні?', 'Чи якісно організовано процес надання послуги?', 'Будь ласка, вкажіть, чому вас не задовольнив перелік послуг:', 'Будь ласка, поясніть, чому вам не сподобалася робота персоналу:', 'Будь ласка, поясніть, чому вам не сподобалося місце надання послуги:', 'Розкажіть про свої враження від державного сервісу, якщо хочете', 'Оберіть зручний канал комунікації'];
const DataForDownloadDashboard_1 = _compiledBlock(_props => _props.v0, {
  name: "DataForDownloadDashboard_1",
  portals: ["v0"]
});
export const DataForDownloadDashboard: FC<TDataForDownloadDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions
}) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<ApiDataForDownload[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  const [pageNumber, setPageNumber] = useState(1);
  const dataCount = useRef<number>(0);
  useEffect(() => {
    if (!loading) {
      setData([]);
      setPageNumber(1);
      setReload(!reload);
    }
  }, [reQuery]);
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      cnapTypes,
      sourceIDs,
      isDiia,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostDataForDownload = {
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      sourceIDs: sourceIDs as number[],
      isDiia: isDiia as boolean,
      regionLevel: 3
    };
    setLoading(true);
    analyticsApi.getDataForDownload(+companyID, filteredData, (pageNumber ? +pageNumber - 1 : 0) * 10, 10).then(res => {
      if (res.statusCode === 200) {
        setData(prev => [...prev, ...res.data]);
        dataCount.current = res.count ? res.count : 0;
        setLoading(false);
      }
    }).finally(() => {
      setLoading(false);
    });
    dispatch(setSaveLink('analytics/reviews/list/file'));
    dispatch(setSaveFilters({
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      sourceIDs: sourceIDs as number[],
      isDiia: isDiia as boolean,
      regionLevel: 3
    }));
  }, [pageNumber, reload]);
  useEffect(() => {
    if (data.length) {
      setTableHeaders([{
        Header: 'ID Відгуку',
        accessor: 'clientID',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <div className="rating-col">
              <span style={{
            color: '#000'
          }}>{data.row.original.clientID}</span>
            </div>
      }, {
        Header: 'Дата відгука',
        accessor: 'createdAt',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <div className="rating-col">
              <span style={{
            color: '#000'
          }}>{format(new Date(data.row.original.createdAt), 'dd MMMM yyyy', {
              locale: uk
            })}</span>
            </div>
      }, {
        Header: 'Місяць',
        accessor: 'formattedDate',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <div className="rating-col">
              <span style={{
            color: '#000'
          }}>{format(new Date(data.row.original.createdAt), 'dd.MM')}</span>
            </div>
      }, {
        Header: 'IDF',
        accessor: 'idf',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <div className="rating-col">
              <span style={{
            color: '#000'
          }}>{data.row.original.idf}</span>
            </div>
      }, {
        Header: 'Область',
        accessor: 'state',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <div className="rating-col">
              <span style={{
            color: '#000'
          }}>{data.row.original.state}</span>
            </div>
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <div className="dashboard-name">
              <span>Назва центру</span>
            </div>,
        accessor: 'name',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <div className="rating-col">
              <span style={{
            color: '#000'
          }}>{data.row.original.centerName}</span>
            </div>
      }, {
        Header: 'Тип органу що утворив',
        accessor: 'createdCenterType',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <div className="rating-col">
              <span style={{
            color: '#000'
          }}>{data.row.original.createdCenterType}</span>
            </div>
      }, {
        Header: 'Назва органу, що утворив центр',
        accessor: 'createdCenterName',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <div className="rating-col">
              <span style={{
            color: '#000'
          }}>{data.row.original.createdCenterName}</span>
            </div>
      }, ...questions.map((el, index) => ({
        Header: el,
        accessor: `${el}`,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <div className="rating-col">
              <span style={{
            color: '#000'
          }}>{data.row.original.answers.find((e: any) => e.field.question.includes(el))?.answer}</span>
            </div>
      })), {
        Header: 'Коментар',
        accessor: 'comment',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <div className="rating-col">
              <span style={{
            color: '#000'
          }}>{data.row.original.comment}</span>
            </div>
      }]);
    }
  }, [data]);
  return /*@million jsx-skip*/<DataForDownloadDashboard_1 v0={<DataForDownloadDashboardStyles>
      <div className="rating">
        {tableHeaders && <Table columns={tableHeaders} data={data} fullWidthColumns={['name']} totalCount={dataCount.current} currentPage={pageNumber} offset={10} loading={loading} pageNumberHandler={value => setPageNumber(value)} fixedHeader />}
      </div>
    </DataForDownloadDashboardStyles>} />;
};