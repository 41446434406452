import styled from 'styled-components';

export const MassmailerStyles = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  .massmailing-subheader {
    width: 100%;
    display: flex;
    gap: 20px;
    align-items: center;
    padding-left: 20px;
  }
  .history {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
    height: 100%;
    padding: 20px;

    .infinite-scroll-component__outerdiv {
      height: 100%;
      width: 100%;
    }

    &-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      gap: 10px;
    }
  }

  .modalBody {
    background: #fff;
    min-width: 320px;
    transition: all 0.3s ease-in;
    max-height: 80vh;
  }
`;
