export enum TabKeys {
  csi = 'csi',
  csiDetailed = 'csiDetailed',
  npsDetailed = 'npsDetailed',
  npsCsiSatisfaction = 'npsCsiSatisfaction',
  csiRegionRatings = 'csiRegionRatings',
  ratingPopulationOne = 'ratingPopulationOne',
  ratingPopulationTwo = 'ratingPopulationTwo',
  ratingPopulationThree = 'ratingPopulationThree',
  ratingPopulationFour = 'ratingPopulationFour',
  ratingPopulationFive = 'ratingPopulationFive',
  ratingPopulationSix = 'ratingPopulationSix',
  ratingReviewsOne = 'ratingReviewsOne',
  ratingReviewsTwo = 'ratingReviewsTwo',
  ratingReviewsThree = 'ratingReviewsThree',
  ratingReviewsFour = 'ratingReviewsFour',
  ratingReviewsFive = 'ratingReviewsFive',
  ratingReviewsSix = 'ratingReviewsSix',
  ratingVrmReviews = 'ratingVrmReviews',
  complexRating = 'complexRating',
  locationDissatisfaction = 'locationDissatisfaction',
  locationOutOfRating = 'locationOutOfRating',
  locationWithoutReviews = 'locationWithoutReviews',
  generalProcessDissatisfaction = 'generalProcessDissatisfaction',
  staffDissatisfaction = 'staffDissatisfaction',
  complexGeneral = 'complexGeneral',
  servicesList = 'servicesList',
  qrPublishing = 'qrPublishing',
  qrPublishingPie = 'qrPublishingPie',
  qrPublishingNodes = 'qrPublishingNodes',
  reviewsByStatuses = 'reviewsByStatuses',
  regionReviewsByStatuses = 'regionReviewsByStatuses',
  dynamic = 'dynamic',
  informationRespondents = 'informationRespondents',
  dataForDownload = 'dataForDownload',
}

export type DataItem = {
  label: string;
  value: number;
  fill?: string;
  count?: number;
};

export type CurveItem = {
  label?: string;
  x: number;
  y: number;
  fill?: string;
  count?: number;
};

export type TTab = {
  key: TabKeys;
  text: string;
  position: number;
};

export type TGroup = {
  name: string;
  description: string;
  dashboards: number[];
  key: string;
};

export const groups: TGroup[] = [
  {
    name: 'Внутрішній звіт',
    description: 'Звіт за результатами оцінки рівня задоволеності суб’єктів звернення якістю надання адміністративних послуг у центрах надання  адміністративних послуг',
    dashboards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 32, 33, 34, 35, 36],
    key: 'internal',
  },
  {
    name: 'Динамічний звіт',
    description: 'Звіт за результатами оцінки рівня задоволеності суб’єктів звернення якістю надання адміністративних послуг у центрах надання  адміністративних послуг (динамічний)',
    dashboards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29],
    key: 'dynamic',
  },
  {
    name: 'Щоквартальний звіт',
    description: 'Звіт за результатами оцінки рівня задоволеності суб’єктів звернення якістю надання адміністративних послуг у центрах надання  адміністративних послуг (щоквартальний)',
    dashboards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29],
    key: 'quarterly',
  },
]

export const tabs: TTab[] = [
  {
    key: TabKeys.csi,
    text: "Показники рівня задоволеності суб'єктів звернення (CSI)",
    position: 1,
  },
  {
    key: TabKeys.locationDissatisfaction,
    text: 'Основні причини незадоволеності місцем надання послуг',
    position: 2,
  },
  {
    key: TabKeys.generalProcessDissatisfaction,
    text: 'Основні причини незадоволеності загальним процесом надання послуг',
    position: 3,
  },
  {
    key: TabKeys.staffDissatisfaction,
    text: 'Основні причини незадоволеності персоналом',
    position: 4,
  },
  {
    key: TabKeys.servicesList,
    text: 'Основні причини незадоволеності повнотою переліку послуг',
    position: 5,
  },
  {
    key: TabKeys.csiDetailed,
    text: "Статистика по показнику рівня задоволеності суб'єктів звернення з дезагрегацією оціночних даних (CSI)",
    position: 7,
  },
  {
    key: TabKeys.npsDetailed,
    text: 'Статистика по індексу лояльності суб’єктів звернення з дезагрегацією оціночних даних (NPS)',
    position: 8,
  },
  {
    key: TabKeys.npsCsiSatisfaction,
    text: 'Показники рівня задоволеності та індексу лояльності відповідно до категорій офлайн-фронт-офісу',
    position: 11,
  },
  {
    key: TabKeys.informationRespondents,
    text: 'Інформація про респондентів, які взяли участь в опитуванні',
    position: 6,
  },
  {
    key: TabKeys.csiRegionRatings,
    text: 'Рейтинг регіонів за результатами оцінки рівня задоволеності суб’єктів звернення',
    position: 9,
  },
  {
    key: TabKeys.ratingPopulationOne,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості населення (офлайн-фронт-офіс І категорії)',
    position: 12,
  },
  {
    key: TabKeys.ratingPopulationTwo,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості населення (офлайн-фронт-офіс ІІ категорії)',
    position: 13,
  },
  {
    key: TabKeys.ratingPopulationThree,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості населення (офлайн-фронт-офіс ІІІ категорії)',
    position: 14,
  },
  {
    key: TabKeys.ratingPopulationFour,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості населення (офлайн-фронт-офіс ІV категорії)',
    position: 15,
  },
  {
    key: TabKeys.ratingPopulationFive,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості населення (офлайн-фронт-офіс V категорії)',
    position: 16,
  },
  {
    key: TabKeys.ratingPopulationSix,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості населення (офлайн-фронт-офіс VI категорії)',
    position: 17,
  },
  {
    key: TabKeys.ratingReviewsOne,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості відгуків (офлайн-фронт-офіс І категорії)',
    position: 20,
  },
  {
    key: TabKeys.ratingReviewsTwo,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості відгуків (офлайн-фронт-офіс ІІ категорії)',
    position: 21,
  },
  {
    key: TabKeys.ratingReviewsThree,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості відгуків (офлайн-фронт-офіс ІІІ категорії)',
    position: 22,
  },
  {
    key: TabKeys.ratingReviewsFour,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості відгуків (офлайн-фронт-офіс ІV категорії)',
    position: 23,
  },
  {
    key: TabKeys.ratingReviewsFive,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості відгуків (офлайн-фронт-офіс V категорії)',
    position: 24,
  },
  {
    key: TabKeys.ratingReviewsSix,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості відгуків (офлайн-фронт-офіс VІ категорії)',
    position: 25,
  },
  {
    key: TabKeys.ratingVrmReviews,
    text: 'Рейтинг ВРМ відповідно до кількості відгуків',
    position: 26,
  },
  {
    key: TabKeys.complexRating,
    text: 'Рейтинг ВРМ за результатами оцінки рівня задоволеності суб’єктів звернення',
    position: 18,
  },
  {
    key: TabKeys.locationOutOfRating,
    text: 'ЦНАП, у яких показник рівня задоволеності суб’єктів звернення у звітному періоді недосягнутий',
    position: 19,
  },
  {
    key: TabKeys.locationWithoutReviews,
    text: 'ЦНАП, у яких відсутні відгуки',
    position: 28,
  },
  {
    key: TabKeys.complexGeneral,
    text: 'Результати оцінки рівня задоволеності суб’єктів звернення в ЦНАП',
    position: 27,
  },
  {
    key: TabKeys.qrPublishing,
    text: 'Стан розміщення QR-кодів з посиланням на опитування в ЦНАП',
    position: 34,
  },
  {
    key: TabKeys.qrPublishingPie,
    text: 'Аналітика розміщення QR-кодів з посиланням на опитування в ЦНАП',
    position: 35,
  },
  // {
  //   key: TabKeys.qrPublishingNodes,
  //   text: 'Стан розміщення QR-кодів з посиланням на опитування в ЦНАП в розрізі регіонів',
  //   position: 34,
  // },
  {
    key: TabKeys.reviewsByStatuses,
    text: 'Відгуки у розрізі статусів',
    position: 33,
  },
  {
    key: TabKeys.regionReviewsByStatuses,
    text: 'Стан опрацювання відгуків',
    position: 32,
  },
  {
    key: TabKeys.dynamic,
    text: 'Динаміка показників рівня задоволеності',
    position: 29,
  },
  {
    key: TabKeys.dataForDownload,
    text: 'Оціночні дані щодо рівня задоволеності суб’єктів звернення',
    position: 30,
  },
];
