import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { NpsDetailedDashboardStyles } from './styles';
import { useWindowSize } from 'usehooks-ts';
import { setSaveFilters, setSaveLink, useAppDispatch } from '../../../../state';
import { analyticsApi, ApiGroupedNps, fieldsApi, TPostGroupedNps } from '../../../../api';
import { DataItem } from '../../../../pages';
import { Loader } from '../../../atoms';
const colors: string[] = ['#CFC4FF', '#F2C3DB', '#A7C4E9', '#6EAE93', '#F5EF67', '#fe7878'];
type TNpsDetailedDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[] | boolean;
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
};
const respondentsDictionary: {
  [key: string]: string;
} = {
  promoters: 'Промоутери',
  passives: 'Нейтральні',
  detractors: 'Критики'
};
export const NpsDetailedDashboard: FC<TNpsDetailedDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions
}) => {
  const dispatch = useAppDispatch();
  const [npsData, setNpsData] = useState<ApiGroupedNps | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      sourceIDs,
      cnapTypes,
      isDiia,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostGroupedNps = {
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      sourceIDs: sourceIDs as number[],
      isDiia: isDiia as boolean,
      fieldIDs: [858818],
      positiveAnswers: ['9', '10', '9/10', '10/10'],
      negativeAnswers: ['0', '1', '2', '3', '4', '5', '6', '0/10', '1/10', '2/10', '3/10', '4/10', '5/10', '6/10'],
      groupsBy: [{
        fieldID: 858829
      }, {
        fieldID: 858830
      }, {
        fieldID: 858831
      }, {
        metaKey: 'created_by_type'
      }, {
        metaKey: 'asc_type'
      }]
    };
    setLoading(true);
    analyticsApi.getGroupedNps(+companyID, filteredData).then(res => {
      if (res.statusCode === 200) {
        setNpsData(res.data);
        setLoading(false);
      }
    });
    dispatch(setSaveLink('analytics/grouped/nps/file'));
    dispatch(setSaveFilters({
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean,
      sourceIDs: sourceIDs as number[],
      fieldIDs: [858818],
      positiveAnswers: ['9', '10', '9/10', '10/10'],
      negativeAnswers: ['0', '1', '2', '3', '4', '5', '6', '0/10', '1/10', '2/10', '3/10', '4/10', '5/10', '6/10'],
      groupsBy: [{
        fieldID: 858829
      }, {
        fieldID: 858830
      }, {
        fieldID: 858831
      }, {
        metaKey: 'created_by_type'
      }, {
        metaKey: 'asc_type'
      }]
    }));
  }, [reQuery]);
  if (loading) {
    return <NpsDetailedDashboardStyles>
        <Loader />
      </NpsDetailedDashboardStyles>;
  }
  return <NpsDetailedDashboardStyles>
      {npsData && <div className="charts">
          <div className="charts-item charts-one">
            <h4>NPS</h4>
            <div className="bars">
              <div className="bars-item">
                <div className="bars-fill">
                  <div style={{
                background: colors[0],
                height: `${Math.abs(npsData.total)}%`
              }} />
                  <span>{npsData.total.toFixed(1)}%</span>
                </div>
                <h6>Всі</h6>
              </div>
            </div>
          </div>
          <div className="charts-item charts-two">
            <h4>NPS стать</h4>
            <div className="bars">
              {npsData.groups[0].filter(e => e.groupName.length).map((el, index) => <div key={el.groupName} className="bars-item">
                    <div className="bars-fill">
                      <div style={{
                background: index % 2 ? colors[2] : colors[1],
                height: `${Math.abs(el.nps)}%`
              }} />
                      <span>{el.nps.toFixed(1)}%</span>
                    </div>
                    <h6 title={el.groupName}>{el.groupName}</h6>
                  </div>)}
            </div>
          </div>
          <div className="charts-item charts-three">
            <h4>NPS вікова категорія</h4>
            <div className="bars">
              {npsData.groups[1].filter(e => e.groupName.length).map((el, index) => <div key={el.groupName} className="bars-item">
                    <div className="bars-fill">
                      <div style={{
                background: colors[3],
                height: `${Math.abs(el.nps)}%`
              }} />
                      <span>{el.nps.toFixed(1)}%</span>
                    </div>
                    <h6 title={el.groupName}>{el.groupName}</h6>
                  </div>)}
            </div>
          </div>
          <div className="charts-item charts-four">
            <h4>NPS Статус суб`єкта звернення</h4>
            <div className="bars">
              {npsData.groups[2].filter(e => e.groupName.length).map((el, index) => <div key={el.groupName} className="bars-item">
                    <div className="bars-fill">
                      <div style={{
                background: colors[4],
                height: `${Math.abs(el.nps)}%`
              }} />
                      <span>{el.nps.toFixed(1)}%</span>
                    </div>
                    <h6 title={el.groupName}>{el.groupName}</h6>
                  </div>)}
            </div>
          </div>
          <div className="charts-item charts-five">
            <h4>Розподіл респондентів</h4>
            <div className="horizontal">
              {npsData.npsDistribution.map((el, index) => <div key={el.part} className="horizontal-item">
                    <h6 title={el.value}>{respondentsDictionary[el.value]}</h6>
                    <div className="horizontal-fill">
                      <span>{el.count}</span>
                      <div style={{
                background: colors[5],
                width: `${Math.abs(el.part)}%`
              }} />
                    </div>
                  </div>)}
            </div>
          </div>
          <div className="charts-item charts-six">
            <h4>NPS тип органу, що утворив центр</h4>
            <div className="horizontal">
              {npsData.groups[3].filter(e => e.groupName.length).map((el, index) => <div key={el.groupName} className="horizontal-item">
                    <h6 title={el.groupName}>{el.groupName}</h6>
                    <div className="horizontal-fill">
                      <span>{el.nps.toFixed(1)}%</span>
                      <div style={{
                background: colors[0],
                width: `${Math.abs(el.nps)}%`
              }} />
                    </div>
                  </div>)}
            </div>
          </div>
          <div className="charts-item charts-seven">
            <h4>NPS тип ЦНАП</h4>
            <div className="horizontal">
              {npsData.groups[4].filter(e => e.groupName.length).map((el, index) => <div key={el.groupName} className="horizontal-item">
                    <h6 title={el.groupName}>{el.groupName}</h6>
                    <div className="horizontal-fill">
                      <span>{el.nps.toFixed(1)}%</span>
                      <div style={{
                background: colors[2],
                width: `${Math.abs(el.nps)}%`
              }} />
                    </div>
                  </div>)}
            </div>
          </div>
        </div>}
    </NpsDetailedDashboardStyles>;
};