import styled from 'styled-components';

export const PieChartStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  svg {
    position: relative;
    z-index: 3;
  }

  .pie-legend {
    font-size: 12px;
    width: 100%;
  }

  .pie-legend-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }

  .legend-column {
    width: 50%;
  }

  .legend-item {
    font-size: 12px;
  }
`;
