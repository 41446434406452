import React, { FC } from 'react';
import { TIcon } from './TIcon';
export const LockIcon: FC<TIcon> = React.memo(({
  width = 28,
  height = 28,
  color = '#000'
}) => <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40 80C57.5261 80 67.2729 80 73.6365 73.6365C80 67.2729 80 57.5309 80 40C80 22.4691 80 12.7271 73.6365 6.36353C67.2729 0 57.5309 0 40 0C22.4691 0 12.7271 0 6.36353 6.36353C0 12.7271 0 22.4739 0 40C0 57.5261 0 67.2729 6.36353 73.6365C12.7271 80 22.4691 80 40 80Z" fill="#0C0005" />
      <rect x="22" y="30" width="36" height="36" rx="5" fill="white" />
      <path d="M50 30.5V23C50 17.4772 45.5228 13 40 13V13C34.4772 13 30 17.4772 30 23V47H44.2308" stroke="white" strokeWidth="5" />
      <circle cx="40" cy="43" r="5" fill="#0C0005" />
      <path d="M38 46H42L44 58H36L38 46Z" fill="#0C0005" />
    </svg>);