import styled from 'styled-components';

export const ComplexRatingDashboardStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex: 1;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;

  @media screen and (max-width: 1440px) {
    gap: 10px;
  }

  .rating {
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box !important;

    &-col {
      box-sizing: border-box;
      font-size: 14px;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 1440px) {
        padding: 6px 10px;
        font-size: 12px;
      }
    }

    &-item {
      font-size: 14px;

      @media screen and (max-width: 1440px) {
        font-size: 12px;
      }
    }
  }
`;
