import * as Yup from 'yup';
import { getTranslationByLangOrEng } from '../../i18n';
import { authApi } from '../../api';
export interface FormValues {
  position: string;
  drfoCode: string;
  name: string;
  email: string;
  roles: number[];
  phone: string;
  passwords?: {
    password?: string;
    passwordConfirmation?: string;
  };
}
export const formInitialValues: FormValues = {
  position: '',
  drfoCode: '',
  name: '',
  email: '',
  phone: '',
  roles: [],
  passwords: {
    password: '',
    passwordConfirmation: ''
  }
};
export const validationNewUserSchema = (lang: string, value: number) => Yup.object({
  name: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  email: Yup.string().trim().required(getTranslationByLangOrEng(lang, 'validation_required')),
  roles: Yup.array().min(value, getTranslationByLangOrEng(lang, 'validation_required')),
  passwords: Yup.object().shape({
    password: Yup.string(),
    passwordConfirmation: Yup.string()
  }).test('check-password-equals', 'Passwords not equal', passwords => passwords.password === passwords.passwordConfirmation),
  drfoCode: Yup.string().trim().required('Введіть ІНН').matches(/^([0-9]{9,10}|[АБВГДЕЄЖЗИІКЛМНОПРСТУФХЦЧШЩЮЯ]{2}[0-9]{6})$/, 'ІНН має починатись з двох кириличних символів та шести цифр')
});
export const validationUserSchema = (lang: string, value: number) => Yup.object({
  email: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  roles: Yup.array().min(value, getTranslationByLangOrEng(lang, 'validation_required')),
  phone: Yup.string().required('Вкажіть номер телефону') // E.164 standard regex pattern
  .matches(/^\+[1-9]\d{10,14}$/, 'Неправильний номер телефону')
});
export async function updateRolesList(rolesToAttach: number[], rolesToDetach: number[], userId: number) {
  if (rolesToAttach.length) {
    await authApi.attachRolesToUser(userId, rolesToAttach);
  }
  if (rolesToDetach.length) {
    await authApi.detachRolesFromUser(userId, rolesToDetach);
  }
}