import { RolesStyles } from './styles';
import { getTranslationByLangOrEng } from '../../i18n';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { rolesApi } from '../../api';
import { IUserRole } from '../../entities';
import { useAppSelector } from '../../state';
import { Helmet } from 'react-helmet-async';
import { SubHeader, Table, TransparentButton } from '../../components';
import { EditButtons, NameCustomField } from './utils';
export const Roles = () => {
  const navigate = useNavigate();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  const [uploading, setUploading] = useState<boolean>(false);
  const [reQuery, setReQuery] = useState<boolean>(false);
  const [roles, setRoles] = useState<IUserRole[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const rolesCount = useRef<number>(0);
  useEffect(() => {
    if (!uploading) {
      setUploading(true);
      rolesApi.getRoles((pageNumber ? +pageNumber - 1 : 0) * 10, 10).then(res => {
        if (res.statusCode === 200) {
          setRoles(prev => [...prev, ...res.data]);
          rolesCount.current = res.count ? res.count : 0;
          setUploading(false);
        }
      });
    }
  }, [pageNumber]);
  const filterItemsAfterDelete = useCallback((id: number) => {
    setRoles(prev => prev.filter(user => user.id !== id));
  }, [reQuery]);
  useEffect(() => {
    setTableHeaders([{
      Header: getTranslationByLangOrEng(interfaceLanguage, 'roles_main_table_user_role_header'),
      accessor: 'role',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <NameCustomField name={data.row.original.name} textColor="#6c6c6c" />
    }, {
      Header: 'Edit buttons',
      accessor: 'editButtons',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <EditButtons reQuery={reQuery} setReQuery={setReQuery} id={data.row.original.id} filterItemsAfterDelete={filterItemsAfterDelete} />
    }]);
  }, [roles]);
  const onClickToRow = async (data: any) => {
    navigate(`/users/roles/${data.id}`);
  };
  return <RolesStyles>
      <Helmet>
        <title>Ролі</title>
      </Helmet>

      <SubHeader title={getTranslationByLangOrEng(interfaceLanguage, 'roles_main_title')}>
        <div className="buttonsContainer">
          <TransparentButton handleClick={() => navigate('/users/permissions')} text={getTranslationByLangOrEng(interfaceLanguage, 'roles_main_permissions_button')} filled />
          <TransparentButton handleClick={() => navigate('/users/roles/create')} text={getTranslationByLangOrEng(interfaceLanguage, 'roles_main_add_role_button')} filled />
          <TransparentButton handleClick={() => navigate('/users')} text={getTranslationByLangOrEng(interfaceLanguage, 'go_back')} />
        </div>
      </SubHeader>

      {tableHeaders && <Table columns={tableHeaders} data={roles} hideFieldsSplitters headerColumnCounts={{
      role: rolesCount.current
    }} hiddenHeaders={['editButtons']} fullWidthColumns={['editButtons']} sortableColumns={['role']} onClickToRow={onClickToRow} totalCount={rolesCount.current} currentPage={pageNumber} offset={10} loading={uploading} pageNumberHandler={value => setPageNumber(value)} fixedHeader />}
    </RolesStyles>;
};