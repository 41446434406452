import styled, { css } from 'styled-components';

export const NameCustomFieldStyles = styled.div`
    &.onClick {
        cursor: pointer;
        
        &:hover, &:focus {
            text-decoration: underline;
        }
    }
`;
