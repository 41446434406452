/* eslint-disable class-methods-use-this */
import ICampaignApi from './ICampaignApi';
import { ApiResponse } from '../entities';
import baseApi from '../baseApi';
import { ApiCampaign, ApiCampaignFilters } from '../entities/ApiCampaigns';

class CampaignApi implements ICampaignApi {
  public async getCampaignDataByCompany(
    campaignId: number,
    filters: {},
    offset?: number,
    limit?: number,
    full?: boolean,
  ): Promise<ApiResponse<ApiCampaign[]>> {
    const { statusCode, data: response } = await baseApi.postData(
      `/clients-campaign/${campaignId}?limit=${limit}&offset=${offset}${
        full ? '&full=true' : ''
      }`,
      { ...filters },
    );
    return Promise.resolve({
      data: response.data,
      statusCode,
      count: response.count,
    });
  }

  public async getCampaignFilters(
    campaignId: number,
  ): Promise<ApiResponse<ApiCampaignFilters>> {
    const { statusCode, data: response } = await baseApi.getData(
      `/clients-campaign/${campaignId}/filters`,
    );
    return Promise.resolve({
      data: response,
      statusCode,
    });
  }
}

export const campaignApi = new CampaignApi();
