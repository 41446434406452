import React, { FC } from 'react';
import { TIcon } from './TIcon';
export const AnalyticFilterIcon: FC<TIcon> = React.memo(({
  width = 20,
  height = 20,
  color = '#768E9D',
  bgColor = '#D7DFE4'
}) => <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} fill={bgColor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M12 9.75V17L8 14V9.75L2 3H18L12 9.75Z" fill={color} />
    </svg>);