import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
// src/components/QRCodePreview.tsx
import React, { useEffect, useRef, useState } from 'react';
import { ApiQRStyles } from '../../../api';
import { Loader } from '../loader';
import { companyPostersApi } from '../../../api/companyPosters/CompanyPostersApi';
export const minQRCodeSize = 50;
interface QRCodePreviewProps {
  width?: number;
  height?: number;
  values: ApiQRStyles;
}
const QRCodePreview_1 = _compiledBlock(_props => _props.v0, {
  name: "QRCodePreview_1",
  portals: ["v0"]
});
const QRCodePreview_2 = _compiledBlock(_props2 => <img style={_props2.v0} width={_props2.v1} height={_props2.v2} src={_props2.v3} alt="QR код" />, {
  name: "QRCodePreview_2"
});
const QRCodePreview: React.FC<QRCodePreviewProps> = ({
  values,
  width = 300,
  height = 300
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [qrCode, setQRCode] = useState<File>();
  const [qrCodeURL, setQRCodeURl] = useState<string>();
  const timeoutRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    const generateQR = () => {
      setIsLoading(true);
      companyPostersApi.generateQR({
        styles: values,
        url: 'https://www.voicerhub.com/'
      }).then(response => {
        if (response.statusCode < 200 && response.statusCode > 300) {
          throw new Error('Виникла помилка при генерації QR коду');
        }
        return response.data;
      }).then(blob => {
        const file = new File([blob], 'qrcode.png', {
          type: 'image/png'
        });
        setQRCode(file);
      }).catch(() => {
        console.error('Виникла помилка при генерації QR коду');
      }).finally(() => {
        setIsLoading(false);
      });
    };
    timeoutRef.current = setTimeout(() => {
      generateQR();
    }, 500);
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [values]);
  useEffect(() => {
    if (qrCode) {
      if (qrCodeURL) {
        URL.revokeObjectURL(qrCodeURL);
      }
      setQRCodeURl(URL.createObjectURL(qrCode));
    }
  }, [qrCode]);
  return isLoading || !qrCodeURL ? /*@million jsx-skip*/<QRCodePreview_1 v0={<Loader />} /> : /*@million jsx-skip*/<QRCodePreview_2 v0={{
    pointerEvents: 'none'
  }} v1={width || 300} v2={height || 300} v3={qrCodeURL} />;
};
export default React.memo(QRCodePreview);