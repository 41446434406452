import React, { useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { LayoutStyles } from './styles';
import { useWindowSize } from 'usehooks-ts';
import { setCompanies, setSidebarFullWidth, useAppDispatch, useAppSelector } from '../../../state';
import { companyApi } from '../../../api';
import { Header } from '../header';
import { Footer } from '../footer';
import { Sidebar } from '../../molecules';
import { RegistrationStatus } from '../../../constants/registrationStatuses';
export const Layout: React.FC = React.memo(() => {
  const dispatch = useAppDispatch();
  const {
    pathname
  } = useLocation();
  const {
    grayMode,
    fontScale
  } = useAppSelector(state => state.interface);
  const {
    width
  } = useWindowSize();
  const {
    data
  } = useAppSelector(state => state.user);
  const loggedIn = data?.status === RegistrationStatus.assigned;
  useEffect(() => {
    if (loggedIn) {
      companyApi.getCompanies().then(res => {
        const companies = res.data.map(el => el.id);
        dispatch(setCompanies(companies));
      });
    }
  }, [loggedIn]);
  useEffect(() => {
    if (pathname.startsWith('/analytic') || pathname.includes('/reviews')) {
      dispatch(setSidebarFullWidth(false));
    } else {
      dispatch(setSidebarFullWidth(width > 991));
    }
  }, [width]);
  return <LayoutStyles grayMode={grayMode} fontScale={fontScale}>
      <Header />
      <main>
        <div className="mainBody" style={pathname.startsWith('/auth') ? {
        gridTemplateColumns: '1fr'
      } : {}}>
          {loggedIn && <Sidebar />}
          <div className="contentWrapper">
            <Outlet />
          </div>
        </div>
      </main>
      {!loggedIn && <Footer />}
    </LayoutStyles>;
});