import React, { FC, useEffect } from 'react';
import { LinePath } from '@visx/shape';
import { scaleLinear } from '@visx/scale';
import { curveLinear } from '@visx/curve';
import { CurveItem } from '../../../../pages';
type TCurvedChart = {
  width: number;
  height: number;
  data: CurveItem[];
  hasNegativeValues?: boolean;
};
export const CurvedChart: FC<TCurvedChart> = ({
  width,
  height,
  data,
  hasNegativeValues
}) => {
  const margin = {
    top: 20,
    right: 30,
    bottom: 40,
    left: 30
  };
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const xScale = scaleLinear({
    domain: [0, Math.max(...data.map(d => d.x))],
    range: [margin.left, xMax]
  });
  const yDomain = hasNegativeValues ? [Math.min(...data.map(d => d.y)), Math.max(...data.map(d => d.y))] : [0, Math.max(...data.map(d => d.y))];
  const yScale = scaleLinear({
    domain: yDomain,
    range: [yMax, margin.top]
  });
  const adjustTextPosition = (x: number, y: number, textWidth: number, textHeight: number) => {
    const adjustedX = Math.max(margin.left + textWidth, Math.min(x, xMax - textWidth / 2));
    const adjustedY = Math.max(margin.top + textHeight, Math.min(y, yMax - 5));
    return {
      adjustedX,
      adjustedY
    };
  };
  return <svg width={width} height={height}>
      <LinePath data={data} x={d => xScale(d.x)} y={d => yScale(d.y)} curve={curveLinear} stroke="#A7C5E9" strokeWidth={5} strokeLinecap="round" />
      {data.map((d, i) => {
      const cx = xScale(d.x);
      const cy = yScale(d.y);
      const {
        adjustedX,
        adjustedY
      } = adjustTextPosition(cx, cy - 10, 30, 15);
      return <g key={`point-${i}`}>
            <circle cx={cx} cy={cy} r={8} fill="red" />
            <text x={adjustedX} y={adjustedY} fontSize={12} fill="black" textAnchor="middle">
              {`(${d.label}, ${d.y})`}
            </text>
          </g>;
    })}
    </svg>;
};