import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CompanySettingsStyles } from './CompanySettingsStyles';
import { ChangingStatuses } from './changingStatuses';
import { AnalyticsParams } from './analyticsParams';
import { EnabledSettings } from './enabledSettings';
import { ApiSettings, companySettingsApi, formApi } from '../../../api';
import { ChatCloseStatuses } from './chatCloseStatuses';
import { NotifySettings } from './notifySettings';
import { DepthNotificationsResponsible } from './depthNotificationsResponsible';
import { ClientStatuses } from './clientStatuses';
import { ClientStatusesEmptyChat } from './clientStatusesEmptyChat';
import { ClientStatusesEmptyIssues } from './clientStatusesEmptyIssues';
import { QRControlForms } from './qrControlForms/QRControlForms';
export const CompanySettings = React.memo(() => {
  const {
    id
  } = useParams();
  const [settings, setSettings] = useState<ApiSettings>({} as ApiSettings);
  useEffect(() => {
    if (id) {
      companySettingsApi.getCompanySettings(+id).then(res => {
        setSettings(res.data);
      });
    }
  }, []);
  return <CompanySettingsStyles>
      {settings['client-status-changes'] && <ChangingStatuses apiSourcesSettings={settings['client-status-changes']} />}
      <EnabledSettings apiEnabled={settings['sms-enabled']} settings="sms-enabled" label="Відправка SMS повідомлень" />
      <EnabledSettings apiEnabled={settings['categories-enabled']} settings="categories-enabled" label="Підключення категорій" />
      <EnabledSettings apiEnabled={settings['tickets-enabled']} settings="tickets-enabled" label="Підключення делегування" />
      <EnabledSettings apiEnabled={settings['auto-sentiment-analysis-enabled']} settings="auto-sentiment-analysis-enabled" label="Підключення сентимент аналізу" />
      <div className="horizontalLine" />
      <div className="row">
        <ChatCloseStatuses apiChatCloseStatuses={settings['chat-close-statuses']} />
        <ClientStatusesEmptyChat apiClientStatusesEmptyChat={settings['client-statuses-when-chat-is-empty'] || []} />
        <ClientStatusesEmptyIssues apiClientStatusesEmptyIssues={settings['client-statuses-when-issues-is-empty'] || []} />
      </div>
      <div className="horizontalLine" />
      <QRControlForms initialQrContolForm={settings['qr-confirmation-form']?.[0]} />
      <div className="horizontalLine" />
      <NotifySettings apiStart={settings['notify-time-begin']} apiEnd={settings['notify-time-end']} apiDays={settings['notify-days']} />
      <DepthNotificationsResponsible apiNotifyOverdueEnable={settings['notify-overdue-enabled']} apiNotifyRemindEnable={settings['notify-remind-enabled']} apiNotifyOverdueLevels={settings['notify-overdue-levels']} apiNotifyRemindLevels={settings['notify-remind-levels']} />
      <ClientStatuses apiStatuses={settings['client-statuses']} />
    </CompanySettingsStyles>;
});