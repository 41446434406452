import { useState } from 'react';
import { ImpexStyles } from './ImpexStyles';
import { useAppSelector } from '../../state';
import { Helmet } from 'react-helmet-async';
import { getTranslationByLangOrEng } from '../../i18n';
import { Export, SubHeader, Import, CustomTabsMenu } from '../../components';
import { tabs, TabKeys } from './TImpex';
export const Impex = () => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [selectedTab, setSelectedTab] = useState(TabKeys.import);
  return <ImpexStyles>
      <Helmet>
        <title>Імпорт\експорт</title>
      </Helmet>
      <SubHeader title={getTranslationByLangOrEng(interfaceLanguage, 'impex_page_title')} hideBorderBottom />
      <CustomTabsMenu tabs={tabs(interfaceLanguage)} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <div className="contentWrapper">
        {selectedTab === TabKeys.import && <Import />}
        {selectedTab === TabKeys.export && <Export />}
      </div>
    </ImpexStyles>;
};