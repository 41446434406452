export interface FormValues {
  companyType: any | undefined;
  position: any | undefined;
  type: any | undefined;
  organisation: any | undefined;
  nodes: any[];
  position_string: string | undefined;
  0: any | undefined;
  1: any | undefined;
  2: any | undefined;
  3: any | undefined;
  4: any | undefined;
}

export const regionTypes = {
  0: 'Країна',
  1: 'Оберіть регіон',
  2: 'Оберіть район',
  3: 'Оберіть орган, що утворив центр',
  4: 'Оберіть фронт-офіс',
};
