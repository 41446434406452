import styled from 'styled-components';
export const StatusesCounterStyles = styled.div`
  .wrapper {
    overflow: auto;
    display: flex;
    gap: 8px;
    width: 100%;

    .status {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 12px;
      gap: 8px;
      height: 92px;
      background: #ffffff;
      border-radius: 12px;

      @media screen and (max-width: 1440px) {
        height: 64px;
      }

      .statusHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .statusCount {
        margin-right: 12px;
        font-weight: 400;
        font-size: 20px;
      }

      svg {
        flex-shrink: 0;
      }

      .statusContent {
        color: #a1a1a1;

        @media screen and (max-width: 1200px) {
          display: none;
        }
      }

      .statusName {
        font-size: 12px;
        color: #000;
      }
    }
  }
`;