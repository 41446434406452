import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { multiTenancyApi, companyApi } from '../../../api';
import { CompaniesMainInfo } from '../../../entities';
import { CompanySelect, MultiNodeSelect } from '../../molecules';
import { MultiTenancyStyles } from './MultiTenancyStyles';
type TMultiTenancy = {
  id: number;
  reQuery: boolean;
  setReQuery: Dispatch<SetStateAction<boolean>>;
};
export const MultiTenancy: FC<TMultiTenancy> = React.memo(({
  id,
  reQuery,
  setReQuery
}) => {
  const [attachedCompanies, setAttachedCompanies] = useState<number[]>([]);
  const companies = useRef<CompaniesMainInfo[]>([]);
  useEffect(() => {
    companyApi.getCompanies().then(responce => {
      companies.current = responce.data;
      multiTenancyApi.getMultiTenancy(id).then(res => {
        setAttachedCompanies(res.data.structures.map(structure => structure.companyID));
      });
    });
  }, [reQuery, id]);
  return <MultiTenancyStyles>
        <CompanySelect setReQuery={setReQuery} reQuery={reQuery} id={id} placeholder="Обрати субʼєкт" companies={companies.current} attachedCompanies={attachedCompanies} label="Прикріпити субʼєкт моніторингу" />
        <div className="nodesSelects">
          {attachedCompanies.map(nodes => <MultiNodeSelect companyID={nodes} companies={companies.current} placeholder="Прикріпити фронт-офіс" selectKey="name" key={nodes} name={nodes.toString()} search id={id} />)}
        </div>
      </MultiTenancyStyles>;
});