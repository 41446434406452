import styled from 'styled-components';

export const ExpandableStyles = styled.div`
  .expandable {
    overflow: visible;
    display: flex;
  }

  .expandable-content {
    max-height: 0px;
    opacity: 0;
    transition: all 0.3s ease-out;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 8px;

    &.open {
      transition: all 0.3s ease-in;
      max-height: 300px;
      opacity: 1;
    }
  }
`;
