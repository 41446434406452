import { FC } from 'react';
import { chartColors } from '../../../constants';
import { LinkIcon } from '../../../assets/icons/LinkIcon';
type TCustomEditLinks = {
  name: string;
  index: number;
  link?: string;
};
export const CustomNameField: FC<TCustomEditLinks> = ({
  name,
  index,
  link
}) => {
  let color: string;
  if (index + 1 > chartColors.length) {
    const moreTimes = Math.floor((index + 1) / chartColors.length);
    color = chartColors[index + 1 - chartColors.length * moreTimes];
  }
  return <a target="_blank" rel="noreferrer" href={link} className="nameWrapper" style={{
    cursor: link ? 'pointer' : 'default'
  }}>
      {link ? <>
          <p>{name}</p>
          <div>
            <LinkIcon color="#000" width={15} height={15} />
          </div>
        </> : <span>{name}</span>}
    </a>;
};