import styled from 'styled-components';

export const DynamicDashboardStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* overflow: hidden; */

  .charts {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;
    }
  }
`;
