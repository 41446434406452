import { toast } from 'react-toastify';
import { store, changeUser } from '../../state';

const handleToastClose = () => {
  store.dispatch(changeUser(null));
  const cookies: string[] = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie: string = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
  localStorage.removeItem('access_token');
  localStorage.removeItem('access_token_expires_at');
  localStorage.removeItem('access_token_end_date');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('refresh_token_expires_at');
  sessionStorage.clear();
};

class BaseApi {
  private baseUrl: string = process.env.REACT_APP_API_HOST!;

  private isRefreshingToken = false;

  private tokenRefreshedResolve: (() => void)[] = [];

  private async waitForTokenRefresh(): Promise<void> {
    if (!this.isRefreshingToken) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      this.tokenRefreshedResolve.push(resolve);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private async refreshAccessToken(): Promise<string | null> {
    console.log('refreshing token');
    if (this.isRefreshingToken) {
      await this.waitForTokenRefresh();
      return localStorage.getItem('access_token') || null;
    }
    this.isRefreshingToken = true;

    try {
      const refreshToken = localStorage.getItem('refresh_token');
      const response = await fetch(`${this.baseUrl}/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          refreshToken,
        }),
      });

      const responseObj = await response.json();

      if (response.status !== 200 && !toast.isActive('not_authorized')) {
        toast.error('Not Authorized', {
          toastId: 'not_authorized',
          onClose: () => handleToastClose(),
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return null;
      }

      localStorage.setItem('access_token', responseObj.accessToken);
      localStorage.setItem(
        'access_token_expires_at',
        responseObj.accessTokenExpiresAt,
      );
      localStorage.setItem('refresh_token', responseObj.refreshToken);
      localStorage.setItem(
        'access_token_end_date',
        responseObj.accessTokenExpiresAt,
      );
      localStorage.setItem(
        'refresh_token_expires_at',
        responseObj.refreshTokenExpiresAt,
      );

      this.isRefreshingToken = false;
      this.tokenRefreshedResolve.forEach((resolve) => resolve());
      this.tokenRefreshedResolve = [];

      return responseObj.accessToken!;
    } catch (error) {
      this.isRefreshingToken = false;
      this.tokenRefreshedResolve.forEach((resolve) => resolve());
      this.tokenRefreshedResolve = [];

      console.error('Error refreshing token:', error);
      return null;
    }
  }

  private defaultErrors: { [key: number]: string } = {
    405: 'Method not allowed',
    429: 'Too Many Requests',
    500: 'Server error',
  };

  async fetchData(
    path: string,
    requestOptions: any,
    isBlob?: boolean,
  ): Promise<any> {
    try {
      const expiredAccessDate = localStorage.getItem('access_token_end_date');

      if (expiredAccessDate) {
        const targetDate = new Date();
        const expiredDate = new Date(expiredAccessDate);

        if (targetDate.getTime() > expiredDate.getTime()) {
          const newToken = await this.refreshAccessToken();
          requestOptions.headers.Authorization = `Bearer ${newToken}`;
        }
      }

      const response = await fetch(`${this.baseUrl}${path}`, {
        ...requestOptions,
      });
      const statusCode = response.status;

      const data = isBlob ? await response.blob() : await response.json();

      if (statusCode === 401 && !toast.isActive('not_authorized')) {
        toast.error('Not Authorized', {
          toastId: 'not_authorized',
          onClose: () => handleToastClose(),
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      if (Object.keys(this.defaultErrors).includes(`${statusCode}`)) {
        toast.error(this.defaultErrors[statusCode]);
      }

      return {
        data, // {data: {}, count: 0}
        statusCode,
      };
    } catch (e) {
      throw new Error(`API Fetch error: ${e}`);
    }
  }

  async getData(
    path: string,
    isBlob?: boolean,
    // eslint-disable-next-line default-param-last
    tokenRequired = true,
    signal?: AbortSignal,
  ): Promise<any> {
    const myHeaders: { [key: string]: string } = {};

    if (tokenRequired) {
      const accessToken = localStorage.getItem('access_token');
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions: {
      method: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;
      headers: { [key: string]: string };
      signal: AbortSignal | undefined;
    } = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
      signal,
    };
    return this.fetchData(path, requestOptions, isBlob);
  }

  async postData(
    path: string,
    data?: any,
    isBlob?: boolean,
    formData?: any,
  ): Promise<any> {
    const accessToken = localStorage.getItem('access_token');
    const myHeaders: { [key: string]: string } = {};
    if (!formData) {
      myHeaders['Content-Type'] = 'application/json';
    }

    if (accessToken) {
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions: {
      method: string;
      headers: { [key: string]: string };
      body: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;

      mode: string;
      cache: string;
      credentials: string;
      referrerPolicy: string;
    } = {
      method: 'POST',
      headers: myHeaders,
      body: formData ? data : JSON.stringify(data),
      redirect: 'follow',

      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      referrerPolicy: 'no-referrer', // no-referrer, *client
    };

    return this.fetchData(path, requestOptions, isBlob);
  }

  async putData(path: string, data: any, formData?: boolean) {
    const accessToken = localStorage.getItem('access_token');
    const myHeaders: { [key: string]: string } = {};
    if (!formData) {
      myHeaders['Content-Type'] = 'application/json';
    }

    if (accessToken) {
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions: {
      method: string;
      headers: { [key: string]: string };
      body: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;

      mode: string;
      cache: string;
      credentials: string;
      referrerPolicy: string;
    } = {
      method: 'PUT',
      headers: myHeaders,
      body: formData ? data : JSON.stringify(data),
      redirect: 'follow',

      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      referrerPolicy: 'no-referrer', // no-referrer, *client
    };

    return this.fetchData(path, requestOptions);
  }

  async patchData(path: string, data: any) {
    const myHeaders: { [key: string]: string } = {};

    const requestOptions: {
      method: string;
      headers: { [key: string]: string };
      body: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;

      mode: string;
      cache: string;
      credentials: string;
      referrerPolicy: string;
    } = {
      method: 'PATCH',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow',

      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      referrerPolicy: 'no-referrer', // no-referrer, *client
    };

    return this.fetchData(path, requestOptions);
  }

  async deleteData(path: string, data?: any): Promise<any> {
    const accessToken = localStorage.getItem('access_token');
    const myHeaders: { [key: string]: string } = {};

    if (accessToken) {
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions: {
      method: string;
      headers: { [key: string]: string };
      body: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;

      mode: string;
      cache: string;
      credentials: string;
      referrerPolicy: string;
    } = {
      method: 'DELETE',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow',

      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      referrerPolicy: 'no-referrer', // no-referrer, *client
    };

    return this.fetchData(path, requestOptions);
  }
}

const baseApi = new BaseApi();

export default baseApi;
