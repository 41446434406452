import React, { CSSProperties } from 'react';
import { TransparentButtonStyles } from './TransparentButtonStyles';
import { Link } from 'react-router-dom';
import { Loader } from '../loader';
import { TransparentButtonProps } from './types';
export const TransparentButton: React.FC<TransparentButtonProps> = ({
  text,
  handleClick,
  filled,
  childrenPosition = 'right',
  extraStyles,
  children,
  disabled,
  isLink,
  linkAddress,
  extraButtonTextStyles,
  extraContainerStyles,
  targetIsBlank,
  submit,
  form,
  filledColor,
  isLoading,
  className
}) => <TransparentButtonStyles filledColor={filledColor} disabled={!!disabled} style={extraContainerStyles}>
    {isLink && linkAddress ? <Link to={linkAddress} className={filled ? 'filled' : ''} onClick={disabled || !handleClick ? () => null : () => handleClick()} style={extraStyles || {}} target={targetIsBlank ? '_blank' : undefined} aria-label={text}>
        <div className="linkContentWrapper">
          {childrenPosition === 'left' && children}
          <span>{text}</span>
          {childrenPosition === 'right' && children}
        </div>
      </Link> : <button className={filled ? `filled ${className || ''}` : `${className || ''}`} type={submit ? 'submit' : 'button'} onClick={disabled || !handleClick ? () => null : () => handleClick()} style={extraStyles || {}} disabled={disabled} form={form} aria-label={text}>
        {childrenPosition === 'left' && children}
        <span style={extraButtonTextStyles || {}}>{text}</span>
        {isLoading !== undefined && <div className="statusWrapper">
            {isLoading && <Loader height={20} color="#FFF" margin={0} />}
          </div>}
      </button>}
  </TransparentButtonStyles>;