import styled from 'styled-components';

export const ReviewsByStatusesDashboardStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex: 1;
  width: 100%;
  overflow: auto;
  align-items: center;
  box-sizing: border-box;

  @media screen and (max-width: 1440px) {
    gap: 10px;
  }

  .legend {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;

    &-item {
      display: flex;
      align-items: center;
      gap: 8px;

      span {
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
  }

  .reviews {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    h2 {
      font-weight: 400;
      font-size: 35px;
      line-height: 115%;
      text-align: center;
      color: #000000;

      @media screen and (max-width: 1440px) {
        font-size: 26px;
      }
    }
  }

  .chart-legend {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    flex-wrap: wrap;

    &-item {
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 115%;
        color: #000000;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      div {
        border-radius: 50%;
        width: 14px;
        height: 14px;
        flex-shrink: 0;
      }
    }
  }

  .dashboard {
    width: 100%;
    flex: 1;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    gap: 24px;
    grid-template-areas:
      'one one two'
      'one one three'
      'one one three'
      'one one four'
      'one one four';

    @media screen and (max-width: 1440px) {
      gap: 8px;
    }

    &-item {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 24px;
      gap: 8px;
      border: 1px solid #dee6eb;
      border-radius: 10px;

      @media screen and (max-width: 1440px) {
        padding: 12px;
      }

      h4 {
        font-weight: 400;
        font-size: 18px;
        line-height: 115%;
        text-align: center;
        color: #000000;
        padding-bottom: 10px;
        border-bottom: 1px solid #dee6eb;

        @media screen and (max-width: 1440px) {
          font-size: 10px;
          padding-bottom: 5px;
        }
      }
    }

    &-one {
      grid-area: one;
      gap: 0;
    }

    &-two {
      grid-area: two;
    }

    &-three {
      grid-area: three;
    }
  }

  .pies {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
  }

  .statuses {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &-item {
      width: 100%;
      padding: 8px 0;
      display: flex;
      align-items: center;
      gap: 16px;

      h5 {
        width: 100%;
        max-width: 190px;
        font-weight: 400;
        font-size: 12px;
        line-height: 115%;
        text-align: right;
        color: #000000;
      }
    }

    &-all {
      border-bottom: 1px solid #dee6eb;
    }

    &-chart {
      flex: 1;
      display: flex;
    }

    &-segment {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
      min-width: 26px !important;

      background: #a7c4e9;
      border-radius: 1000px;

      font-weight: 700;
      font-size: 8px;
      line-height: 100%;
      text-align: center;
      color: #000000;
    }
  }
`;
