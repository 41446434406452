import styled from 'styled-components';
export const ClientStatusesEmptyChatStyles = styled.div`
  width: 100%;

  .selectField {
    max-width: 450px;
  }

  .loader {
    width: 100%;
    position: absolute;
    top: 0px;
  }
`;