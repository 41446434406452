/* eslint-disable class-methods-use-this */
import baseApi from '../baseApi';
import {
  ApiComplexRating,
  ApiCsi,
  ApiPopulationRating,
  ApiResponse,
  ApiReviewsRating,
  TPostDashboardData,
  TPostComplexRating,
  TPostPopulationRating,
  TPostReviewRating,
  ApiAnalyticFilter,
  ApiPostAnalyticFilter,
  TPostLocationsOutOfRating,
  ApiLocationsCountsData,
  TPostLocationWithoutReviews,
  TPostDissatisfaction,
  ApiDissatisfactions,
  ApiDissatisfactionsPie,
  TPostComplexGeneral,
  ApiComplexGeneral,
  TPostQRPublishing,
  ApiQRPublishing,
  TPostGroupedCsi,
  ApiGroupedCsi,
  ApiQRPublishingPie,
  ApiCsiRegionRating,
  TPostCsi,
  ApiQRPublishingNodes,
  ApiReviewsByStatuses,
  TPostReviewsByStatuses,
  ApiRegionReviewsByStatuses,
  TPostDynamic,
  ApiDynamic,
  TPostGroupedNps,
  ApiGroupedNps,
  TPostInformationRespondents,
  ApiInformationRespondents,
  TPostDataForDownload,
  ApiDataForDownload,
} from '../entities';
import IAnalyticsApi from './IAnalyticsApi';

class AnalyticsApi implements IAnalyticsApi {
  public async getCsiDashboardData(companyID: number, data: TPostCsi): Promise<ApiResponse<ApiCsi[]>> {
    return baseApi.postData(`/companies/${companyID}/analytics/csi`, data);
  }

  public async getCsiRegionRatings(companyID: number, data: TPostDashboardData): Promise<ApiResponse<ApiCsiRegionRating>> {
    return baseApi.postData(`/companies/${companyID}/analytics/regions-csi-ratings`, data);
  }

  public async analyticsFilters(companyID: number, data: ApiPostAnalyticFilter): Promise<ApiResponse<ApiAnalyticFilter>> {
    return baseApi.postData(`/companies/${companyID}/analytics/filter`, data);
  }

  public async getPopulationRatingData(companyID: number, data: TPostPopulationRating, offset?: number, limit?: number): Promise<ApiResponse<ApiPopulationRating[]>> {
    const res = await baseApi.postData(`/companies/${companyID}/analytics/rating/csi-asc-by-category?offset=${offset || 0}&limit=${limit || 10}`, data);

    return Promise.resolve({
      data: res.data.data,
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async getReviewsRatingData(companyID: number, data: TPostReviewRating, offset?: number, limit?: number): Promise<ApiResponse<ApiReviewsRating[]>> {
    const res = await baseApi.postData(`/companies/${companyID}/analytics/rating/reviews-asc-by-category?offset=${offset || 0}&limit=${limit || 10}`, data);

    return Promise.resolve({
      data: res.data.data,
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async getComplexRatingData(companyID: number, data: TPostComplexRating, offset?: number, limit?: number): Promise<ApiResponse<ApiComplexRating[]>> {
    const res = await baseApi.postData(`/companies/${companyID}/analytics/rating/complex?offset=${offset || 0}&limit=${limit || 10}`, data);

    return Promise.resolve({
      data: res.data.data,
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async getLocationOutOFating(companyID: number, data: TPostLocationsOutOfRating, offset?: number, limit?: number): Promise<ApiResponse<ApiLocationsCountsData>> {
    const res = await baseApi.postData(`/companies/${companyID}/analytics/rating/out-of-csi?offset=${offset || 0}&limit=${limit || 10}`, data);

    return Promise.resolve({
      data: res.data,
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async getLocationWithoutReviews(companyID: number, data: TPostLocationWithoutReviews, offset?: number, limit?: number): Promise<ApiResponse<ApiLocationsCountsData>> {
    const res = await baseApi.postData(`/companies/${companyID}/analytics/without-reviews?offset=${offset || 0}&limit=${limit || 10}`, data);

    return Promise.resolve({
      data: res.data,
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async getReviewsByStatuses(companyID: number, data: TPostReviewsByStatuses, offset?: number, limit?: number): Promise<ApiResponse<ApiReviewsByStatuses>> {
    const res = await baseApi.postData(`/companies/${companyID}/analytics/reviews-by-statuses?offset=${offset || 0}&limit=${limit || 10}`, data);

    return Promise.resolve({
      data: {
        data: res.data.data,
        totalCount: res.data.totalCount,
      },
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async getDissatisfaction(companyID: number, data: TPostDissatisfaction, division: string, offset?: number, limit?: number): Promise<ApiResponse<ApiDissatisfactions>> {
    const res = await baseApi.postData(`/companies/${companyID}/analytics/${division}?offset=${offset || 0}&limit=${limit || 10}`, data);

    return Promise.resolve({
      data: res.data,
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async getQRPublishing(companyID: number, data: TPostQRPublishing, offset?: number, limit?: number): Promise<ApiResponse<ApiQRPublishing[]>> {
    const res = await baseApi.postData(`/companies/${companyID}/analytics/qr-publishing?offset=${offset || 0}&limit=${limit || 10}`, data);

    return Promise.resolve({
      data: res.data.data,
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async getDataForDownload(companyID: number, data: TPostDataForDownload, offset?: number, limit?: number): Promise<ApiResponse<ApiDataForDownload[]>> {
    const res = await baseApi.postData(`/companies/${companyID}/analytics/reviews/list?offset=${offset || 0}&limit=${limit || 10}`, data);

    return Promise.resolve({
      data: res.data.data,
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async getQRPublishingPie(companyID: number, data: TPostQRPublishing): Promise<ApiResponse<ApiQRPublishingPie>> {
    return baseApi.postData(`/companies/${companyID}/analytics/qr-publishing-pie`, data);
  }

  public async getQRPublishingNodes(companyID: number, data: TPostQRPublishing): Promise<ApiResponse<ApiQRPublishingNodes[]>> {
    return baseApi.postData(`/companies/${companyID}/analytics/qr-publishing-nodes`, data);
  }

  public async getDissatisfactionPie(companyID: number, division: string, data: TPostDissatisfaction): Promise<ApiResponse<ApiDissatisfactionsPie>> {
    return baseApi.postData(`/companies/${companyID}/analytics/${division}`, data);
  }

  public async getComplexGeneral(companyID: number, data: TPostComplexGeneral): Promise<ApiResponse<ApiComplexGeneral>> {
    return baseApi.postData(`/companies/${companyID}/analytics/statistics/general`, data);
  }

  public async getGroupedCsi(companyID: number, data: TPostGroupedCsi): Promise<ApiResponse<ApiGroupedCsi>> {
    return baseApi.postData(`/companies/${companyID}/analytics/grouped/csi`, data);
  }

  public async getGroupedNps(companyID: number, data: TPostGroupedNps): Promise<ApiResponse<ApiGroupedNps>> {
    return baseApi.postData(`/companies/${companyID}/analytics/grouped/nps`, data);
  }

  public async getRegionReviewsByStatuses(companyID: number, data: TPostReviewsByStatuses): Promise<ApiResponse<ApiRegionReviewsByStatuses>> {
    return baseApi.postData(`/companies/${companyID}/analytics/regions-reviews-by-statuses`, data);
  }

  public async getDynamic(companyID: number, data: TPostDynamic): Promise<ApiResponse<ApiDynamic>> {
    return baseApi.postData(`/companies/${companyID}/analytics/dynamic`, data);
  }

  public async geTPostInformationRespondents(companyID: number, data: TPostInformationRespondents): Promise<ApiResponse<ApiInformationRespondents>> {
    return baseApi.postData(`/companies/${companyID}/analytics/information-respondents`, data);
  }
}

export const analyticsApi = new AnalyticsApi();
