import styled from 'styled-components';

export const LoginStyles = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .formWrapper {
    position: relative;
    width: 95%;
    max-width: 544px;
    min-width: 320px;
    margin: 10px;

    &::after {
      position: absolute;
      top: -2px;
      left: -2px;
      z-index: 0;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      content: '';
      background: linear-gradient(99.68deg, #c4ed99 7.23%, #88c5f2 106.37%);
    }

    .formContainer {
      background: #fff;
      position: relative;
      z-index: 1;
      padding: 56px 48px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      @media screen and (max-width: 768px) {
        padding: 32px 16px;
      }

      .formTitle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
          font-size: 38px;
          line-height: 32px;
          font-weight: 400;
          margin-bottom: 24px;
          @media screen and (max-width: 768px) {
            font-size: 25px;
          }
        }
        h3 {
          font-size: 21px;
          line-height: 18px;
          font-weight: 400;

          @media screen and (max-width: 768px) {
            font-size: 25px;
          }
        }
      }

      form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        position: relative;
        .login-form {
          width: 100%;
          display: flex;
          flex-direction: column;
          position: relative;
          gap: 24px;
          padding-bottom: 10px;
        }
      }
      .nodes {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        .selectedNode {
          cursor: pointer;
          margin-right: 10px;
          margin-bottom: 10px;
          max-width: 200px;
          border: 1px solid black;
          border-radius: 5px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 5px;
          p {
            font-size: 12px;
          }
        }
      }

      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 10px;
        }
      }

      .form__link {
        line-height: 160%;
        font-weight: 400;
        font-size: 16px;
        text-decoration: none;
        color: #353637;
        cursor: pointer;

        &:link,
        &:visited,
        &:active,
        &:hover,
        &:focus {
          color: #353637;
        }
      }
    }
  }
`;

export const NetworkSelector = styled.div`
  display: flex;
  justify-content: space-evenly;
  /* background-color: red; */
  margin-bottom: 24px;
  .network-selector {
    font-size: 22px;
    border-bottom: 1px solid black;
  }
`;

export const NetworkSelectorItem = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  padding-bottom: 4px;
  color: ${({ isSelected }) => (isSelected ? 'black' : 'rgb(183, 183, 183)')};
  border-bottom: ${({ isSelected }) => (isSelected ? '2px solid black' : '2px solid rgb(183, 183, 183)')};
  // TODO temporary
  &:last-child {
    cursor: default;
  }
`;
