import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { ApiEmailCampaigns, ApiEmailHistoryItem } from '../../../api';
import { CampaignStyles } from './CampaignStyles';
import { format } from 'date-fns';
import { ArrowDownIcon } from '../../../assets';
import { NameCustomField, TransparentButton } from '../../atoms';
import { Table } from '../table';
import { downloadSheet, extraFieldsSettings, options } from './utils';
import { useAppSelector } from '../../../state';
import { getTranslationByLangOrEng } from '../../../i18n';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useWindowSize } from 'usehooks-ts';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const coloredStats = {
  itemsDelivered: '#3ea2c1',
  itemsFormOpened: '#12414e',
  itemsUnsubscribed: '#bf9b3f',
  itemsFailed: '#dc7350',
  formOpened: '#5177d9',
  formSubmitted: '#88379b',
  itemsFormSubmitted: '#88379b',
  itemAccepted: '#75a430',
  itemsTotal: '#acacac',
  itemsProcessed: '#75a430'
};
interface ChartCampaignsEmail {
  itemsDelivered: number;
  itemsFailed: number;
  itemsFormOpened: number;
  itemsFormSubmitted: number;
  itemsProcessed: number;
  itemsTotal: number;
}
type TCampaign = {
  campaign: ApiEmailCampaigns;
  selectedCampaign?: ApiEmailCampaigns;
  setSelectedCampaign: Dispatch<SetStateAction<ApiEmailCampaigns | undefined>>;
  type: string;
  companyID: number;
};
export const CampaignEmail: FC<TCampaign> = React.memo(({
  campaign,
  selectedCampaign,
  setSelectedCampaign,
  type,
  companyID
}) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const {
    width
  } = useWindowSize();
  const [isInfoVisible, setInfoVisible] = useState<boolean>(false);
  const [items, setItems] = useState<ApiEmailHistoryItem[]>([]);
  const [headers, setHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  const keysToInclude: Array<keyof ChartCampaignsEmail> = ['itemsTotal', 'itemsFormOpened', 'itemsFormSubmitted'];
  const data = useMemo(() => ({
    labels: ['Count'],
    datasets: keysToInclude.map((key, index) => ({
      label: getTranslationByLangOrEng(interfaceLanguage, key),
      data: [campaign[key]],
      borderColor: coloredStats[key],
      backgroundColor: coloredStats[key]
    }))
  }), [campaign]);
  const description: string = useMemo(() => keysToInclude.map(el => `${getTranslationByLangOrEng(interfaceLanguage, el)}:  ${campaign[el]}`).join('  |  '), [campaign, keysToInclude]);
  const handleToggleInfo = () => {
    setInfoVisible(!isInfoVisible);
    setSelectedCampaign(campaign);
  };
  useEffect(() => {
    setInfoVisible(selectedCampaign ? selectedCampaign.id === campaign.id : false);
  }, [selectedCampaign]);
  useEffect(() => {
    setItems(campaign.items);
  }, [type, isInfoVisible]);
  useEffect(() => {
    setHeaders([{
      Header: 'ID',
      accessor: 'id',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <NameCustomField name={data.row.original.messageID} />
    }, {
      Header: 'Email',
      accessor: 'email',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <NameCustomField name={data.row.original.email} />
    }, {
      Header: 'Sent date',
      accessor: 'date',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <NameCustomField name={format(new Date(data.row.original.sentAt), 'dd.MM.yyyy')} />
    }, {
      Header: 'Unsubscribed',
      accessor: 'unsubscribed',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <NameCustomField name={`${data.row.original.unsubscribed}`} />
    }, {
      Header: 'Opened / Submitted',
      accessor: 'opened-submitted',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <NameCustomField name={`${data.row.original.formOpened} / ${data.row.original.formSubmitted}`} />
    }]);
  }, [items]);
  return <CampaignStyles>
        <div onClick={handleToggleInfo} className="campaign-header">
          <div className="campaign-title">
            <h3>{campaign.name}</h3>
            <p>{description}</p>
          </div>
          <div className="campaign-date">
            Date of creation:{' '}
            {format(new Date(campaign.createdAt), 'dd.MM.yyyy')}
          </div>
          <div className={isInfoVisible ? 'arrow' : 'arrow active'}>
            <ArrowDownIcon />
          </div>
        </div>
        <div className={`campaign-info-content ${isInfoVisible ? 'show' : ''}`}>
          <Bar height={40} options={options} data={data} />
          <div className="btns-containe">
            <TransparentButton filled text="Download Failed" handleClick={() => downloadSheet(items.filter(el => el.failed))} />
            <TransparentButton filled text="Download Unsubscribed" handleClick={() => downloadSheet(items.filter(el => el.unsubscribed))} />
          </div>
          {!!headers && !!items && isInfoVisible && <Table columns={headers} data={items} hideFieldsSplitters sortableColumns={['id']} extraFieldsSettings={extraFieldsSettings} />}
        </div>
      </CampaignStyles>;
});