/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { CustomSelectStyles, CustomSelectWrapperStyles } from './styles';
import { useClickOutside } from '../../../hooks';
import { ArrowDownIcon, CheckMark, SearchIcon } from '../../../assets';
import { useAppSelector } from '../../../state';
import { getTranslationByLangOrEng } from '../../../i18n';
import { ErrorMessage, useFormikContext } from 'formik';
import { FormErrorMessage, Loader } from '../../atoms';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TCustomSelect } from './utils';
export const CustomSelect: FC<TCustomSelect<any>> = ({
  options,
  valueField,
  labelField,
  multiple,
  name,
  label,
  required,
  onChange,
  extraStyles,
  selected = undefined,
  placeholder,
  search,
  hideError = false,
  selectError,
  selectErrorName,
  withoutForm,
  totalCount,
  offset,
  loading,
  pageNumberHandler,
  currentPage,
  customSearch,
  containerStyle,
  icon,
  disabled,
  setSearching,
  searching
}) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  let errors: any = {};
  let touched: any = {};
  if (!withoutForm) {
    const formikContext = useFormikContext();
    if (formikContext) {
      ({
        errors,
        touched
      } = formikContext);
    }
  }
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useState<string>('');
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const filteredParams = useMemo(() => {
    if (search) {
      return options.filter(option => (labelField ? `${option[labelField]}` : `${option}`).toLowerCase().includes(searchParams.toLowerCase()));
    }
    return options;
  }, [searchParams, options]);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (setSearching) {
      setSearching(e.target.value);
      return;
    }
    if (customSearch) {
      customSearch(e.target.value);
    }
    setSearchParams(e.target.value);
  };
  useEffect(() => {
    setSearchParams('');
  }, [open]);
  useClickOutside(optionsListRef, () => {
    setOpen(false);
  }, selectedValueFieldRef.current);
  return <CustomSelectWrapperStyles style={extraStyles}>
      {label && <div className="labelContainer">
          <label htmlFor={name}>
            {label}
            {required && <span className="required"> *</span>}
          </label>
        </div>}
      <CustomSelectStyles style={containerStyle}>
        <div className="selectedValue" onClick={() => disabled ? null : setOpen(!open)} ref={selectedValueFieldRef} aria-label={placeholder} tabIndex={0} onKeyDown={e => {
        if (e.key === 'Enter' && !disabled) {
          setOpen(!open);
        }
      }}>
          {(multiple || labelField && !selected?.[labelField]) && placeholder ? <span className="placeholder">{placeholder}</span> : <span className="value">{labelField ? selected[labelField] : selected}</span>}
          <div className={open ? 'arrowDownActive' : ''}>
            <ArrowDownIcon />
          </div>
        </div>
        {open && <InfiniteScroll dataLength={filteredParams.length} next={() => {
        if (pageNumberHandler && currentPage) {
          pageNumberHandler(currentPage + 1);
        }
      }} hasMore={currentPage && offset && totalCount ? currentPage * offset < totalCount : false} loader={loading ? <Loader margin={2} height={20} /> : null} scrollableTarget="optionsList">
            <div className="options" id="optionsList" ref={optionsListRef}>
              {search && <div className="searchWrapper">
                  <SearchIcon />
                  <input type="text" value={searching || searchParams} onChange={handleChange} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search')} />
                </div>}
              {!!filteredParams.length && filteredParams.map((option, index) => <div key={`${valueField ? option[valueField] : option}-${index}`} className="option">
                    <button type="button" onClick={() => {
              onChange(option);
              if (!multiple) {
                setOpen(false);
              }
            }} aria-label={labelField ? option[labelField] : option}>
                      <div className="labelContent">
                        <span>
                          {icon ? option.icon : ''} {labelField ? option[labelField] : option}
                        </span>
                      </div>
                      {multiple && <div className={selected.includes(valueField ? option[valueField] : option) ? 'default selected' : 'default'}>
                          {selected.includes(valueField ? option[valueField] : option) && <CheckMark />}
                        </div>}
                    </button>
                  </div>)}
              {!filteredParams.length && <div className="option">
                  <div className="noItems">-</div>
                </div>}
            </div>
          </InfiniteScroll>}
      </CustomSelectStyles>
      {!hideError && <div className="selectErrorContainer">
          {selectError && <span>{selectError}</span>}
          {!selectError && touched && touched[name] && <ErrorMessage name={selectErrorName || name} component={FormErrorMessage} />}
        </div>}
    </CustomSelectWrapperStyles>;
};