import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { FC, useEffect, useMemo, useState } from 'react';
import { TCategoryOption } from '../types';
import { ApiCategory, categoryApi } from '../../../../api';
import { ArrowDownIcon, CheckMark } from '../../../../assets';
const SelectOption_1 = _compiledBlock(_props => <div className="selectOption">
      <div className="selectRow" style={_props.v0}>
        <div className="selectCheckbox">
          <button className={_props.v1} onClick={_props.v2}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      type="button" aria-label={_props.v3}>
            {_props.v4}
          </button>
          <span>{_props.v5}</span>
        </div>
        {_props.v6}
      </div>
      {_props.v7}
    </div>, {
  name: "SelectOption_1",
  portals: ["v4", "v5", "v6", "v7"]
});
export const SelectOption: FC<TCategoryOption> = ({
  category,
  handleSelect,
  offset,
  companyID,
  categories
}) => {
  const [open, setOpen] = useState(false);
  const [children, setChildren] = useState<ApiCategory[]>([]);
  const isSelected = useMemo(() => categories.some(el => el.id === category.id), [categories]);
  useEffect(() => {
    if (open) {
      categoryApi.getCategoriesByCompany(companyID, category.id).then(res => {
        setChildren(res.data || []);
      });
    }
  }, [open]);
  return /*@million jsx-skip*/<SelectOption_1 v0={{
    paddingLeft: `${offset * 16}px`
  }} v1={isSelected ? 'selectMarker selectActive' : 'selectMarker'} v2={() => handleSelect(category)} v3={category.name} v4={isSelected && <CheckMark />} v5={category.name} v6={!category.isLeaf && <div className={open ? 'active' : ''} onClick={() => setOpen(!open)}
  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
  tabIndex={0} onKeyDown={e => {
    if (e.key === 'Enter') {
      setOpen(!open);
    }
  }} aria-label="Обрати підкатегорію">
            <ArrowDownIcon />
          </div>} v7={!!children.length && open && !category.isLeaf && children.map(el => <SelectOption key={el.id} offset={offset + 1} handleSelect={handleSelect} category={el} companyID={companyID} categories={categories} />)} />;
};