import React, { FC, useEffect } from 'react';
import { PieArcDatum } from '@visx/shape/lib/shapes/Pie';
import { DataItem } from '../../../../../pages';
import { animated } from '@react-spring/web';
type TPieArch = {
  index: number;
  arc: PieArcDatum<DataItem>;
  path: any;
  color: string;
  chartLegend?: boolean;
  onClick?: (el: DataItem) => void;
};
const useDataAnnotationPosition = ({
  arc,
  path,
  index
}: {
  arc: PieArcDatum<DataItem>;
  path: any;
  index: number;
}): {
  labelX: number;
  labelY: number;
  surfaceX: number;
  surfaceY: number;
  anchor: 'start' | 'end';
} => {
  const middleAngle = Math.PI / 2 - (arc.startAngle + (arc.endAngle - arc.startAngle) / 2);
  const outerRadius: number = path.outerRadius()(arc);
  const normalX = Math.cos(middleAngle);
  const normalY = Math.sin(-middleAngle);
  const isRightSide = normalX > 0;
  const labelX = isRightSide ? outerRadius + 20 : -outerRadius - 20;
  const labelY = normalY * outerRadius;
  const surfaceX = normalX * outerRadius;
  const surfaceY = normalY * outerRadius;
  const anchor = isRightSide ? 'start' : 'end';
  return {
    labelX,
    labelY,
    surfaceX,
    surfaceY,
    anchor
  };
};
export const PieArch: FC<TPieArch> = ({
  index,
  arc,
  path,
  color,
  onClick,
  chartLegend
}) => {
  const {
    surfaceX,
    surfaceY,
    labelX,
    labelY,
    anchor
  } = useDataAnnotationPosition({
    arc,
    path,
    index
  });
  return <g key={index}>
      <animated.path onClick={() => {
      if (onClick) {
        onClick(arc.data);
      }
    }} d={path(arc)} fill={color} pointerEvents="all" />
      {arc.endAngle - arc.startAngle !== 0 && chartLegend && arc.value > 2 && <animated.g>
          <line x1={surfaceX} y1={surfaceY} x2={labelX > 0 ? labelX - 10 : labelX + 10} y2={labelY} stroke={color} strokeWidth={1} />

          <circle cx={labelX + (labelX > 0 ? -5 : 5)} cy={labelY} r={5} fill={color} />

          <text fill="#000" x={labelX + (labelX > 0 ? 5 : -5)} y={labelY} dy=".33em" fontSize={12} textAnchor={labelX > 0 ? 'start' : 'end'} pointerEvents="none">
            {`${arc.value}% ${arc.data.count ? `(${arc.data.count})` : ''}`}
          </text>
        </animated.g>}
    </g>;
};