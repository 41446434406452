import styled from 'styled-components';

export const ChartSelectorStyles = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  button {
    &.chart-next, &.chart-prev {
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .chart-next {
    transform: rotate(180deg);
  }

  .chart-selector {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 250px;
    width: 100%;

    .chart-name {
      font-size: 12px;
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .chart-options {
      position: absolute;
      overflow: auto;
      z-index: 10;
      left: 50%;
      transform: translate(-50%, -100%);
      top: -10px;
      max-height: 200px;
      background: #fff;
      width: 100%;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: column;

      .chart-option {
        font-size: 10px;
        padding: 8px 12px;
        height: 30px;
        text-align: start;

        &:not(:last-child) {
          border-bottom: 1px solid rgb(196, 196, 196);
        }

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
`;
