/* eslint-disable class-methods-use-this */
import IPermissionApi from './IPermissionsApi';
import baseApi from '../baseApi';
import { ApiResponse } from '../entities';
import { IPermission } from '../../entities';
import {
  mapApiPermissionToIPermission,
  mapApiPermissionsToIPermissions,
  mapIPermissionToApiPermission,
} from '../Mappers';

class PermissionApi implements IPermissionApi {
  public async getPermissions(
    offset?: number,
    limit?: number,
    rolesIDs?: number[],
    query?: string,
  ): Promise<ApiResponse<IPermission[]>> {
    const path = `/permissions?offset=${offset || 0}&limit=${limit || 10}${
      rolesIDs ? rolesIDs.map((roleId) => `&rolesIDs=${roleId}`) : ''
    }${query ? `&query=${query}` : ''}`;
    const { data, statusCode } = await baseApi.getData(path);
    return Promise.resolve({
      data: mapApiPermissionsToIPermissions(data.permissions),
      statusCode,
      count: data.count,
    });
  }

  public async getPermission(id: number): Promise<ApiResponse<IPermission>> {
    const { data, statusCode } = await baseApi.getData(`/permissions/${id}`);

    return Promise.resolve({
      data: mapApiPermissionToIPermission(data),
      statusCode,
    });
  }

  public async deletePermission(id: number): Promise<ApiResponse<null>> {
    const { statusCode } = await baseApi.deleteData(`/permissions/${id}`);

    return Promise.resolve({
      data: null,
      statusCode,
    });
  }

  public async updatePermission(
    id: number,
    data: IPermission,
  ): Promise<ApiResponse<IPermission>> {
    const { data: response, statusCode } = await baseApi.putData(
      `/permissions/${id}`,
      mapIPermissionToApiPermission(data),
    );

    return Promise.resolve({
      data: mapApiPermissionToIPermission(response),
      statusCode,
    });
  }

  public async createPermission(
    data: IPermission,
  ): Promise<ApiResponse<IPermission>> {
    const { data: response, statusCode } = await baseApi.postData(
      '/permissions',
      mapIPermissionToApiPermission(data),
    );

    return Promise.resolve({
      data: mapApiPermissionToIPermission(response),
      statusCode,
    });
  }
}

export const permissionApi = new PermissionApi();
