import React, { FC, useEffect, useState } from 'react';
import { StatusesCounterStyles } from './StatusesCounterStyles';
import { useAppSelector } from '../../../state';
import { reviewApi } from '../../../api';
import { reviewsStatusIconsAndText } from '../../../constants';
type TStatusesCounter = {
  clientStatuses: number[];
};
export const StatusesCounter: FC<TStatusesCounter> = React.memo(({
  clientStatuses
}) => {
  const {
    data
  } = useAppSelector(state => state.user);
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [status, setStatus] = useState<{
    count: number;
    status: number;
  }[]>([]);
  useEffect(() => {
    if (data?.id) {
      reviewApi.getStatusesCount(data.id).then(res => {
        if (res.statusCode === 200) {
          setStatus(res.data);
        }
      });
    }
  }, [data]);
  return <StatusesCounterStyles>
        <div className="wrapper">
          {status && status.filter(el => clientStatuses.includes(el.status)).map(item => {
        const statusObject = reviewsStatusIconsAndText(interfaceLanguage)[item.status];
        const Icon = statusObject.icon;
        return <div key={item.status} className="status">
                    <div className="statusHead">
                      <div className="statusCount">{item.count}</div>
                      <Icon width={26} height={26} />
                    </div>
                    <div className="statusContent">
                      <div className="statusName">
                        {statusObject.text.length > 20 ? statusObject.text.slice(0, 23).concat('...') : statusObject.text}
                      </div>
                    </div>
                  </div>;
      })}
        </div>
      </StatusesCounterStyles>;
});