import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { ComplexGeneralDashboardStyles } from './styles';
import { analyticsApi, ApiComplexGeneral, ApiComplexRating, TPostComplexRating } from '../../../../api';
import { Table } from '../../../organisms';
import { PieChart } from '../../analyticsCharts';
import { Loader } from '../../../atoms';
import { setSaveFilters, setSaveLink, useAppDispatch } from '../../../../state';
import { TGroup } from '../../../../pages';
type TComplexGeneralDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[] | boolean;
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
  group: TGroup;
};
const colors: string[] = ['#6EAE93', '#BB6685', '#A7C5E9', '#F5EF67'];
const ComplexGeneralDashboard_1 = _compiledBlock(_props => _props.v0, {
  name: "ComplexGeneralDashboard_1",
  portals: ["v0"]
});
export const ComplexGeneralDashboard: FC<TComplexGeneralDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions,
  group
}) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<ApiComplexRating[]>([]);
  const [pie, setPie] = useState<ApiComplexGeneral>();
  const [loading, setLoading] = useState<boolean>(true);
  const [preloading, setPreloading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  const [pageNumber, setPageNumber] = useState(1);
  const dataCount = useRef<number>(0);
  useEffect(() => {
    if (!loading) {
      setData([]);
      setPreloading(true);
      setPageNumber(1);
      setReload(!reload);
    }
  }, [reQuery]);
  useEffect(() => {
    const {
      companyID,
      year,
      quarter,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      cnapTypes,
      sourceIDs,
      isDiia,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostComplexRating = {
      year: +year as number,
      quarter: +quarter as number,
      csiFieldIDs: [858718, 858809],
      csiPositiveAnswers: ['Чудово', 'Добре'],
      npsFieldIDs: [858735, 858818],
      npsPositiveAnswers: ['9', '10'],
      npsNegativeAnswers: ['1', '2', '3', '4', '5', '6'],
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean,
      sourceIDs: sourceIDs as number[],
      withTrustLevel: group.key === 'quarterly'
    };
    setLoading(true);
    Promise.all([analyticsApi.getComplexRatingData(+companyID, filteredData, (pageNumber ? +pageNumber - 1 : 0) * 10, 10), analyticsApi.getComplexGeneral(+companyID, filteredData)]).then(([data, pie]) => {
      setData(prev => [...prev, ...data.data]);
      dataCount.current = data.count ? data.count : 0;
      setPie(pie.data);
      setLoading(false);
      setPreloading(false);
    }).finally(() => {
      setLoading(false);
    });
    dispatch(setSaveLink('analytics/statistics/general/file'));
    dispatch(setSaveFilters({
      year: +year as number,
      quarter: +quarter as number,
      csiFieldIDs: [858718, 858809],
      csiPositiveAnswers: ['Чудово', 'Добре'],
      npsFieldIDs: [858735, 858818],
      npsPositiveAnswers: ['9', '10'],
      npsNegativeAnswers: ['1', '2', '3', '4', '5', '6'],
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      sourceIDs: sourceIDs as number[],
      isDiia: isDiia as boolean
    }));
  }, [pageNumber, reload]);
  useEffect(() => {
    setTableHeaders([{
      Header: 'IDF',
      accessor: 'idf',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.idf}</span>
          </div>
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <div className="dashboard-name">
            <span>Регіон</span>
          </div>,
      accessor: 'regionName',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.regionName}</span>
          </div>
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <div className="dashboard-name">
            <span>Назва центру</span>
          </div>,
      accessor: 'name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.name}</span>
          </div>
    }, {
      Header: 'CSI',
      accessor: 'csi',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>
              {data.row.original.csi}
            </span>
          </div>
    }, {
      Header: 'NPS',
      accessor: 'nps',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>
              {data.row.original.nps}
            </span>
          </div>
    }, {
      Header: 'Кількість відгуків',
      accessor: 'reviews',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>
              {data.row.original.reviews}
            </span>
          </div>
    }]);
  }, [data]);
  return /*@million jsx-skip*/<ComplexGeneralDashboard_1 v0={<ComplexGeneralDashboardStyles>
      <div className="charts">
        <div className="charts-item charts-one">
          <h4>CSI</h4>
          <div className="counts">
            <h2>{pie?.csi}</h2>
          </div>
        </div>
        <div className="charts-item charts-two">
          <h4>NPS</h4>
          <div className="counts">
            <h2>{pie?.nps}</h2>
          </div>
        </div>
        <div className="charts-item charts-three">
          <h4>Кількість точок</h4>
          <div className="counts">
            <h2>{pie?.locations}</h2>
          </div>
        </div>
        <div className="charts-item charts-four">
          <h4>Відгуків</h4>
          <div className="counts">
            <h2>{pie?.reviews}</h2>
          </div>
        </div>
        <div className="charts-item charts-five">
          <div className="legend">
            {pie?.csiDistribution.map((e, i) => <div className="legend-item">
                <div style={{
              background: colors[i]
            }} />
                <p>{e.value}</p>
              </div>)}
          </div>
          <div className="pies">
            <PieChart width={dimensions.height / 4} height={dimensions.height / 4} data={pie ? pie?.csiDistribution.map((el, index) => ({
            label: el.value,
            value: el.part,
            fill: colors[index]
          })) : []} chartLegend />
          </div>
        </div>
        <div className="charts-item charts-six">
          {tableHeaders && <Table columns={tableHeaders} data={data} fullWidthColumns={['name', 'answers']} totalCount={dataCount.current} currentPage={pageNumber} offset={10} loading={loading} pageNumberHandler={value => setPageNumber(value)} fixedHeader />}
        </div>
      </div>
    </ComplexGeneralDashboardStyles>} />;
};