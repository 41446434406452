import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ErrorMessage, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import base64url from 'base64url';
import buffer from 'buffer';
import SubmitButton from '../../form/submitButton/SubmitButton';
import { useAppDispatch, useAppSelector } from '../../../state';
import { ApiContacts, usersApi, authApi, userContactsApi } from '../../../api';
import { getErrorMessage, handleKeyUp } from '../../../utils';
import { getTranslationByLangOrEng } from '../../../i18n';
import { FormErrorMessage, Loader, TransparentButton } from '../../atoms';
import { NotifiableSource } from '../notifiableSource';
import { UserProfileFormStyles } from './UserProfileFormStyles';
import { QRModal } from '../../organisms';
import { Notifications } from '../notifications';
import { InputField } from '../form';
import { changeUserData } from '../../../state/thunk/authThunk';
import * as Yup from 'yup';
const validationSchema = (lang: string) => Yup.object({
  email: Yup.string().trim().required(getTranslationByLangOrEng(lang, 'validation_required'))
});
export interface FormValues {
  PIB: string;
  INN: string;
  email: string;
  phone: string;
  position: string;
}
export const UserProfileForm = React.memo(() => {
  const dispatch = useAppDispatch();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const {
    data,
    isLoading
  } = useAppSelector(state => state.user);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [reQuery, setReQuery] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');
  const [qrModal, setQrModal] = useState<boolean>(false);
  const [contacts, setContacts] = useState<ApiContacts[]>([]);
  const formRef = useRef<FormikProps<FormValues>>(null);
  const initialValues: FormValues = {
    PIB: data?.name ?? '',
    INN: data?.drfoCode ?? '',
    email: data?.email ?? '',
    phone: data?.phone ?? '+380',
    position: data?.position ?? ''
  };
  const hideNoty = useMemo(() => {
    if (data) {
      return data.roles?.find(role => [78, 82].includes(role.id!));
    }
    return false;
  }, [data]);
  const handleOpenBotLink = (messenger: 'telegram' | 'viber') => {
    global.Buffer = global.Buffer || buffer.Buffer;
    if (messenger === 'telegram') {
      setUrl(`${process.env.REACT_APP_TELEGRAM_BOT_HOST!}${base64url(`${data!.id!}`)}`);
    } else {
      setUrl(`${process.env.REACT_APP_VIBER_BOT_HOST!}${base64url(`${data!.id!}`)}`);
    }
    setQrModal(true);
  };
  useEffect(() => {
    if (data?.id) {
      usersApi.getCrmUser(data?.id).then(e => formRef.current?.setFieldValue('position', e.data.position));
    }
  }, [data?.id]);
  async function onSubmit(values: FormValues, {
    setSubmitting,
    setFieldValue
  }: FormikHelpers<FormValues>) {
    setSubmitting(false);
    dispatch(changeUserData({
      id: data?.id ?? 0,
      phone: values.phone,
      position: values.position,
      email: values.email,
      name: values.PIB
    }));
  }
  useEffect(() => {
    userContactsApi.getUserContacts(data?.id!).then(res => {
      setContacts(res.data);
    });
  }, []);
  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue
  }: FormikProps<FormValues>) => <Form>
      <div className="inputs">
        <InputField disabled name="PIB" onChange={() => {}} onKeyUp={() => handleKeyUp('PIB', setErrorMessage, errorMessage)} placeholder="ПІБ" autocomplete value={values.PIB} />
        <InputField disabled type="text" name="INN" onChange={() => {}} onKeyUp={() => handleKeyUp('INN', setErrorMessage, errorMessage)} placeholder="РНОКПП" autocomplete value={values.INN} />
        <InputField name="email" onChange={setFieldValue} onKeyUp={() => handleKeyUp('email', setErrorMessage, errorMessage)} placeholder="Електронна пошта" autocomplete value={values.email} error={typeof errorMessage === 'object' ? getErrorMessage('email', errorMessage) : undefined} />
        <InputField type="tel" name="phone" onChange={setFieldValue} onKeyUp={() => handleKeyUp('email', setErrorMessage, errorMessage)} placeholder="Номер телефону" autocomplete value={values.phone} error={typeof errorMessage === 'object' ? getErrorMessage('email', errorMessage) : undefined} />
        <InputField type="text" name="position" onChange={setFieldValue} onKeyUp={() => handleKeyUp('email', setErrorMessage, errorMessage)} placeholder="Посада" autocomplete value={values.position} error={typeof errorMessage === 'object' ? getErrorMessage('email', errorMessage) : undefined} />
      </div>
      <SubmitButton isLoading={isLoading}>
        {getTranslationByLangOrEng(interfaceLanguage, 'save_changes_button')}
      </SubmitButton>
      {typeof errorMessage === 'string' && <p className="extraErrorMessage">{errorMessage}</p>}
      {/* {contacts && (
        <Notifications
          hidens={['email']}
          setContacts={setContacts}
          contacts={contacts}
        />
       )} */}
      {/* {!hideNoty && (
        <NotifiableSource
          id={data?.id!}
          reQuery={reQuery}
          setReQuery={setReQuery}
        />
       )} */}
      <TransparentButton text={getTranslationByLangOrEng(interfaceLanguage, 'connect_tg')} handleClick={() => handleOpenBotLink('telegram')} targetIsBlank />
      <TransparentButton text={getTranslationByLangOrEng(interfaceLanguage, 'connect_vb')} handleClick={() => handleOpenBotLink('viber')} targetIsBlank />
    </Form>;
  return <UserProfileFormStyles>
      {qrModal && <QRModal onClose={() => setQrModal(false)} url={url} />}

      <div className="formContainer">
        <Formik innerRef={formRef} initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema(interfaceLanguage)} enableReinitialize>
          {renderForm}
        </Formik>
      </div>
    </UserProfileFormStyles>;
});