import React, { Dispatch, FC, SetStateAction, useEffect, useState, RefObject } from 'react';
import { QuarterFilterStyles } from './styles';
import { ArrowDownIcon, CheckMark } from '../../../assets';
import { format } from 'date-fns';
type TQuarterFilter = {
  filterParams: RefObject<{
    [key: string]: number | string | string[] | number[] | boolean;
  }>;
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
};
type TPeriod = {
  year: number;
  quarter: number;
};
export const QuarterFilter: FC<TQuarterFilter> = ({
  filterParams,
  setReQuery,
  reQuery
}) => {
  const currentYear = new Date().getFullYear();
  const currentQuarters = Math.ceil((new Date().getMonth() + 1) / 3);
  const years: number[] = Array.from({
    length: currentYear - 2021 + 1
  }, (_, i) => 2021 + i);
  const quarters: number[] = Array.from({
    length: 4
  }, (_, i) => i + 1);
  const [period, setPeriod] = useState<TPeriod>({
    year: currentYear,
    quarter: currentQuarters
  });
  const [expandedYears, setExpandedYears] = useState<number[]>([]);
  const expandYear = (year: number) => {
    setExpandedYears(prev => prev.includes(year) ? prev.filter(y => y !== year) : [...prev, year]);
  };
  const toggleYear = (year: number) => {
    setPeriod({
      year,
      quarter: 1
    });
  };
  const toggleQuarter = (year: number, quarter: number) => {
    setPeriod({
      year,
      quarter
    });
  };
  const renderQuarters = (year: number) => {
    const currentMonth = new Date().getMonth() + 1;
    const isCurrentYear = year === currentYear;
    const maxQuarter = isCurrentYear ? Math.ceil(currentMonth / 3) : 4;
    const quarters = Array.from({
      length: maxQuarter
    }, (_, i) => i + 1);
    return <div className="quarters">
        {quarters.map(quarter => <div key={quarter} className="quarter">
            <div className="quarter-checkmark" onClick={() => toggleQuarter(year, quarter)}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0} onKeyDown={e => {
          if (e.key === 'Enter') {
            toggleQuarter(year, quarter);
          }
        }} aria-label={`${quarter} квартал`}>
              {period.quarter === quarter && period.year === year ? <CheckMark /> : null}
            </div>
            <p>{`${quarter} квартал`}</p>
          </div>)}
      </div>;
  };
  useEffect(() => {
    if (filterParams.current) {
      filterParams.current.year = period.year;
      filterParams.current.quarter = period.quarter;
    }
    setReQuery(!reQuery);
  }, [period]);
  return <QuarterFilterStyles>
      <h4>Рік & Квартал</h4>
      <div className="list">
        {years.map((year, index) => <div key={year} className="year">
            <div className="year-label">
              <div className={`year-arrow ${expandedYears.includes(year) ? 'year-active' : ''}`} onClick={() => expandYear(year)}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0} onKeyDown={e => {
            if (e.key === 'Enter') {
              expandYear(year);
            }
          }} aria-label={`${year}`}>
                <ArrowDownIcon />
              </div>
              <div className="year-checkmark" onClick={() => toggleYear(year)}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0} onKeyDown={e => {
            if (e.key === 'Enter') {
              toggleYear(year);
            }
          }}>
                {period.year === year ? <CheckMark /> : null}
              </div>
              {year}
            </div>
            {expandedYears.includes(year) && renderQuarters(year)}
          </div>)}
      </div>
    </QuarterFilterStyles>;
};