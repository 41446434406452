import { companyTypes } from './config';
import { FormValues } from './types';

export const initialFormValues: FormValues = {
  companyType: companyTypes['diia-tsnap'],
  position: undefined,
  type: undefined,
  organisation: undefined,
  nodes: [],
  position_string: '',
  0: undefined,
  1: undefined,
  2: undefined,
  3: undefined,
  4: undefined,
};
