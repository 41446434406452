import { AddIssueStyles } from './styles';
import * as Yup from 'yup';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { CompaniesMainInfo, DeepArrayItem, INode, IReviewForm, IUser } from '../../entities';
import { issuesApi, usersApi, reviewApi, formApi, companyApi, ApiIssueType, ApiIssueStatus, ApiCreateTicket, mapFromApiNodeToINode } from '../../api';
import { getErrorMessage, getNodeNamesArray, getNodesDeepArray, handleKeyUp } from '../../utils';
import CustomSelectTiedNode from '../../components/form/customSelect/CustomSelectTiedNode';
import TextArea from '../../components/form/textArea/TextArea';
import SubmitButton from '../../components/form/submitButton/SubmitButton';
import { CustomSelect, InputField, Loader, SubHeader, TransparentButton } from '../../components';
import { Helmet } from 'react-helmet-async';
const validationSchema = Yup.object({
  name: Yup.string().required('Опис обовʼязковий'),
  companyID: Yup.string().required('Оберіть суб’єкт моніторингу'),
  status: Yup.mixed().nullable().required('Оберіть статус'),
  responsible: Yup.mixed().nullable().required('Оберіть відповідального'),
  node: Yup.mixed().nullable().required('Оберіть Вузол')
});
interface FormValues {
  name: string;
  comment: string;
  companyID: number;
  reviewID: number;
  priority: number;
  status: ApiIssueStatus | null;
  responsible: IUser | null;
  node: INode | null;
}
const initialData: FormValues = {
  name: '',
  comment: '',
  status: null,
  companyID: 0,
  priority: 1,
  reviewID: 0,
  responsible: null,
  node: null
};
export const AddIssue = () => {
  const navigate = useNavigate();
  const {
    id
  } = useParams();
  const priorities = [1, 2, 3, 4, 5];
  const [initialValues, setInitialValues] = useState<FormValues>(initialData);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [companies, setCompanies] = useState<CompaniesMainInfo[]>([]);
  const [nodeNames, setNodeNames] = useState<{
    id: number;
    name: string;
    deep: number;
  }[]>([]);
  const [nodes, setNodes] = useState<DeepArrayItem[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [statuses, setStatuses] = useState<ApiIssueStatus[]>([]);
  async function getDataBydNode(node: INode) {
    console.log(node);
    const usersPromis: Promise<IUser>[] = node.responsibleIDs.map(async el => {
      const res = await usersApi.getUser(el);
      return res.data;
    });
    const usersResp: IUser[] = await Promise.all(usersPromis);
    setUsers(usersResp);
  }
  const getNodesByCompany = async (companyId: number) => {
    const [statuses, nodes] = await Promise.all([issuesApi.getTicketStatusesByCompany(companyId), formApi.getCompanyNodes(companyId)]);
    setStatuses(statuses.data);
    if (nodes.statusCode >= 200 && nodes.statusCode < 300) {
      const nodeNamesArray: {
        id: number;
        name: string;
        deep: number;
      }[] = [];
      getNodeNamesArray(nodeNamesArray, nodes.data || []);
      setNodeNames(nodeNamesArray);
      setNodes(getNodesDeepArray(nodeNamesArray));
    }
  };
  const getAllCompanies = async () => {
    companyApi.getCompanies().then(res => {
      if (res.statusCode === 200) {
        setCompanies(res.data);
      }
    });
  };
  const onSubmit = async (values: FormValues, {
    setSubmitting
  }: FormikHelpers<FormValues>) => {
    if (id) {
      const ticket: ApiCreateTicket = {
        name: values.name,
        comment: values.comment,
        nodeID: values.node?.id!,
        priority: values.priority,
        responsibleID: values.responsible?.id!,
        reviewID: values.reviewID,
        statusID: values.status?.id!
      };
      issuesApi.updateTicket(id, ticket);
    }
    setSubmitting(false);
  };
  const renderForm = ({
    values,
    setFieldValue
  }: FormikProps<FormValues>) => <Form>
      <InputField extraBlockStyles={{
      marginTop: '25px'
    }} name="name" onChange={setFieldValue} onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)} placeholder="Введіть опис делегування" value={values.name} error={typeof errorMessage === 'object' ? getErrorMessage('name', errorMessage) : undefined} required disabled />

      <CustomSelect options={companies} selected={companies.find(company => company.id === values.companyID)} valueField="id" labelField="name" name="companyID" onChange={(value: any) => {
      setFieldValue('companyID', value.id);
      getNodesByCompany(value.id);
    }} placeholder="Оберіть суб’єкт моніторингу" label="Суб’єкт моніторингу" required disabled />

      <CustomSelectTiedNode label="Вузол" name="node" options={nodes} selectKey="name" placeholder="Виберіть вузол" disabled value={nodeNames?.find(node => node.id === values.node?.id)} handleSelect={node => {
      formApi.getNodeById(node.id).then(res => {
        setFieldValue('node', res.data);
      });
    }} required search expandable />

      <Link to={`/reviews/company/${values.companyID}/review/${values.reviewID}`} className="ticket-link">
        Переглянути відгук
      </Link>

      <div className="ticket-dashes" />

      <CustomSelect options={users} selected={values.responsible} valueField="id" labelField="name" name="responsible" required onChange={(value: any) => {
      setFieldValue('responsible', value);
    }} placeholder="Оберіть відповідального" label="Відповідальна особа" />

      <CustomSelect options={statuses} selected={values.status} required valueField="id" labelField="name" disabled={!values.companyID} name="status" onChange={(value: any) => {
      setFieldValue('status', value);
    }} placeholder="Оберіть статус" label="Статус делегування" />

      <CustomSelect options={priorities} selected={values.priority} required name="priority" onChange={(value: any) => {
      setFieldValue('priority', value);
    }} placeholder="Оберіть приорітет" label="Приорітет делегування" />

      <TextArea name="comment" onChange={setFieldValue} onKeyUp={() => handleKeyUp('comment', setErrorMessage, errorMessage)} placeholder="Введіть коментар" value={values.comment} extraBlockStyles={{
      marginBottom: 24,
      height: 120
    }} />

      <SubmitButton extraBlockStyles={{
      maxWidth: 250,
      width: '100%'
    }}>
        Зберегти
      </SubmitButton>
    </Form>;
  useEffect(() => {
    getAllCompanies();
    if (id) {
      issuesApi.getTicket(+id).then(res => {
        getNodesByCompany(res.data.companyID);
        getDataBydNode(mapFromApiNodeToINode(res.data.node));
        const ticket: FormValues = {
          name: res.data.name,
          comment: res.data.comment,
          companyID: res.data.companyID,
          reviewID: res.data.reviewID,
          priority: res.data.priority,
          status: res.data.status,
          responsible: res.data.responsible,
          node: mapFromApiNodeToINode(res.data.node)
        };
        setInitialValues(ticket);
      });
    }
  }, []);
  return <AddIssueStyles>
      <Helmet>
        <title>{id ? `Завдання ${id}` : 'Створення завдання'}</title>
      </Helmet>

      {!loading && initialValues && <SubHeader title={id ? 'Редагування завдання' : 'Створення завдання'}>
          <TransparentButton text="Закрити" isLink linkAddress="/tickets" filled />
        </SubHeader>}

      <div className="ticket-wrapper">
        {loading ? <Loader /> : <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
            {renderForm}
          </Formik>}
      </div>
    </AddIssueStyles>;
};