import React, { FC } from 'react';
import { TIcon } from './TIcon';
export const FullScreen: FC<TIcon> = React.memo(({
  width = 20,
  height = 20,
  color = '#768E9D',
  bgColor = '#D7DFE4'
}) => <svg width={width} height={height} viewBox="0 0 20 20" fill="none">
    <rect width="20" height="20" fill={bgColor} />
    <path d="M11 8.85069L17 3" stroke={color} strokeWidth="2" />
    <path d="M11 3H17V9" stroke={color} strokeWidth="2" />
    <path d="M9 11.1493L3 17" stroke={color} strokeWidth="2" />
    <path d="M9 17L3 17L3 11" stroke={color} strokeWidth="2" />
  </svg>);