import { useLocalStorage } from 'usehooks-ts';

export enum RegistrationStatuses {
  nil = 'nil',
  pre_registered = 'pre_registered',
  user_data_complete = 'user_data_complete',
  view_access = 'view_access',
  access_pending = 'access_pending',
  registered = 'registered',
}

export const useRegistrationStatus = () => {
  const [currentRegistrationStatus, setRegistrationStatus] = useLocalStorage(
    '@registrationStatuses',
    RegistrationStatuses.pre_registered,
  );
  const setStatus = (newStatus: RegistrationStatuses) => {
    console.log(`sets new status: ${newStatus}`);
    setRegistrationStatus(newStatus);
  };

  return { currentStatus: currentRegistrationStatus, setStatus };
};
