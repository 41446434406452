import styled from 'styled-components';

export const ComplexGeneralDashboardStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  align-items: center;

  @media screen and (max-width: 1440px) {
    gap: 10px;
  }

  .rating {
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box !important;

    &-col {
      box-sizing: border-box;
      font-size: 14px;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 1440px) {
        padding: 0;
        font-size: 12px;
      }
    }

    &-item {
      font-size: 14px;

      @media screen and (max-width: 1440px) {
        font-size: 12px;
      }
    }
  }

  .charts {
    height: 100%;
    width: 100%;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    gap: 24px;
    grid-template-areas:
      'one two five five five'
      'three four five five five'
      'six six six six six'
      'six six six six six'
      'six six six six six';

    @media screen and (max-width: 1440px) {
      gap: 8px;
    }

    &-item {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 24px;
      gap: 8px;
      border: 1px solid #dee6eb;
      border-radius: 10px;

      @media screen and (max-width: 1440px) {
        padding: 12px;
      }

      h4 {
        font-weight: 400;
        font-size: 18px;
        line-height: 115%;
        text-align: center;
        color: #000000;
        padding-bottom: 10px;
        border-bottom: 1px solid #dee6eb;

        @media screen and (max-width: 1440px) {
          font-size: 10px;
          padding-bottom: 5px;
        }
      }
    }

    &-one {
      grid-area: one;
    }

    &-two {
      grid-area: two;
    }

    &-three {
      grid-area: three;
    }

    &-four {
      grid-area: four;
    }

    &-five {
      grid-area: five;
    }

    &-six {
      grid-area: six;
      padding: 0;
      border: none;
      border-radius: 0;
    }
  }

  .pies {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
  }

  .counts {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    h2 {
      font-weight: 400;
      font-size: 35px;
      line-height: 115%;
      text-align: center;
      color: #000000;

      @media screen and (max-width: 1440px) {
        font-size: 26px;
      }
    }
  }

  .legend {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    flex-wrap: wrap;

    &-item {
      display: flex;
      align-items: center;
      gap: 8px;
      max-width: 120px;

      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 115%;
        color: #000000;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        overflow: hidden;
      }

      div {
        border-radius: 50%;
        width: 14px;
        height: 14px;
        flex-shrink: 0;
      }
    }
  }
`;
