import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClientStatusesEmptyChatStyles } from './QRControlFormsStyles';
import { useClickOutside } from '../../../../hooks';
import { ArrowDownIcon, CheckMark } from '../../../../assets';
import { reviewsStatusIconsAndText } from '../../../../constants';
import { useAppSelector } from '../../../../state';
import { companySettingsApi, formApi } from '../../../../api';
import { Loader } from '../../../atoms';
type TClientStatusesEmptyChat = {
  initialQrContolForm?: number;
};
export const QRControlForms: FC<TClientStatusesEmptyChat> = ({
  initialQrContolForm
}) => {
  const {
    id
  } = useParams();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [qrContolForm, setQrContolForm] = useState<number | undefined>();
  const [companyForms, setCompanyForms] = useState<any[]>([]);
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    formApi.getFormsByCompany(+(id ?? 1), 1, 100).then(res2 => {
      setCompanyForms(res2.data.map(e => ({
        id: e.id,
        name: e.name,
        value: false
      })));
    });
  }, []);
  const handleUpdate = useCallback((formId: number) => {
    if (id) {
      setLoading(true);
      companySettingsApi.updateCompanySettingsKey(+id, 'qr-confirmation-form', {
        value: [formId]
      }).then(res => {
        setQrContolForm(formId);
        setOpen(false);
        setLoading(false);
      });
    }
  }, [initialQrContolForm]);
  useClickOutside(optionsListRef, () => {
    setOpen(false);
  }, selectedValueFieldRef.current);
  useEffect(() => {
    if (initialQrContolForm) {
      setQrContolForm(initialQrContolForm);
    }
  }, [initialQrContolForm]);
  return <ClientStatusesEmptyChatStyles>
      <div className="clientStatusesEmptyChat">
        <h3>Форма контролю розміщення QR</h3>
        <div className="selectField">
          <div className="selectedValue" onClick={() => setOpen(!open)} ref={selectedValueFieldRef}>
            <span className="placeholder">Оберіть форму</span>
            <div className={open ? 'arrowDownActive' : ''}>
              <ArrowDownIcon />
            </div>
          </div>

          {open && <div className="options" ref={optionsListRef}>
              {loading && <div className="loader">
                  <Loader />
                </div>}
              {companyForms.map(element => <div key={element.id} className="option">
                  <div className="rowSel">
                    <button type="button" onClick={() => !loading && handleUpdate(+element.id)}>
                      <div className="labelContent">
                        <div className="default">
                          {qrContolForm === element.id && <CheckMark />}
                        </div>
                        <span>{element.name}</span>
                      </div>
                    </button>
                  </div>
                </div>)}
            </div>}
        </div>
      </div>
    </ClientStatusesEmptyChatStyles>;
};