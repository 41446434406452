import React from 'react';
import { ProfileStyles } from './ProfileStyles';
import { useAppSelector } from '../../state';
import { getTranslationByLangOrEng } from '../../i18n';
import { Helmet } from 'react-helmet-async';
import { SubHeader, UserProfileForm } from '../../components';
import { RegistrationStatus } from '../../constants/registrationStatuses';
interface FormValues {
  name: string;
  email: string;
  oldPassword: string;
  newPassword: string;
  phoneNumber: string;
}
export const Profile = () => {
  const {
    data: user
  } = useAppSelector(state => state.user);
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const loggedIn = user?.status === RegistrationStatus.assigned;
  return <ProfileStyles>
      <Helmet>
        <title>Профіль</title>
      </Helmet>
      <SubHeader title={getTranslationByLangOrEng(interfaceLanguage, 'employee_profile_title')} />
      {user && loggedIn && <UserProfileForm />}
    </ProfileStyles>;
};