/* eslint-disable class-methods-use-this */
import baseApi from '../baseApi';
import { ApiPosterTemplate, ApiPreviewQRValues, ApiResponse } from '../entities';
import ICompanyPostersApi from './ICompanyPostersApi';
import { ICreatePoster, ICreatePosterTemplate, IPreviewPosterTemplate } from '../../entities/ICreatePosters';

class CompanyPostersApi implements ICompanyPostersApi {
  public async getCompanyPosterTemplates(id: number): Promise<ApiResponse<ApiPosterTemplate[]>> {
    return baseApi.getData(`/companies/${id}/qr-templates`);
  }

  public async createPosterTemplate(values: ICreatePosterTemplate, companyId: number): Promise<ApiResponse<ApiPosterTemplate>> {
    return baseApi.postData(`/companies/${companyId}/qr-templates`, values);
  }

  public async deletePosterTemplate(posterID: number): Promise<ApiResponse<{message: string}>> {
    return baseApi.deleteData(`/qr-templates/${posterID}`);
  }

  public async previewPoster(values: IPreviewPosterTemplate): Promise<ApiResponse<Blob>> {
    return baseApi.postData('/qr-templates/preview', values, true);
  }

  public async createQRPoster(values: ICreatePoster): Promise<ApiResponse<Blob>> {
    return baseApi.postData(`/nodes/${values.node_id}/forms/${values.form_id}/poster`, values.params, true);
  }

  public async generateQR(values: ApiPreviewQRValues): Promise<ApiResponse<Blob>> {
    return baseApi.postData('/qr-templates/preview/qr', values, true);
  }
}

export const companyPostersApi = new CompanyPostersApi();
