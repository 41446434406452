import React, { useEffect, useRef, useState } from 'react';
import { MassmailerStyles } from './MassmailerStyles';
import { useAppSelector } from '../../state';
import { TabMailKeys, tabsEdit } from './utils';
import { Helmet } from 'react-helmet-async';
import { CampaignTable } from './campaignTable/CampaignTable';
import { CampaignEmail, CampaignSms, CompanyFilter, CustomTabsMenu, Loader, Modal, SubHeader, Templaters, TransparentButton } from '../../components';
import { ApiEmailCampaigns, ApiEmailHistoryItem, ApiSmsCampaigns, massMailingApi, companyApi, ApiViberHistoryItem } from '../../api';
import { CompaniesMainInfo } from '../../entities';
import { Sms } from './sms';
import molData from './email/email.json';
import _ from 'lodash';
import { CustomSelect } from '../../components/molecules/customSelect/CustomSelect';
import { ApiCampaign } from '../../api/entities/ApiCampaigns';
import InfiniteScroll from 'react-infinite-scroll-component';
export const Massmailer = () => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [selectedTab, setSelectedTab] = useState(tabsEdit(interfaceLanguage)[0].key);
  const channels = [{
    channel: 'SMS'
  }, {
    channel: 'Viber'
  }, {
    channel: 'Email'
  }];
  const [selectedChanel, setSelectedChanel] = useState<{
    channel: 'SMS' | 'Viber' | 'Email';
  }>();
  const [companies, setCompanies] = useState<{
    [key: string]: CompaniesMainInfo;
  }>();
  const [reQuery, setReQuery] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [campaignsSms, setCampaignsSms] = useState<ApiSmsCampaigns[]>([]);
  const [selectedCampaignSms, setSelectedCampaignSms] = useState<ApiSmsCampaigns>();
  const [campaignsEmail, setCampaignsEmail] = useState<ApiEmailCampaigns[]>([]);
  const [selectedCampaignEmail, setSelectedCampaignEmail] = useState<ApiEmailCampaigns>();
  const [addCampaign, setAddCampaign] = useState<boolean>(false);
  const [templater, setTemplater] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMoreCampaign, setHasMoreCampaign] = useState(true);
  const filterParamsRef = useRef<{
    [key: string]: number | string | string[] | number[];
  }>({});
  const campaignCount = useRef<number>(0);
  const infiniteScrollRef = useRef<HTMLDivElement>(null);
  const campaignsContainerRef = useRef<HTMLDivElement>(null);
  const getCompanies = (data: CompaniesMainInfo[]) => {
    const apiCompanies: {
      [key: string]: CompaniesMainInfo;
    } = data.reduce((acc, item) => {
      acc[`${item.id}`] = item;
      return acc;
    }, {} as {
      [key: string]: CompaniesMainInfo;
    });
    if (Object.keys(apiCompanies).length > 1) {
      setCompanies(apiCompanies);
    } else {
      const id = Object.values(apiCompanies)[0].id;
      filterParamsRef.current = {
        companyID: id
      };
      setReQuery(!reQuery);
    }
  };
  useEffect(() => {
    setCampaignsSms([]);
    setPageNumber(1);
    setReload(!reload);
    setHasMoreCampaign(true);
  }, [selectedTab, filterParamsRef.current, addCampaign, templater, selectedChanel]);
  const checkIfMoreCampaigns = () => {
    if (infiniteScrollRef.current && campaignsContainerRef.current && campaignsSms.length) {
      const infiniteScrollHeight = infiniteScrollRef.current.clientHeight;
      const sidebarListHeight = campaignsContainerRef.current.clientHeight;
      if (infiniteScrollHeight >= sidebarListHeight && campaignsSms.length < campaignCount.current) {
        setPageNumber(prev => prev + 1);
      }
    }
  };
  useEffect(() => {
    checkIfMoreCampaigns();
  }, [campaignsSms, campaignCount.current]);
  useEffect(() => {
    companyApi.getCompanies().then(res => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        getCompanies(res.data);
      }
    });
  }, []);
  useEffect(() => {
    setLoading(true);
    if (selectedTab === TabMailKeys.campains && filterParamsRef.current.companyID && selectedChanel?.channel === 'SMS') {
      massMailingApi.massMailingSmsCampaignList(+filterParamsRef.current.companyID, (pageNumber ? +pageNumber - 1 : 0) * 10, 10).then(res => {
        if (res.statusCode === 200) {
          setCampaignsSms(prev => [...prev, ...res.data]);
          campaignCount.current = res.count ? res.count : 0;
          if (campaignsSms.length + res.data.length >= (res.count || 0)) {
            setHasMoreCampaign(false);
          }
        }
      }).finally(() => {
        setLoading(false);
      });
    }
    if (selectedTab === TabMailKeys.campains && filterParamsRef.current.companyID && selectedChanel?.channel === 'Viber') {
      massMailingApi.massMailingViberCampaignList(+filterParamsRef.current.companyID, (pageNumber ? +pageNumber - 1 : 0) * 10, 10).then(res => {
        if (res.statusCode === 200) {
          setCampaignsSms(prev => [...prev, ...res.data]);
          campaignCount.current = res.count ? res.count : 0;
          if (campaignsSms.length + res.data.length >= (res.count || 0)) {
            setHasMoreCampaign(false);
          }
        }
      }).finally(() => {
        setLoading(false);
      });
    }
    if (selectedTab === TabMailKeys.campains && selectedChanel?.channel === 'Email') {
      massMailingApi.massMailingEmailCampaignList(+filterParamsRef.current.companyID).then(res => {
        const sortedData = _.orderBy(res.data.campaigns, ['createdAt'], ['desc']);
        if (res.statusCode === 200) {
          // @ts-ignore
          setCampaignsEmail(sortedData);
        }
      });
    }
  }, [pageNumber, reload]);
  const handleDelete = async (id: number) => {
    await massMailingApi.deleteMassSmsMailingCampaign(id);
    setCampaignsSms(prevCamp => prevCamp.filter(e => e.id !== id));
  };
  return <MassmailerStyles>
      <Helmet>
        <title>Масові розсилки</title>
      </Helmet>
      <SubHeader strechChild title="Масові розсилки">
        <div className="massmailing-subheader">
          {companies && <CompanyFilter filterParams={filterParamsRef.current} companies={companies} setFilterParams={params => {
          filterParamsRef.current = params;
        }} setReQuery={() => setReQuery(!reQuery)} />}
          {selectedTab === TabMailKeys.campains && <CustomSelect containerStyle={{
          marginTop: 0
        }} extraStyles={{
          maxWidth: '300px'
        }} name="channels" options={channels} placeholder="Канал комунікації" labelField="channel" selected={selectedChanel} onChange={value => {
          setSelectedChanel(value);
        }} />}
        </div>
      </SubHeader>
      <CustomTabsMenu tabs={tabsEdit(interfaceLanguage)} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      {filterParamsRef.current.companyID && selectedTab === TabMailKeys.createCampain && <CampaignTable filterParams={filterParamsRef} setAddCampaign={() => setAddCampaign(true)} setTemplater={() => setTemplater(true)} companyID={+filterParamsRef.current.companyID} />}
      {selectedChanel && selectedTab === TabMailKeys.campains && <div className="history" ref={infiniteScrollRef} id="scrollableCampaign">
          <InfiniteScroll dataLength={campaignsSms.length} next={() => {
        setPageNumber(prev => prev + 1);
      }} loader={loading ? <Loader margin={10} /> : null} hasMore={hasMoreCampaign} scrollableTarget="scrollableCampaign">
            <div className="history-container" ref={campaignsContainerRef}>
              {(selectedChanel?.channel === 'SMS' || selectedChanel?.channel === 'Viber') && !!campaignsSms.length && campaignsSms.map(campaign => <CampaignSms handleDelete={handleDelete} selectedCampaign={selectedCampaignSms} key={campaign.id} campaign={campaign} setSelectedCampaign={setSelectedCampaignSms} type={selectedChanel?.channel} companyID={+filterParamsRef.current.companyID} />)}
            </div>
          </InfiniteScroll>

          {selectedChanel?.channel === 'Email' && !!campaignsEmail.length && campaignsEmail.map(campaign => <CampaignEmail selectedCampaign={selectedCampaignEmail} key={campaign.id} campaign={campaign} setSelectedCampaign={setSelectedCampaignEmail} type="Email" companyID={+filterParamsRef.current.companyID} />)}
        </div>}

      {addCampaign && <Modal title="Створення розсилки" onClose={() => setAddCampaign(false)}>
          <Sms filters={filterParamsRef.current} setAddCampaign={setAddCampaign} companyID={+filterParamsRef.current.companyID} />
        </Modal>}
      {templater && <Modal title="Редактор шаблонів" onClose={() => setTemplater(false)}>
          <Templaters setTemplater={setTemplater} companyID={+filterParamsRef.current.companyID} />
        </Modal>}
    </MassmailerStyles>;
};