import styled from 'styled-components';

export const NpsDetailedDashboardStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;

  .charts {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
    flex: 1;

    &-item {
      width: calc(50% - 12px);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 24px;
      gap: 8px;
      border: 1px solid #dee6eb;
      border-radius: 10px;

      @media screen and (max-width: 1440px) {
        padding: 12px;
      }

      h4 {
        font-weight: 400;
        font-size: 18px;
        line-height: 115%;
        text-align: center;
        color: #000000;
        padding-bottom: 10px;
        border-bottom: 1px solid #dee6eb;

        @media screen and (max-width: 1440px) {
          font-size: 10px;
          padding-bottom: 5px;
        }
      }
    }
  }

  .bars {
    display: flex;
    align-items: flex-end;
    gap: 15px;
    flex: 1;

    @media screen and (max-width: 1440px) {
      gap: 8px;
    }

    &-item {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 15px;

      @media screen and (max-width: 1440px) {
        gap: 8px;
      }

      h6 {
        font-weight: 400;
        font-size: 10px;
        line-height: 115%;
        text-align: center;
        color: #000000;
        width: 100%;
      }
    }

    &-fill {
      flex: 1;
      position: relative;
      width: 100%;
      height: 100%;
      max-width: 200px;

      &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        bottom: 0;
        transform: translate(-50%);
        left: 50%;
        background: #dee6eb;
      }

      div {
        background: #cfc4ff;
        border-radius: 100px 100px 0 0px;
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        width: 100%;
        max-width: 50%;
      }

      span {
        font-weight: 700;
        font-size: 16px;
        line-height: 115%;
        color: #000000;
        opacity: 0.8;
        position: absolute;
        z-index: 3;
        transform: translate(-50%, -50%) rotate(-90deg);
        bottom: 30px;
        left: 50%;

        @media screen and (max-width: 1440px) {
          font-size: 10px;
          bottom: 10px;
        }
      }
    }
  }
`;
