import { Dispatch, FC, SetStateAction, useRef, useState } from 'react';
import { useAppSelector } from '../../state';
import { useClickOutside } from '../../hooks';
import { CopyIcon, DeleteIcon, ThreeDots } from '../../assets';
import { getTranslationByLangOrEng } from '../../i18n';
import { CopyRolesModal, ModalConfirmDecline } from '../../components';
import { rolesApi } from '../../api';
import { proxyFile } from '../../utils';
type TEditButtons = {
  id: number;
  filterItemsAfterDelete: (id: number) => void;
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
};
export const EditButtons: FC<TEditButtons> = ({
  id,
  filterItemsAfterDelete,
  setReQuery,
  reQuery
}) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [modalIndex, setModalIndex] = useState<number>(0);
  const [modalErrorIndex, setModalErrorIndex] = useState<number>(0);
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const menuRef = useRef<any>();
  useClickOutside(menuRef, () => setMenuOpen(false));
  return <div className="editButtonsContainer">
      <div className="menuOpenerContainer" ref={menuRef}>
        <button className="menuOpenerButton" type="button" onClick={event => {
        event.stopPropagation();
        setMenuOpen(!menuOpen);
      }}>
          <ThreeDots />
        </button>
        {menuOpen && <ul className="menuList">
            <li>
              <button type="button" onClick={event => {
            event.stopPropagation();
            setModalIndex(2);
          }}>
                <CopyIcon width={20} height={20} />{' '}
                <span>
                  {getTranslationByLangOrEng(interfaceLanguage, 'copy_button')}
                </span>
              </button>
            </li>
            <li>
              <button type="button" onClick={event => {
            event.stopPropagation();
            setModalIndex(1);
          }}>
                <DeleteIcon />
                <span>
                  {getTranslationByLangOrEng(interfaceLanguage, 'delete_button')}
                </span>
              </button>
            </li>
          </ul>}
      </div>
      {modalIndex === 1 && <ModalConfirmDecline onClose={() => setModalIndex(0)} onDecline={event => {
      event.stopPropagation();
      setModalIndex(0);
    }} onConfirm={event => {
      event.stopPropagation();
      rolesApi.deleteRole(id).then(res => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          filterItemsAfterDelete(id);
          setModalIndex(0);
        } else {
          setModalErrorIndex(1);
        }
      });
    }} title={getTranslationByLangOrEng(interfaceLanguage, 'languages_table_modal_delete_title')} buttonsLabel={getTranslationByLangOrEng(interfaceLanguage, 'users_main_delete_description')} confirmText={getTranslationByLangOrEng(interfaceLanguage, 'delete_button')} declineText={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')} firstButtonStyles={{
      border: '1px solid #C4C4C4',
      backgroundColor: '#ffffff',
      color: '#000000'
    }} secondButtonStyles={{
      backgroundColor: '#FF0101',
      border: '1px solid #FF0101'
    }} error={modalErrorIndex === 1} errorText={getTranslationByLangOrEng(interfaceLanguage, 'delete_button')} />}
      {modalIndex === 2 && <CopyRolesModal onClose={() => {
      setModalIndex(0);
      setReQuery(!reQuery);
    }} roleId={id} />}
    </div>;
};
export const NameCustomField = ({
  name,
  logo,
  textColor
}: {
  name: string;
  logo?: string;
  textColor?: string;
}) => <div className="customNameWrapper">
      {logo && <div className={logo ? 'imageContainer' : 'imageContainer default'}>
          <div className="image">{logo && <img src={proxyFile(logo, true)} alt="logo" />}</div>
        </div>}
      <span style={{
    color: textColor || '#000'
  }}>{name}</span>
    </div>;