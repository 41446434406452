import { AddRoleStyles } from './styles';
import { getTranslationByLangOrEng, translations } from '../../i18n';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { formInitialValues, FormValues, validationSchema, extraFieldsSettings, NameCustomField, EditButtons, StaticCheckBox } from './utils';
import { ApiResponse, rolesApi, permissionApi } from '../../api';
import { IUserPermission } from '../../entities';
import { availablePageSizes } from '../../constants';
import { useAppSelector } from '../../state';
import { Helmet } from 'react-helmet-async';
import { getErrorMessage, handleKeyUp } from '../../utils';
import { CustomSelect, InputField, Loader, SubHeader, Table, TransparentButton } from '../../components';
import { useDebounce } from '../../hooks';
export const AddRole = () => {
  const navigate = useNavigate();
  const {
    id
  } = useParams();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [initialValues, setInitialValues] = useState<FormValues>(formInitialValues);
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useState<string>('');
  const [permissions, setPermissions] = useState<IUserPermission[]>([]);
  const [rolePermission, setRolePermission] = useState<IUserPermission[]>([]);
  const [reload, setReload] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [uploading, setUploading] = useState<boolean>(false);
  const permissionsCount = useRef<number>(0);
  const rolePermissionsCount = useRef<number>(0);
  const debouncedSearch = useDebounce(searchParams, 1000);
  useEffect(() => {
    if (!uploading) {
      setPermissions([]);
      setPageNumber(1);
      setReload(!reload);
    }
  }, [debouncedSearch]);
  useEffect(() => {
    setUploading(true);
    permissionApi.getPermissions((pageNumber ? +pageNumber - 1 : 0) * 10, 10, [], debouncedSearch).then(res => {
      if (res.statusCode === 200) {
        setPermissions(prev => [...prev, ...res.data]);
        permissionsCount.current = res.count ? res.count : 0;
        setUploading(false);
      }
    }).finally(() => {
      setUploading(false);
    });
  }, [pageNumber, reload]);
  useEffect(() => {
    if (id) {
      setLoading(true);
      rolesApi.getRole(+id).then(res => {
        if (res.statusCode === 200) {
          const fetchAllPermissions = async (offset = 0, limit = 10, accumulatedPermissions: IUserPermission[] = []): Promise<IUserPermission[]> => {
            const permissions = await permissionApi.getPermissions(offset, limit, [res.data.id ?? 0]);
            const newPermissions: IUserPermission[] = [...accumulatedPermissions, ...permissions.data];
            const totalPermissionsCount = permissions.count ?? 0;
            if (newPermissions.length < totalPermissionsCount) {
              return fetchAllPermissions(offset + limit, limit, newPermissions);
            }
            return newPermissions;
          };
          fetchAllPermissions().then(allPermissions => {
            setInitialValues({
              name: res.data.name,
              permissions: allPermissions
            });
            setRolePermission(allPermissions);
            rolePermissionsCount.current = allPermissions.length;
            setLoading(false);
          });
        }
      }).finally(() => {
        setLoading(false);
      });
    } else {
      setInitialValues(formInitialValues);
    }
  }, []);
  async function updatePermissionsList(roleId: number, permissions: IUserPermission[]) {
    const initialPermissions = initialValues?.permissions.map(item => item.id) || [];
    const currentPermissions = permissions.map(item => item.id);
    const permissionsToAttach: number[] = [];
    const permissionsToDetach: number[] = [];
    currentPermissions.forEach((item, index) => {
      if (!initialPermissions.includes(item)) {
        permissionsToAttach.push(item!);
      }
    });
    initialPermissions.forEach((item, index) => {
      if (!currentPermissions.includes(item)) {
        permissionsToDetach.push(item!);
      }
    });
    if (permissionsToAttach.length) {
      await rolesApi.attachPermissionsToRole(roleId, permissionsToAttach);
    }
    if (permissionsToDetach.length) {
      await rolesApi.detachPermissionsFromRole(roleId, permissionsToDetach);
    }
  }
  async function onSubmit(values: FormValues, {
    setSubmitting
  }: FormikHelpers<FormValues>) {
    setSubmitting(false);
    let res;
    try {
      if (id) {
        res = await rolesApi.updateRole(+id, {
          ...values,
          permissions: rolePermission
        });
      } else {
        res = await rolesApi.createRole({
          ...values,
          permissions: rolePermission
        });
      }
      await updatePermissionsList(id ? +id : res?.data?.id!, rolePermission);
      if (res.statusCode >= 200 && res.statusCode < 300) {
        navigate('/users/roles');
      }
    } catch (e) {
      console.log('ON SUBMIT ERROR', e);
    }
  }
  const filterItemsAfterDelete = useCallback((id: number) => {
    setRolePermission(prev => prev.filter(el => el.id !== id));
  }, []);
  useEffect(() => {
    setTableHeaders([{
      Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_permission_header'),
      accessor: 'permission',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <NameCustomField name={data.row.original.name} textColor="#6c6c6c" />
    }, {
      Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_resource_header'),
      accessor: 'resource',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <NameCustomField name={data.row.original.resource} textColor="#6c6c6c" />
    }, {
      Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_create_header'),
      accessor: 'create',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <StaticCheckBox value={data.row.original.permissions.includes(1)} />
    }, {
      Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_read_header'),
      accessor: 'read',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <StaticCheckBox value={data.row.original.permissions.includes(2)} />
    }, {
      Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_update_header'),
      accessor: 'update',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <StaticCheckBox value={data.row.original.permissions.includes(4)} />
    }, {
      Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_delete_header'),
      accessor: 'delete',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <StaticCheckBox value={data.row.original.permissions.includes(8)} />
    }, {
      Header: 'Edit buttons',
      accessor: 'editButtons',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <EditButtons lang={interfaceLanguage} id={data.row.original.id} filterItemsAfterDelete={filterItemsAfterDelete} />
    }]);
  }, [permissions]);
  const renderForm = ({
    values,
    errors,
    setFieldValue,
    handleChange
  }: FormikProps<FormValues>) => <Form id="roleForm">
      <div className="formSection">
        <InputField extraBlockStyles={{
        width: '100%',
        maxWidth: '524px'
      }} name="name" onChange={setFieldValue} onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'role_name_placeholder')} value={values.name} error={typeof errorMessage === 'object' ? getErrorMessage('name', errorMessage) : undefined} label={getTranslationByLangOrEng(interfaceLanguage, 'role_name_title')} required />

        {!!permissions && <CustomSelect label={getTranslationByLangOrEng(interfaceLanguage, 'role_permissions')} name="permissions" valueField="id" labelField="name" options={permissions} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'role_select_permissions_placeholder')} onChange={permission => {
        setRolePermission(prev => [...prev, permission]);
      }} setSearching={setSearchParams} searching={searchParams} search offset={10} loading={uploading} pageNumberHandler={value => setPageNumber(value)} totalCount={permissionsCount.current} currentPage={pageNumber} />}

        {tableHeaders && <Table columns={tableHeaders} data={rolePermission} hideFieldsSplitters extraFieldsSettings={extraFieldsSettings} rowTitle={getTranslationByLangOrEng(interfaceLanguage, 'open_permission_title')} hiddenHeaders={['editButtons']} fullWidthColumns={['editButtons']} sortableColumns={['permission']} fixedHeader />}
      </div>
    </Form>;
  if (loading) {
    return <Loader />;
  }
  return <AddRoleStyles>
      <Helmet>
        <title>{id ? 'Редагування ролі' : 'Створення ролі'}</title>
      </Helmet>

      <SubHeader title={getTranslationByLangOrEng(interfaceLanguage, id ? 'role_edit_title' : 'role_create_title')}>
        <div className="buttonsContainer">
          <TransparentButton form="roleForm" submit filled text={getTranslationByLangOrEng(interfaceLanguage, 'save_button')} />
          <TransparentButton handleClick={() => navigate('/users/roles')} text={getTranslationByLangOrEng(interfaceLanguage, 'go_back')} />
        </div>
      </SubHeader>

      {!initialValues ? <Loader /> : <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize validationSchema={validationSchema(interfaceLanguage)}>
          {renderForm}
        </Formik>}
    </AddRoleStyles>;
};