/* eslint-disable class-methods-use-this */
import baseApi from '../baseApi';
import { ApiGetTemplates, ApiPostTemplates, ApiResponse } from '../entities';

class MassMailingApiV2 {
  public async getSmsTemplates(
    companyID: number,
  ): Promise<ApiResponse<{ templates: ApiGetTemplates[]; count: number }>> {
    return baseApi.getData(`/sms-templates?companyID=${companyID}`);
  }

  public async getSmsTemplate(
    id: number,
  ): Promise<ApiResponse<ApiGetTemplates>> {
    return baseApi.getData(`/sms-templates/${id}`);
  }

  public async createSmsTemplate(
    data: ApiPostTemplates,
  ): Promise<ApiResponse<ApiGetTemplates>> {
    return baseApi.postData('/sms-templates', data);
  }

  public async editSmsTemplate(
    id: number,
    data: ApiPostTemplates,
  ): Promise<ApiResponse<ApiGetTemplates>> {
    return baseApi.putData(`/sms-templates/${id}`, data);
  }

  public async deleteSmsTemplate(id: number): Promise<ApiResponse<any>> {
    return baseApi.deleteData(`/sms-templates/${id}`);
  }

  public async getViberTemplates(
    companyID: number,
  ): Promise<ApiResponse<{ templates: ApiGetTemplates[]; count: number }>> {
    return baseApi.getData(`/viber-templates?companyID=${companyID}`);
  }

  public async getViberTemplate(
    id: number,
  ): Promise<ApiResponse<ApiGetTemplates>> {
    return baseApi.getData(`/viber-templates/${id}`);
  }

  public async createViberTemplate(
    data: ApiPostTemplates,
  ): Promise<ApiResponse<ApiGetTemplates>> {
    return baseApi.postData('/viber-templates', data);
  }

  public async editViberTemplate(
    id: number,
    data: ApiPostTemplates,
  ): Promise<ApiResponse<ApiGetTemplates>> {
    return baseApi.putData(`/viber-templates/${id}`, data);
  }

  public async deleteViberTemplate(id: number): Promise<ApiResponse<any>> {
    return baseApi.deleteData(`/viber-templates/${id}`);
  }

  public async getEmailTemplates(
    companyID: number,
  ): Promise<ApiResponse<{ templates: ApiGetTemplates[] }>> {
    return baseApi.getData(`/email-templates?companyID=${companyID}`);
  }

  public async getEmailTemplate(
    id: number,
  ): Promise<ApiResponse<ApiGetTemplates>> {
    return baseApi.getData(`/email-templates/${id}`);
  }

  public async createEmailTemplate(
    data: ApiPostTemplates,
  ): Promise<ApiResponse<ApiGetTemplates>> {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === 'template') {
        const htmlBlob = new Blob([data[key]], { type: 'text/html' });
        // @ts-ignore
        formData.append(key, htmlBlob, 'template.html');
      } else {
        // @ts-ignore
        formData.append(key, data[key]);
      }
    });
    return baseApi.postData('/email-templates', formData, true);
  }

  public async editEmailTemplate(
    id: number,
    data: ApiPostTemplates,
  ): Promise<ApiResponse<ApiGetTemplates>> {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === 'template') {
        const htmlBlob = new Blob([data[key]], { type: 'text/html' });
        // @ts-ignore
        formData.append(key, htmlBlob, 'template.html');
      } else {
        // @ts-ignore
        formData.append(key, data[key]);
      }
    });

    return baseApi.putData(`/email-templates/${id}`, formData, true);
  }

  public async deleteEmailTemplate(id: number): Promise<ApiResponse<any>> {
    return baseApi.deleteData(`/email-templates/${id}`);
  }
}

export const massMailingApiV2 = new MassMailingApiV2();
