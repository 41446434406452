/* eslint-disable class-methods-use-this */
import {
  mapApiUserRolesToIUserRoles,
  mapApiUserRoleToIUserRole,
  mapIUserRoleToApiUserRole,
} from '../Mappers';
import IRolesApi from './IRolesApi';
import baseApi from '../baseApi';
import { ApiResponse } from '../entities';
import { IUserRole } from '../../entities';

class RolesApi implements IRolesApi {
  public async getRoles(
    offset?: number,
    limit?: number,
    q?: string,
    usersIDs?: number[],
  ): Promise<ApiResponse<IUserRole[]>> {
    const path = `/roles?offset=${offset || 0}&limit=${limit || 10}${
      usersIDs ? `&usersIDs=${usersIDs}` : ''
    }&query=${q ?? ''}`;
    const { data, statusCode } = await baseApi.getData(path);
    return Promise.resolve({
      data: mapApiUserRolesToIUserRoles(data.roles),
      statusCode,
      count: data.count,
    });
  }

  public async getRole(id: number): Promise<ApiResponse<IUserRole>> {
    const { data, statusCode } = await baseApi.getData(`/roles/${id}`);

    return Promise.resolve({
      data: mapApiUserRoleToIUserRole(data),
      statusCode,
    });
  }

  public async deleteRole(id: number): Promise<ApiResponse<null>> {
    const { statusCode } = await baseApi.deleteData(`/roles/${id}`);

    return Promise.resolve({
      data: null,
      statusCode,
    });
  }

  public async updateRole(
    id: number,
    data: IUserRole,
  ): Promise<ApiResponse<null>> {
    const { statusCode } = await baseApi.putData(
      `/roles/${id}`,
      mapIUserRoleToApiUserRole(data),
    );

    return Promise.resolve({
      data: null,
      statusCode,
    });
  }

  public async createRole(data: IUserRole): Promise<ApiResponse<IUserRole>> {
    const { data: response, statusCode } = await baseApi.postData(
      '/roles',
      mapIUserRoleToApiUserRole(data),
    );

    return Promise.resolve({
      data: mapApiUserRoleToIUserRole(response),
      statusCode,
    });
  }

  public async attachPermissionsToRole(
    roleId: number,
    permissionIds: number[],
  ): Promise<ApiResponse<null>> {
    const { statusCode } = await baseApi.postData(
      `/roles/${roleId}/permissions:add`,
      {
        permissionsIDs: permissionIds,
      },
    );

    return Promise.resolve({
      data: null,
      statusCode,
    });
  }

  public async detachPermissionsFromRole(
    roleId: number,
    permissionIds: number[],
  ): Promise<ApiResponse<null>> {
    const { statusCode } = await baseApi.postData(
      `/roles/${roleId}/permissions:remove`,
      {
        permissionsIDs: permissionIds,
      },
    );

    return Promise.resolve({
      data: null,
      statusCode,
    });
  }
}

export const rolesApi = new RolesApi();
